import { Typography } from "@mui/material";

const GenerationalArtTab = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Generational Art
      </Typography>
    </div>
  );
};

export default GenerationalArtTab;
