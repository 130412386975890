import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { functions } from "../../firebase";

const CheckoutPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    try {
      // Call your Firebase Cloud Function to create the PayPal payment
      const createPayment = httpsCallable(functions, "createPayPalPayment");
      const result = await createPayment({ amount: 49.99 }); // Example payload

      //   const { approvalUrl } = result.data;

      const approvalUrl = "temp";

      // Redirect the user to the PayPal approval URL
      if (approvalUrl) {
        window.location.href = approvalUrl;
      } else {
        throw new Error("Failed to initiate payment");
      }
    } catch (error: any) {
      console.error("Error during checkout:", error);
      setError(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box textAlign="center" py={5}>
        <Typography variant="h4" gutterBottom>
          Checkout
        </Typography>
        <Typography variant="body1" gutterBottom>
          Review your order and proceed to payment.
        </Typography>
        <Box mt={4}>
          {error && <Alert severity="error">{error}</Alert>}
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCheckout}
            >
              Checkout with PayPal
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default CheckoutPage;
