import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../firebase";

interface CheckoutButtonProps {
  amount: number;
  currency: string;
  description: string;
  customData?: any;
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({
  amount,
  currency,
  description,
  customData,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const userID = user ? user.uid : null;

  const handleCheckout = async () => {
    if (!userID) {
      console.error("User ID not found");
      return;
    }
    setLoading(true);
    setError(null);

    try {
      const payload = {
        amount,
        currency,
        description,
        customData,
      };

      console.log("Creating PayPal payment with payload:", payload);

      const response = await fetch(
        "http://127.0.0.1:5001/family-tree-and-me/us-central1/api_create_order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify({ cart: payload }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create PayPal payment");
      }

      const data = await response.json();
      console.log("data from response", data);
      const approvalUrl = data.approvalUrl;
      console.log("Approval URL:", approvalUrl);

      if (approvalUrl) {
        console.log("Approval URL:", approvalUrl);

        window.location.href = approvalUrl;

        // Simulate the redirect to PayPal and back
        //generate order based on today's date and time, plus a random number
        // const fakeOrderID =
        //   new Date().getTime().toString() +
        //   Math.floor(Math.random() * 1000).toString();
        // const newBody = {
        //   orderID: fakeOrderID,
        //   userID: userID,
        //   requiredForms: customData?.requiredForms || [],
        // };

        // console.log("Confirming payment with payload:", newBody);

        // // After redirect (simulate success):
        // const confirmResponse = await fetch(
        //   "http://127.0.0.1:5001/family-tree-and-me/us-central1/confirm_paypal_payment",
        //   // "https://confirm-paypal-payment-7bsfwndbdq-uc.a.run.app",
        //   {
        //     method: "POST",
        //     headers: {
        //       "Content-Type": "application/json",
        //       "Access-Control-Allow-Origin": "*",
        //     },
        //     body: JSON.stringify(newBody),
        //   }
        // );

        // if (!confirmResponse.ok) {
        //   throw new Error("Failed to confirm PayPal payment");
        // }

        // const confirmData = await confirmResponse.json();
        // console.log("Payment confirmed and Firestore updated:", confirmData);
        // const orderID = confirmData.order_id;

        // // Navigate to payment success page
        // navigate(`/payment-confirmation?orderID=${orderID}`);
      } else {
        throw new Error("Approval URL not found");
      }
    } catch (error: any) {
      console.error("Checkout error:", error);
      setError(error.message);

      navigate("/cart", { state: { error: error.message } });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleCheckout}
      disabled={loading}
      style={{ margin: "20px 0" }}
    >
      {loading ? "Processing..." : "Checkout with PayPal"}
    </Button>
  );
};

export default CheckoutButton;
