import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";

const GenerationalArtOverviewPage: React.FC = () => {
  return (
    <Box maxWidth="1200px" margin="0 auto" padding="16px">
      <Typography variant="h4" component="h1" gutterBottom>
        Generational Art
      </Typography>
      <Typography variant="body1" paragraph>
        Generational Art displays Father-to-Son or Mother-to-Daughter photos in
        either a horizontal or circular presentation.
      </Typography>
      <Typography variant="body1" paragraph>
        This art may include as many generations as you have pictures available.
        Each photo will be given our company's unique signature style - the
        "STUDIO PORTRAIT LOOK." We digitally enhance each photo, extracting the
        head-and-shoulders image out of their original background and placing
        them on a background similar to all the other photos. Our goal is to
        make it look like each individual went down to the same local photo
        studio to have their picture taken.{" "}
        <a href="/photo-enhancements">
          View Photo Enhancements - Before & After Pictures.
        </a>
      </Typography>
      <Typography variant="body1" paragraph>
        The size is 8" tall and varies between 16" to 22" long. The background
        is Neutral Beige.
      </Typography>
      <Typography variant="body1" paragraph>
        The art comes as a print, or for an additional charge you may choose to
        have it mounted on black foam core board and coated with a satin-glaze
        laminate. Finished in this manner, the art does not need to be framed.
        It can be placed on a small table-top easel for display.
      </Typography>
      <Typography variant="body1" paragraph>
        Generational art is priced at $12.00 per each person in the artwork. Art
        reprints are $25 each. Mounting is optional at $24.
      </Typography>
      <Typography variant="body1" paragraph>
        Example: If you are ordering a Generational Art piece for yourself with
        6 generations, the charge would be $72. If you then wanted to give one
        to your daughter with her picture added to the art, then you would pay
        $25 for the reprint with the addition of $10 for her added photo. Your
        daughter's total art charge would be $35.
      </Typography>

      <Grid container spacing={2} mt={4}>
        <Grid item xs={12}>
          <img
            src="/images/genArt/4gen.jpg"
            alt="4 Generations Art"
            style={{ width: "100%" }}
          />
          <Typography variant="body1" textAlign={"center"}>
            4 Generations - 8" x 16"
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent={"center"}>
          <img
            src="/images/genArt/5gen.jpg"
            alt="5 Generations Art"
            style={{
              width: "100%",
              margin: "auto",
              backgroundColor: "green",
            }}
          />
          <Typography variant="body1" textAlign={"center"}>
            5 Generations - 8" x 18"
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            src="/images/genArt/6gen.jpg"
            alt="6 Generations Art"
            style={{ width: "100%" }}
          />
          <Typography variant="body1" textAlign={"center"}>
            6 Generations - 8" x 20"
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            src="/images/genArt/7gen.jpg"
            alt="7 Generations Art"
            style={{ width: "100%" }}
          />
          <Typography variant="body1" textAlign={"center"}>
            7 Generations - 8" x 22"
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <img
            src="/images/genArt/circular.jpg"
            alt="Circular Generational Art"
            style={{ width: "100%" }}
          />
          <Typography variant="body1" textAlign={"center"}>
            Circular Generational Art - 16" x 20"
          </Typography>
        </Grid>
      </Grid>

      <Box mt={4} textAlign="center">
        <Button variant="contained" color="primary" href="/purchase">
          Click to Purchase
        </Button>
      </Box>
    </Box>
  );
};

export default GenerationalArtOverviewPage;
