import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  List,
  ListItem,
  SelectChangeEvent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

type Product = {
  id: string;
  name: string;
  instructions: JSX.Element;
};

const products: Product[] = [
  {
    id: "photo-family-tree",
    name: "Photo Family Tree",
    instructions: (
      <>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>
            Please contact us if you have ANY questions or comments:
          </strong>{" "}
          801.400.5802 or info@familytreeandme.com
        </Typography>
        <Stepper activeStep={4} orientation="vertical">
          <Step>
            <StepLabel>Create an account</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Create an Account.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Make a payment</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Click the PURCHASE Tab. Select the Photo Family Tree image and
              follow the instructions to check-out.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Send your Family Tree information</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Family Tree Names & Birthdates. For
              your convenience, it is optional to download two pdf family tree
              outlines, worksheet #1 and worksheet #2, as a guide while you
              gather your family information.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Upload your photos</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              An instructional email will be sent to you within 24 hours with
              the link that allows you to upload your photos to us through a
              safe, secure third-party transfer site.
            </Typography>
          </Step>
        </Stepper>
        <br />
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>NOTE:</strong> For maximum photo quality, review and
          understand the scanning guidelines and photograph instructions listed
          on our home page.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          We will create your art piece and send an email with a low-resolution
          draft for you to review and approve before it is sent to the printer.
        </Typography>
      </>
    ),
  },
  {
    id: "family-proclamation",
    name: "Family Proclamation",
    instructions: (
      <>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>
            Please contact us if you have ANY questions or comments:
          </strong>{" "}
          801.400.5802 or info@familytreeandme.com
        </Typography>
        <Stepper activeStep={4} orientation="vertical">
          <Step>
            <StepLabel>Create an account</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Create an Account.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Make a payment</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Click the PURCHASE Tab. Select the Photo Family Proclamation image
              and follow the instructions to check-out.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Upload your photos</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              An instructional email will be sent to you within 24 hours with
              the link that allows you to upload your photos to us through a
              safe, secure third-party transfer site.
            </Typography>
          </Step>
        </Stepper>
        <br />
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>NOTE:</strong> For maximum photo quality, review and
          understand the scanning guidelines and photograph instructions listed
          on our home page.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          We will create your art piece and send an email with a low-resolution
          draft for you to review and approve before it is sent to the printer.
        </Typography>
      </>
    ),
  },
  {
    id: "missionary-photo-art",
    name: "Missionary Photo Art",
    instructions: (
      <>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>
            Please contact us if you have ANY questions or comments:
          </strong>{" "}
          801.400.5802 or info@familytreeandme.com
        </Typography>
        <Stepper activeStep={4} orientation="vertical">
          <Step>
            <StepLabel>Create an account</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Create an Account.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Make a payment</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Click the PURCHASE Tab. Select the Missionary Art Piece image and
              follow the instructions to check-out.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Send us your Missionary's Information</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Missionary Photo art. For your
              convenience, it is optional to download the missionary worksheet
              pdf as a guide to fill out while you gather your missionary's
              information.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Upload your photos</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              An instructional email will be sent to you within 24 hours with
              the link that allows you to upload your photos to us through a
              safe, secure third-party transfer site.
            </Typography>
          </Step>
        </Stepper>
        <br />
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>NOTE:</strong> For maximum photo quality, review and
          understand the scanning guidelines and photograph instructions listed
          on our home page.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          We will create your art piece and send an email with a low-resolution
          draft for you to review and approve before it is sent to the printer.
        </Typography>
      </>
    ),
  },
  {
    id: "generational-art",
    name: "Generational Art",
    instructions: (
      <>
        <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>
            Please contact us if you have ANY questions or comments:
          </strong>{" "}
          801.400.5802 or info@familytreeandme.com
        </Typography>
        <Stepper activeStep={4} orientation="vertical">
          <Step>
            <StepLabel>Create an account</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Create an Account.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Make a payment</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Click the PURCHASE Tab. Select the Generational Art image and
              follow the instructions to check-out.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Send your Generational information</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              Fill out and submit: FORM: Generational Names & Birthdates.
            </Typography>
          </Step>
          <Step>
            <StepLabel>Upload your photos</StepLabel>
            <Typography variant="body1" sx={{ pl: 4 }}>
              An instructional email will be sent to you within 24 hours with
              the link that allows you to upload your photos to us through a
              safe, secure third-party transfer site.
            </Typography>
          </Step>
        </Stepper>
        <br />
        <Typography variant="body2" sx={{ mt: 2 }}>
          <strong>NOTE:</strong> For maximum photo quality, review and
          understand the scanning guidelines and photograph instructions listed
          on our home page.
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          We will create your art piece and send an email with a low-resolution
          draft for you to review and approve before it is sent to the printer.
        </Typography>
      </>
    ),
  },
];

const OrderPage: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const productId = searchParams.get("product");
    if (productId && products.some((product) => product.id === productId)) {
      setSelectedProduct(productId);
    }
  }, [searchParams]);

  const handleProductChange = (event: SelectChangeEvent) => {
    const productId = event.target.value as string;
    setSelectedProduct(productId);
    setSearchParams({ product: productId });
  };

  const selectedProductData = products.find(
    (product) => product.id === selectedProduct
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <FormControl fullWidth>
        <InputLabel id="product-select-label">Select a Product</InputLabel>
        <Select
          labelId="product-select-label"
          value={selectedProduct}
          onChange={handleProductChange}
          label="Select a Product"
        >
          {products.map((product) => (
            <MenuItem key={product.id} value={product.id}>
              {product.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedProductData && (
        <Box sx={{ mt: 4 }}>{selectedProductData.instructions}</Box>
      )}
    </Container>
  );
};

export default OrderPage;
