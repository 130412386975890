import { Box, Grid, Button, Container } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";

const buttons = [
  "PURCHASE",
  "FAMILY TREES",
  "PROCLAMATIONS",
  "MISSIONARIES",
  "GENERATIONAL ART",
  "CONTACT US",
];

const FamTreeButtonBanner = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ width: "100vw", height: "340px", backgroundColor: "#111111" }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ maxWidth: 1250 }}
      >
        {buttons.map((button) => (
          <Grid
            key={`famtree-button-${button}`}
            item
            xs={4}
            style={{ color: "white", margin: 0, padding: 20 }}
          >
            <Box
              sx={{
                p: 2,
                border: "1px solid white",
                backgroundColor: "rgb(255,255,255,0.15)",
              }}
            >
              <Button
                fullWidth
                style={{
                  textAlign: "center",
                  margin: "15px 0 15px 0",
                  color: "white",
                  fontSize: 24,
                }}
              >
                {button}
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default FamTreeButtonBanner;
