import React from "react";
import {
  Box,
  List,
  Paper,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  CircularProgress,
  Button,
  Typography,
} from "@mui/material";
import { Add, Reorder } from "@mui/icons-material";
import moment from "moment";

export type CompanionDetails = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  notes?: string;
  imageFilePath: string;
};

type CompanionListProps = {
  companionList: CompanionDetails[];
  loading: boolean;
  onItemClick: (item: CompanionDetails) => void;
  onAddItem: () => void;
};

const CompanionList: React.FC<CompanionListProps> = ({
  companionList,
  loading,
  onItemClick,
  onAddItem,
}) => {
  return (
    <Paper elevation={3} sx={{ flexGrow: 1, marginRight: "24px" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {companionList.map((item, index) => (
            <ListItem key={item.id} button onClick={() => onItemClick(item)}>
              <ListItemText
                primary={
                  <Box>
                    <Typography variant="body1">{`${index + 1}. ${
                      item.name || "Unnamed"
                    }`}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.startDate && item.endDate
                        ? `${moment(item.startDate).format(
                            "MMMM D, YYYY"
                          )} - ${moment(item.endDate).format("MMMM D, YYYY")}`
                        : "Dates not set"}
                    </Typography>
                  </Box>
                }
              />
              <ListItemSecondaryAction></ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        <Button
          onClick={onAddItem}
          color="primary"
          aria-label="add new item"
          startIcon={<Add />}
          variant="contained" // Optional: You can choose a variant, such as 'text', 'outlined', or 'contained'
        >
          Add Companion
        </Button>
      </Box>
    </Paper>
  );
};

export default CompanionList;
