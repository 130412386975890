import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  Link,
  Card,
  CardMedia,
  Divider,
  CardContent,
} from "@mui/material";

const PhotoFamilyTreesOverviewPage: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Photo Family Trees
        </Typography>

        <Typography variant="body1" paragraph>
          There are MANY ways to create a photo family tree. Start by deciding{" "}
          <strong>WHO</strong> you would like pictured in your art piece. If we
          don't have an example that fits your needs - we'll design a{" "}
          <strong>CUSTOM</strong> one for you!
        </Typography>

        <Typography variant="body1" paragraph>
          Our company's unique signature style - the{" "}
          <em>"STUDIO PORTRAIT LOOK"</em> - is what sets us apart from other
          Family Trees. We digitally enhance each photo, extracting the
          head-and-shoulders image out of their original background and placing
          them on a background similar to all the other photos. Our goal is to
          make it look like <strong>Great, Great Grandpa Charles</strong> and
          all his posterity went down to the same local photo studio to have
          their picture taken.{" "}
          <Link href="/before-and-after-photo-enhancements">
            View Photo Enhancements - Before &amp; After Pictures.
          </Link>
        </Typography>

        <Typography variant="h6" align="center" sx={{ my: 4 }}>
          <Link href="/family-tree-example-gallery">
            View our Family Tree Example Gallery
          </Link>
        </Typography>

        <Typography variant="h5" align="center" sx={{ mt: 6, mb: 3 }}>
          Let's get started in creating your keepsake art!
        </Typography>

        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>1. Decide WHO</strong> will be displayed in your Photo
              Family Tree.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body1">
              <strong>2. Choose a Format.</strong> Do you want a TRADITIONAL or
              BUTTERFLY format?
            </Typography>
          </ListItem>
        </List>

        {/* Images with Captions */}
        <Grid container spacing={2} sx={{ my: 4 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/traditional.jpg"
                alt="Traditional Format"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  <strong>TRADITIONAL:</strong> The generations rise upward from
                  the main couple. Children are pictured below. 4 generations
                  displayed.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/butterfly.jpg"
                alt="Butterfly Format"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  <strong>BUTTERFLY:</strong> The generations come out to either
                  side of the main couple. Children are pictured below. 4
                  generations displayed.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <List>
          <ListItem>
            <Typography variant="body1">
              <strong>3. Choose an Orientation.</strong> Do you want a VERTICAL
              or HORIZONTAL orientation?
            </Typography>
          </ListItem>
        </List>

        {/* More Images with Captions */}
        <Grid container spacing={2} sx={{ my: 4 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/mcmaster.jpg"
                alt="Vertical Orientation"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  <strong>VERTICAL Orientation.</strong> Butterfly Format. 4
                  generations displayed.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/horizontal.jpg"
                alt="Horizontal Orientation"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  <strong>HORIZONTAL Orientation.</strong> Butterfly Format. 5
                  generations displayed.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Typography variant="body1" paragraph>
          <strong>5. Other Factors.</strong> Cost - The number of photos
          displayed in your family tree determines the cost of your order. BASIC
          LAYOUTS are priced at $8 per person in the art piece; CUSTOM LAYOUTS
          are priced at $10 per person. REPRINTS are $25 each unless the
          original art is 36" or wider.{" "}
          <Link href="/costs-for-photo-family-trees">
            View our pricing tables.
          </Link>
        </Typography>
        <Typography variant="body1" paragraph>
          Size - The smallest and most commonly requested size is 16" x 20". The
          largest size for the BASIC LAYOUT is 24" x 36." If you have a frame
          that you are wanting us to fit, let us know and we can most likely
          accommodate your request. CUSTOM LAYOUTS can be upwards to 24" x 96".
        </Typography>
        <Typography variant="body1" paragraph>
          Individual only - Perhaps you only want to display one side of the
          family. Here is an example:
        </Typography>

        {/* Example Image */}
        <Grid container spacing={2} sx={{ my: 4 }}>
          <Grid item xs={12} sm={6} sx={{ mx: "auto" }}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/individualantiqueweb.jpg"
                alt="Individual Example"
              />
            </Card>
          </Grid>
        </Grid>

        <Divider sx={{ my: 4 }} />

        <Typography variant="body1" paragraph>
          <strong>Optional Background</strong> - For a special touch, one may
          choose to have a faint, line-drawn LDS temple placed in the background
          to emphasize that families are forever. Featured temple artist is Chad
          Hawkins.{" "}
          <Link href="/family-trees-with-lds-temple-backgrounds">
            View temple options.
          </Link>
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Silhouettes</strong> - When you cannot locate the picture of
          an ancestor, we can use silhouettes to replace the missing photos. If
          an entire generation is missing pictures, we recommend either
          eliminating the generation or just using text boxes to display the
          information.
        </Typography>

        <Grid container spacing={2} sx={{ my: 4 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/male_silhouette.jpg"
                alt="Male Silhouette"
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/photoFamilyTrees/female_silhouette.jpg"
                alt="Female Silhouette"
              />
            </Card>
          </Grid>
        </Grid>

        <Typography variant="body1" paragraph>
          For maximum photo quality, review and understand the{" "}
          <Link href="/scanning-guidelines">scanning guidelines</Link> and{" "}
          <Link href="/photograph-guidelines">photograph instructions</Link>{" "}
          listed on our home page. If you don't see a Family Tree example that
          fits your needs - we'll design one for you!{" "}
          <Link href="/custom-layout-options-for-photo-family-trees">
            View some of our CUSTOM LAYOUTS!
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default PhotoFamilyTreesOverviewPage;
