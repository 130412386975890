import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const PriceListPage: React.FC = () => {
  return (
    <Box maxWidth="900px" margin="0 auto" padding="16px">
      <Typography variant="h4" component="h1" gutterBottom>
        Price List
      </Typography>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Standard Products
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#5C0629",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Product
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    backgroundColor: "#5C0629",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Photo Family Tree</TableCell>
                <TableCell align="right">$100.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Missionary Photo Art</TableCell>
                <TableCell align="right">$80.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Family Proclamation Art</TableCell>
                <TableCell align="right">$120.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Custom Layouts</TableCell>
                <TableCell align="right">Starting at $150.00</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Additional Services
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#5C0629",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Service
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    backgroundColor: "#5C0629",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Photo Enhancements</TableCell>
                <TableCell align="right">$20.00 per photo</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Mounting on Foam Core</TableCell>
                <TableCell align="right">$24.00</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Satin-Glaze Laminate</TableCell>
                <TableCell align="right">$15.00</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Discounts
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#5C0629",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Discount Type
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    backgroundColor: "#5C0629",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  Discount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Bulk Orders (10 or more)</TableCell>
                <TableCell align="right">10% off</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Returning Customers</TableCell>
                <TableCell align="right">5% off</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Notes
        </Typography>
        <Typography variant="body1" paragraph>
          All prices are subject to change without notice. The final price will
          be confirmed during the checkout process.
        </Typography>
        <Typography variant="body1" paragraph>
          Custom artwork prices may vary depending on the complexity and size of
          the project. Please contact us for a detailed quote.
        </Typography>
      </Box>
    </Box>
  );
};

export default PriceListPage;
