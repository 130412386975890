import { ProductData, ProductOption } from "./productTypes";

export const FamilyTreeOptions: ProductOption[] = [
  {
    id: "orientation",
    type: "select",
    label: "Orientation",
    values: [
      {
        id: "ft-horizontal",
        label: "Horizontal",
        cost: 0,
        image: "/images/photoFamilyTrees/horizontal.jpg",
      },
      {
        id: "ft-vertical",
        label: "Vertical",
        cost: 0,
      },
      { id: "ft-orientation-undecided", label: "undecided", cost: 0 },
    ],
    default: "ft-orientation-undecided",
  },
  {
    id: "format",
    type: "select",
    label: "Format",
    values: [
      {
        id: "ft-format-traditional",
        label: "Traditional",
        cost: 0,
        image: "/images/photoFamilyTrees/traditional.jpg",
      },
      {
        id: "ft-format-butterfly",
        label: "Butterfly",
        cost: 0,
        image: "/images/photoFamilyTrees/butterfly.jpg",
      },
      { id: "ft-format-undecided", label: "undecided", cost: 0 },
    ],
    default: "ft-format-undecided",
  },
  {
    id: "dimension",
    type: "select",
    label: "Dimension",
    values: [
      { id: "dim-16x20", label: "16 x 20", cost: 0 },
      { id: "dim-20x24", label: "20 x 24", cost: 10 },
      { id: "dim-20x30", label: "20 x 30", cost: 20 },
      { id: "dim-24x36", label: "24 x 36", cost: 30 },
      { id: "dim-other", label: "Other", cost: 0 },
      { id: "dim-undecided", label: "undecided", cost: 0 },
    ],
    default: "dim-undecided",
  },
  {
    id: "faces",
    type: "number",
    label: "Number of Faces",
    min: 6,
    max: 50,
    default: 6,
    cost: 5,
  },
  {
    id: "background",
    type: "select",
    label: "Background",
    values: [
      {
        id: "bg-beige",
        label: "Neutral Beige",
        image: "/images/photoFamilyTrees/backgrounds/beige.jpg",
        cost: 0,
      },
      {
        id: "bg-burgundy",
        label: "Burgundy Cloud",
        image: "/images/photoFamilyTrees/backgrounds/burgundy.jpg",
        cost: 5,
      },
      {
        id: "bg-grove",
        label: "Grove",
        image: "/images/photoFamilyTrees/backgrounds/grove.jpg",
        cost: 5,
      },
      {
        id: "bg-antiquep",
        label: "Antique Parchment",
        image: "/images/photoFamilyTrees/backgrounds/antiquep.jpg",
        cost: 0,
      },
      {
        id: "bg-tawny",
        label: "Tawny Parchment",
        image: "/images/photoFamilyTrees/backgrounds/tawny.jpg",
        cost: 0,
      },
      { id: "bg-undecided", label: "undecided", cost: 0 },
    ],
    default: "bg-undecided",
  },
  {
    id: "border",
    type: "boolean",
    label: "Include Temple Background",
    image: "/images/shop/photoFamilyTree/sltemple.jpg",
    default: false,
    cost: 15,
  },
];

export const GenArtOptions: ProductOption[] = [
  {
    id: "generations",
    type: "select",
    label: "Number of Generations",
    values: [
      {
        id: "four-generations",
        label: "4 Generations",
        image: "/images/genArt/4gen.jpg",
        cost: 0,
      },
      {
        id: "five-generations",
        label: "5 Generations",
        image: "/images/genArt/5gen.jpg",
        cost: 12,
      },
      {
        id: "six-generations",
        label: "6 Generations",
        image: "/images/genArt/6gen.jpg",
        cost: 24,
      },
      {
        id: "seven-generations",
        label: "7 Generations",
        image: "/images/genArt/7gen.jpg",
        cost: 36,
      },
    ],
    default: "four-generations",
  },
  {
    id: "line",
    type: "select",
    label: "Line",
    values: [
      {
        id: "paternal",
        label: "Paternal Line",
        cost: 0,
        image: "/images/genArt/7gen.jpg",
      },
      {
        id: "maternal",
        label: "Maternal Line",
        cost: 0,
        image: "/images/genArt/6gen.jpg",
      },
    ],
    default: "paternal",
  },
];

export const MissionaryArtOptions: ProductOption[] = [
  {
    id: "size",
    type: "select",
    label: "Size",
    values: [
      {
        id: "small",
        label: "Small (16 x 20)",
        cost: 0,
        // image: "/images/shop/missionary/smallmiss2.jpg",
      },
      {
        id: "large",
        label: "Large (20 x 30)",
        cost: 40,
        // image: "/images/shop/missionary/largemiss1.jpg",
      },
    ],
    default: "small",
  },
  {
    id: "perimeter",
    type: "boolean",
    label: "Perimeter",
    image: "/images/shop/missionary/largemiss1.jpg",
    default: false,
    cost: 40,
  },
  {
    id: "mounting",
    type: "boolean",
    label: "Mounting",
    image: "/images/shop/missionary/largemiss1.jpg",
    default: false,
    cost: 24,
  },
  {
    id: "reprints",
    type: "number",
    label: "Reprints",
    min: 0,
    max: 10,
    default: 0,
    cost: 25,
  },
];

export const productData: ProductData = {
  "Photo-Family-Trees": {
    "basic-layout": {
      id: "pft-basic-layout",
      title: "BASIC LAYOUT Photo Family Tree",
      description:
        "Our BASIC LAYOUT Photo Family Trees are standardized layouts which do not require reconfiguration to accommodate your unique family structure. They may have either a Traditional or Butterfly format, a Vertical or Horizontal orientation, and may be placed on any of our 5 background colors.\n\nThe art piece is printed on Fuji Film Photo Paper/Satin/archival grade -- chosen for its beautiful, vibrant colors and premium luster photo finish.\n\nPlease note that computer monitors display color differently. Slight color variations with your finished product may occur.\n\nOrder your project at least 4 weeks before your desired deadline date. This allows for its creation, printing, and shipping.\n\nOur artwork is shipped flat so that it will be ready for framing upon arrival. A frame is not included.\n\nWe look forward to working with you as we create a memorable keepsake art piece for your family!",
      price: 48.0,
      originalPrice: 60.0,
      discount: 20,
      per: 8,
      originalPer: 10,
      qty: [6, 50],
      qtyDescription: "individuals/photos",
      images: [
        "/images/shop/photoFamilyTree/basic1.jpg",
        "/images/shop/photoFamilyTree/basic2.jpg",
        "/images/shop/photoFamilyTree/basic3.jpg",
        "/images/shop/photoFamilyTree/basic4.jpg",
      ],
      category: "Photo Family Tree",
      productName: "Basic Layout",
      options: FamilyTreeOptions,
      requiredForms: ["FamilyNames"],
    },
    "custom-layout": {
      id: "pft-custom-layout",
      title: "CUSTOM LAYOUT Photo Family Tree",
      description:
        "CUSTOM LAYOUT Family Trees require us to design a layout specific to your unique family structure.  They may include Grandparents Art, Combo: Ancestors & Posterity Art, Extended Family Art, Perimeter Family Tree Art, Families & Siblings Art, and Special Requests. \n\nThe art piece is printed on Fuji Film Photo Paper/Satin/archival grade -- chosen for its beautiful, vibrant colors and premium luster photo finish.\n\nPlease note that computer monitors display color differently.  Slight color variations with your finished product may occur.\n\nOrder your project at least 4 weeks before your desired deadline date.  This allows for its creation, printing, and shipping.\n\nOur artwork is shipped flat so that it will be ready for framing upon arrival.  A frame is not included.\n\nWe look forward to working with you as we create a memorable keepsake art piece for your family!",
      price: 60.0,
      originalPrice: 90.0,
      discount: 33,
      per: 10,
      originalPer: 15,
      qty: [6, 50],
      qtyDescription: "individuals/photos",
      images: [
        "/images/shop/photoFamilyTree/custom1.jpg",
        "/images/shop/photoFamilyTree/custom2.jpg",
        "/images/shop/photoFamilyTree/custom3.jpg",
        "/images/shop/photoFamilyTree/custom4.jpg",
      ],
      category: "Photo Family Tree",
      productName: "Basic Layout",
      options: FamilyTreeOptions,
      requiredForms: ["FamilyNames"],
    },
    "gift-certificate-basic": {
      id: "pft-gift-certificate-basic",
      title: "Gift Certificate for BASIC LAYOUT Photo Family Tree",
      description:
        "Gift Certificates provide the perfect way to surprise someone for a special occasion - and yet allow them the opportunity to build the artwork to their liking within the Basic Layout Guidelines. \n\nOur BASIC LAYOUT Photo Family Trees Gift Certificate is to be used for the creation of standardized layouts which do not require reconfiguration to accommodate your unique family structure.  They may have either a Traditional or Butterfly format, a Vertical or Horizontal orientation, and may be placed on any of our 5  background colors.\n\nThe certificate is good for 12 months -- allowing time to select the needed photographs. We handle all gift certificates personally, so when the recipient is ready to order their product, they just need to contact us by phone or email to place their order.",
      price: 48.0,
      originalPrice: 60.0,
      discount: 20,
      per: 8,
      originalPer: 10,
      qty: [6, 50],
      qtyDescription: "individuals/photos",
      images: [
        "/images/shop/photoFamilyTree/basicgift1.jpg",
        "/images/shop/photoFamilyTree/basicgift2.jpg",
        "/images/shop/photoFamilyTree/basicgift3.jpg",
        "/images/shop/photoFamilyTree/basicgift4.jpg",
      ],
      category: "Photo Family Tree",
      productName: "Gift Certificate for Basic Layout",
      requiredForms: ["FamilyNames"],
    },
    "gift-certificate-custom": {
      id: "pft-gift-certificate-custom",
      title: "Gift Certificate for CUSTOM LAYOUT Photo Family Tree",
      description:
        "This Gift Certificate gives the recipient the opportunity to create a CUSTOM LAYOUT Photo Family Tree, which includes Generational Art, Grandparents Art, Combo: Ancestors & Posterity Art, Extended Family Tree Art, Perimeter Family Tree Art, Families & Siblings Art, and Special Requests.\n\nGift Certificates are available for all our family photo art products! They provide the perfect way to surprise someone for a special occasion -- and yet allow the recipient the opportunity to build the artwork to their liking.\n\nThe certificate is good for 12 months -- allowing time to select the needed photographs. We handle all gift certificates personally, so when the recipient is ready to order their product, they just need to contact us by phone or email to place their order.\n\nIf the recipient decides they want a different product than the one printed on their gift certificate, we will accommodate their request. If the new item is more expensive than the original purchase, then payment for the price difference is required.",
      price: 60.0,
      originalPrice: 90.0,
      discount: 33,
      per: 10,
      originalPer: 15,
      qtyDescription: "individuals/photos",
      qty: [6, 50],
      images: [
        "/images/shop/photoFamilyTree/customgift1.jpg",
        "/images/shop/photoFamilyTree/customgift2.jpg",
        "/images/shop/photoFamilyTree/customgift3.jpg",
        "/images/shop/photoFamilyTree/customgift4.jpg",
      ],
      category: "Photo Family Tree",
      productName: "Gift Certificate for Custom Layout",
      requiredForms: ["FamilyNames"],
    },
    reprints: {
      id: "pft-reprints",
      title: "REPRINTS of your completed artwork",
      description:
        "Reprint any of your completed Photo Art Products for just $25 each.  (This price does not include shipping.)\n\nOnce the artwork has been designed, we're not going to charge you again for the creating process.  That's why REPRINTS are such a great Family Reunion idea or Christmas gift idea because many families are able to enjoy our products at a significantly reduced price! \n\nNOTE:  Do NOT use the QUANTITY BUTTON when ordering multiple copies of your artwork or you will be charged full price for each additional one.  Instead, order through the REPRINTS icon.\n\nContact us if you are wanting more copies than we have listed.",
      price: 25.0,
      originalPrice: 25.0,
      discount: 0,
      per: 25,
      originalPer: 25,
      qtyDescription: "reprints",
      qty: [1, 10],
      images: ["/images/shop/photoFamilyTree/reprint.jpg"],
      category: "Photo Family Tree",
      productName: "Reprints",
      requiredForms: [],
    },
    // "LDS-Temple-Addon": {
    //   id: "pft-lds-temple-addon",
    //   title: "LDS Temple in the Background",
    //   description:
    //     "We look forward to working with you as we create a memorable keepsake art piece for your family!\n\nFor a special touch when ordering a Photo Family Tree, one may choose the art option of a line-drawn LDS temple to be placed in the background.\n\nThis added feature reminds us that families are forever because of the temples and the work performed there. \n\nWe are privileged to offer the copyrighted artwork of Chad Hawkins, a renowned LDS artist whose specialty includes the detailed drawings of temples throughout the world.\n\nThis is the $15 artist's royalty fee for the addition of a temple background.",
    //   price: 15.0,
    //   originalPrice: 15.0,
    //   discount: 0,
    //   per: 15,
    //   originalPer: 15,
    //   qtyDescription: "background",
    //   qty: [1, 1],
    //   images: ["/images/shop/photoFamilyTree/sltemple.jpg"],
    //   category: "Photo Family Tree",
    //   productName: "LDS Temple in the Background",
    //   requiredForms: ["FamilyNames"],
    // },
    // "Perimeter-Addon": {
    //   id: "pft-perimeter-addon",
    //   title: "Perimeter Family Tree Art addon",
    //   description:
    //     "This Custom layout features your choice of a Photo Family Tree surrounded by color photos around the perimeter representing the family in different life stages.  This is an ADD-ON purchase the accompanies the ordering of any Photo Family Tree.  In other words, one must first order their Family Tree Layout and then this is the second purchase which places the photos around the perimeter.",
    //   price: 40.0,
    //   originalPrice: 40.0,
    //   discount: 0,
    //   per: 40,
    //   originalPer: 40,
    //   qtyDescription: "orders",
    //   qty: [1, 10],
    //   images: ["/images/shop/photoFamilyTree/perimeter.jpg"],
    //   category: "Photo Family Tree",
    //   productName: "Perimeter Add-on",
    //   requiredForms: ["FamilyNames"],
    // },
    "Unexpected-Addons": {
      id: "pft-unexpected-addons",
      title: "Unexpected Add-ons",
      description:
        "This item allows for the additional charges that arise when an unexpected add-on is made to a client's order after they have made their initial purchase. \n\nIt is available in increments of $5.00. \n\nIf, for example the additional expense is $22.85, then the customer would add-to-cart the amount rounded UP to the next increment of $5.00.  In this case that would be $25.00.  Before your credit card is ever charged for the purchase, we manually remove the difference on PayPal so that you are paying exactly $22.85.\n\nThe very nature of custom work can sometimes be unpredictable.",
      price: 5.0,
      originalPrice: 5.0,
      discount: 0,
      per: 5,
      originalPer: 5,
      qtyDescription: "addons",
      qty: [1, 20],
      images: ["/images/shop/photoFamilyTree/unexpected.jpg"],
      category: "Photo Family Tree",
      productName: "Add-ons",
      requiredForms: ["FamilyNames"],
    },
  },
  "Missionary-Photo-Art": {
    Art: {
      id: "mpa-art",
      title: "Missionary Photo Art",
      description:
        "This customized art piece is designed to capture the moments and memories of the returned missionary.  It features 40 or 80 mission photos around the perimeter, a portrait shot of the missionary, photo of the mission president(s), and a map of the mission boundaries with highlighted cities/states/countries where served.  This can be further customized with the addition of a country flag(s), favorite scripture or two, companion information and/or photos, transfer information, etc. -- anything that would be meaningful to the missionary.\n\nIf you want the placement of the perimeter pictures to have a specific order, then rename each jpeg before you upload the file to our website.  Use this number system:  #1 for the photo in the upper left hand corner of the outside perimeter of pictures.  #2 for the photo to its immediate right.  Continue naming #3 through #44 in this clockwise manner.   Continue #45 through #80 starting with the upper left hand photo of the inner perimeter of pictures.\n\nIt is printed on Fuji Film Photo Paper/Satin/archival grade – chosen for its beautiful, vibrant colors and premium luster photo finish.  \n\nA frame is not included.",
      price: 120.0,
      originalPrice: 150.0,
      discount: 20,
      per: 185,
      originalPer: 200,
      qty: [1, 1],
      images: [
        "/images/shop/missionary/largemiss1.jpg",
        "/images/shop/missionary/largemiss2.jpg",
        "/images/shop/missionary/largemiss3.jpg",
        "/images/shop/missionary/largemiss4.jpg",
        "/images/shop/missionary/smallmiss1.jpg",
        "/images/shop/missionary/smallmiss2.jpg",
        "/images/shop/missionary/smallmiss3.jpg",
        "/images/shop/missionary/smallmiss4.jpg",
      ],
      category: "Missionary Photo Art",
      productName: "Art",
      options: MissionaryArtOptions,
      requiredForms: ["Missionary"],
    },
    // Small: {
    //   id: "mpa-small",
    //   title: "Small Missionary Photo Art - 16 x 20",
    //   description:
    //     "This 16” x 20” customized art piece is designed to capture the moments and memories of the returned missionary.  It features mission photos around the perimeter, a portrait shot of the missionary, photo of the mission president(s), and a map of the mission boundaries with highlighted cities/states/countries where served.  This can be further customized with the addition of a country flag(s), favorite scripture or two, companion information and/or photos, transfer information, etc. -- anything that would be meaningful to the missionary.\n\nIf you want the placement of the perimeter pictures to have a specific order, then rename each jpeg before you upload the file to our website.  Use this number system:  #1 for the photo in the upper left hand corner of the outside perimeter of pictures.  #2 for the photo to its immediate right.  Continue naming #3 through #44 in this clockwise manner.   Continue #45 through #80 starting with the upper left hand photo of the inner perimeter of pictures.\n\nIt is printed on Fuji Film Photo Paper/Satin/archival grade – chosen for its beautiful, vibrant colors and premium luster photo finish.  \n\nA frame is not included.",
    //   price: 120.0,
    //   originalPrice: 150.0,
    //   discount: 20,
    //   per: 120,
    //   originalPer: 150,
    //   qty: [1, 1],
    //   images: [
    //     "/images/shop/missionary/smallmiss1.jpg",
    //     "/images/shop/missionary/smallmiss2.jpg",
    //     "/images/shop/missionary/smallmiss3.jpg",
    //     "/images/shop/missionary/smallmiss4.jpg",
    //   ],
    //   category: "Missionary Photo Art",
    //   productName: "Small",
    //   options: MissionaryArtOptions,
    //   requiredForms: ["FamilyNames"],
    // },
    "Gift-Certificate": {
      id: "mpa-gift-certificate",
      title: "Gift Certificate for CUSTOM LAYOUT Photo Family Tree",
      description:
        "This Gift Certificate gives the recipient the opportunity to create a CUSTOM LAYOUT Photo Family Tree, which includes Generational Art, Grandparents Art, Combo: Ancestors & Posterity Art, Extended Family Tree Art, Perimeter Family Tree Art, Families & Siblings Art, and Special Requests.\n\nGift Certificates are available for all our family photo art products! They provide the perfect way to surprise someone for a special occasion -- and yet allow the recipient the opportunity to build the artwork to their liking.\n\nThe certificate is good for 12 months -- allowing time to select the needed photographs. We handle all gift certificates personally, so when the recipient is ready to order their product, they just need to contact us by phone or email to place their order.\n\nIf the recipient decides they want a different product than the one printed on their gift certificate, we will accommodate their request. If the new item is more expensive than the original purchase, then payment for the price difference is required.",
      price: 60.0,
      originalPrice: 90.0,
      discount: 33,
      per: 60,
      originalPer: 90,
      qty: [1, 1],
      images: [
        "/images/shop/missionary/giftcertificate.jpg",
        "/images/shop/missionary/giftcertificate2.jpg",
        "/images/shop/missionary/giftcertificate3.jpg",
        "/images/shop/missionary/giftcertificate4.jpg",
      ],
      category: "Missionary Photo Art",
      productName: "Gift Certificate",
      requiredForms: ["Missionary"],
    },
  },
  "Generational-Art": {
    Circular: {
      id: "ga-circular",
      title: "Circular Layout",
      description:
        'This Generational Art displays Father-to-Son or Mother-to-Daughter in a CIRCULAR presentation on a 16" x 20" Neutral Beige photo sheet.\n\nThis art may include as many generations as you have pictures available. Each photo will be given our company\'s unique signature style - the "STUDIO PORTRAIT LOOK." We digitally enhance each photo, extracting the head-and-shoulders image out of their original background and placing them on a background similar to all the other photos. Our goal is to make it look like each individual went down to the same local photo studio to have their picture taken.',
      price: 48.0,
      originalPrice: 60.0,
      discount: 20,
      per: 10,
      originalPer: 12,
      qtyDescription: "individuals/photos",
      qty: [5, 7],
      images: ["/images/genArt/circular.jpg"],
      category: "Generational Art",
      productName: "Circular Layout",
      options: GenArtOptions,
      requiredForms: ["Generational"],
    },

    Horizontal: {
      id: "ga-horizontal",
      title: "Horizontal Layout",
      description:
        'The Generational Art displays Father-to-Son or Mother-to-Daughter photos in a rectangular HORIZONTAL presentation. \n\nThis art may include as many generations as you have pictures available. Each photo will be given our company\'s unique signature style - the "STUDIO PORTRAIT LOOK." We digitally enhance each photo, extracting the head-and-shoulders image out of their original background and placing them on a background similar to all the other photos. Our goal is to make it look like each individual went down to the same local photo studio to have their picture taken.\n\nThe size is 8" tall and varies between 16" to 22" long depending on how many photos are chosen to display. The background is Neutral Beige.\n\nThe art comes as a print, or for an additional charge you may choose to have it mounted on black foam core board and coated with a satin-glaze laminant.  Finished in this manner the art does not need to be framed. It can be placed on a small table-top easel for display.  This is an additional purchase to place in your cart.',
      price: 48.0,
      originalPrice: 60.0,
      discount: 20,
      per: 12,
      originalPer: 15,
      qtyDescription: "individuals/photos",
      qty: [4, 13],
      images: [
        "/images/shop/generationalArt/horizontal.jpg",
        "/images/genArt/4gen.jpg",
        "/images/genArt/5gen.jpg",
        "/images/genArt/6gen.jpg",
        "/images/genArt/7gen.jpg",
        "/images/genArt/circular.jpg",
      ],
      category: "Generational Art",
      productName: "Horizontal Layout",
      options: GenArtOptions,
      requiredForms: ["Generational"],
    },
    Mounting: {
      id: "ga-mounting",
      title: "Horizontal Mounting",
      description:
        "As an optional finish for one's horizontal generational art, one may choose to have it mounted on black foam core board and coated with a satin-glaze laminate.  In this manner it is not necessary to frame the art.  Instead it can be placed on a small table-top easel for display.",
      price: 24.0,
      originalPrice: 30.0,
      discount: 20,
      per: 24,
      originalPer: 30,
      qtyDescription: "mounting",
      qty: [1, 1],
      images: ["/images/shop/generationalArt/mounting.jpg"],
      category: "Generational Art",
      productName: "Horizontal Mounting",
      requiredForms: ["Generational"],
    },
    Reprints: {
      id: "ga-reprints",
      title: "Reprints",
      description:
        "Reprint any of your completed Photo Art Products for just $25 each.  (This price does not include shipping.)\n\nOnce the artwork has been designed, we're not going to charge you again for the creating process.  That's why REPRINTS are such a great Family Reunion idea or Christmas gift idea because many families are able to enjoy our products at a significantly reduced price! \n\nNOTE:  Do NOT use the QUANTITY BUTTON when ordering multiple copies of your artwork or you will be charged full price for each additional one.  Instead, order through the REPRINTS icon.\n\nContact us if you are wanting more copies than we have listed.",
      price: 25.0,
      originalPrice: 25.0,
      discount: 0,
      per: 25,
      originalPer: 25,
      qtyDescription: "reprints",
      qty: [1, 10],
      images: ["/images/shop/generationalArt/reprint.jpg"],
      category: "Generational Art",
      productName: "Reprints",
      requiredForms: [],
    },
  },
  "Gift-Certificates": {
    "Basic-Family-Photo-Tree": {
      id: "gc-basic-family-photo-tree",
      title: "Gift Certificate for BASIC LAYOUT Photo Family Tree",
      description:
        "Gift Certificates provide the perfect way to surprise someone for a special occasion - and yet allow them the opportunity to build the artwork to their liking within the Basic Layout Guidelines. \n\nOur BASIC LAYOUT Photo Family Trees Gift Certificate is to be used for the creation of standardized layouts which do not require reconfiguration to accommodate your unique family structure.  They may have either a Traditional or Butterfly format, a Vertical or Horizontal orientation, and may be placed on any of our 5  background colors.\n\nThe certificate is good for 12 months -- allowing time to select the needed photographs. We handle all gift certificates personally, so when the recipient is ready to order their product, they just need to contact us by phone or email to place their order.",
      price: 48.0,
      originalPrice: 60.0,
      discount: 20,
      per: 8,
      originalPer: 10,
      qty: [6, 50],
      qtyDescription: "individuals/photos",
      images: [
        "/images/shop/photoFamilyTree/basicgift1.jpg",
        "/images/shop/photoFamilyTree/basicgift2.jpg",
        "/images/shop/photoFamilyTree/basicgift3.jpg",
        "/images/shop/photoFamilyTree/basicgift4.jpg",
      ],
      category: "Gift Certificates",
      productName: "Basic Layout",
      requiredForms: ["FamilyNames"],
    },
    "Custom-Family-Photo-Tree": {
      id: "gc-custom-family-photo-tree",
      title: "Gift Certificate for CUSTOM LAYOUT Photo Family Tree",
      description:
        "This Gift Certificate gives the recipient the opportunity to create a CUSTOM LAYOUT Photo Family Tree, which includes Generational Art, Grandparents Art, Combo: Ancestors & Posterity Art, Extended Family Tree Art, Perimeter Family Tree Art, Families & Siblings Art, and Special Requests.\n\nGift Certificates are available for all our family photo art products! They provide the perfect way to surprise someone for a special occasion -- and yet allow the recipient the opportunity to build the artwork to their liking.\n\nThe certificate is good for 12 months -- allowing time to select the needed photographs. We handle all gift certificates personally, so when the recipient is ready to order their product, they just need to contact us by phone or email to place their order.\n\nIf the recipient decides they want a different product than the one printed on their gift certificate, we will accommodate their request. If the new item is more expensive than the original purchase, then payment for the price difference is required.",
      price: 60.0,
      originalPrice: 90.0,
      discount: 33,
      per: 10,
      originalPer: 15,
      qtyDescription: "individuals/photos",
      qty: [6, 50],
      images: [
        "/images/shop/photoFamilyTree/customgift1.jpg",
        "/images/shop/photoFamilyTree/customgift2.jpg",
        "/images/shop/photoFamilyTree/customgift3.jpg",
        "/images/shop/photoFamilyTree/customgift4.jpg",
      ],
      category: "Gift Certificates",
      productName: "Custom Layout",
      requiredForms: ["FamilyNames"],
    },
    "Missionary-Photo-Art": {
      id: "gc-missionary-photo-art",
      title: "Gift Certificate for Missionary Photo Art",
      description:
        "This Gift Certificate gives the recipient the opportunity to create a Missionary Photo Art piece.  This customized art piece highlights the experiences of the returned missionary.  It captures in art form the people, places, and memories that touched their lives.  It is more than an art piece -- it is a conversation piece!\n\nGift Certificates are available for all our family photo art products! They provide the perfect way to surprise someone for a special occasion -- and yet allow the recipient the opportunity to build the artwork to their liking.\n\nThe certificate is good for 12 months -- allowing time to select the needed photographs. We handle all gift certificates personally, so when the recipient is ready to order their product, they just need to contact us by phone or email to place their order.\n\nIf the recipient decides they want a different product than the one printed on their gift certificate, we will accommodate their request. If the new item is more expensive than the original purchase, then payment for the price difference is required.",
      price: 60.0,
      originalPrice: 90.0,
      discount: 33,
      per: 10,
      originalPer: 15,
      qty: [1, 1],
      images: [
        "/images/shop/missionary/giftcertificate.jpg",
        "/images/shop/missionary/giftcertificate2.jpg",
        "/images/shop/missionary/giftcertificate3.jpg",
        "/images/shop/missionary/giftcertificate4.jpg",
      ],
      category: "Gift Certificates",
      productName: "Missionary Photo Art",
      requiredForms: ["Missionary"],
    },
  },
};
