import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Tooltip,
  IconButton,
  Badge,
  Divider,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { db, useAuth } from "../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { CartItem, FormTypes, Order } from "../shop/productTypes";
import { useNavigate } from "react-router-dom";
import { getFormPath } from "../../AppRouter";

const OrdersTab: React.FC = () => {
  const [done, setDone] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [pastOrders, setPastOrders] = useState<Order[]>([]);
  const navigate = useNavigate();

  const { user } = useAuth();
  const userId = user ? user.uid : null;

  // Fetch orders from Firestore
  useEffect(() => {
    const fetchOrders = async () => {
      const userDocRef = doc(db, "users", userId);

      try {
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const orderData = userData.orders || []; // Get orders array, or default to an empty array

          setOrders(orderData);
        } else {
          console.error("User document not found");
        }
      } catch (error) {
        console.error("Error fetching user document:", error);
      }
    };

    fetchOrders();
  }, [userId]);

  const handleClick = () => {
    setDone(!done);
  };

  return (
    <Box>
      {/* Current Orders */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Current Orders
        </Typography>
        <Divider />
        <List>
          <ListItem>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {/* Left-aligned text */}
              <ListItemText
                primary="Order #12345 - Example Order"
                secondary="Status: Waiting for Form Submission"
                sx={{ flexGrow: 1 }}
              />

              {/* Middle-aligned form badges */}
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  flexGrow: 1,
                }}
              >
                <Typography alignContent="center" sx={{}}>
                  Forms Required:
                </Typography>
                <Tooltip title="Family Tree Form">
                  <IconButton
                    onClick={() =>
                      navigate("/forms/customizing-your-family-tree")
                    }
                  >
                    <Badge
                      badgeContent={
                        done ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <ErrorIcon color="error" />
                        )
                      }
                    >
                      <AssignmentIcon color={done ? "disabled" : "error"} />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Generational Form">
                  <IconButton
                    onClick={() => navigate("/forms/generational-names")}
                  >
                    <Badge
                      badgeContent={
                        done ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <ErrorIcon color="error" />
                        )
                      }
                    >
                      <AssignmentIcon color={done ? "disabled" : "error"} />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Family Birthdays Form">
                  <IconButton
                    onClick={() =>
                      navigate("/forms/family-names-and-birthdates")
                    }
                  >
                    <Badge
                      badgeContent={
                        done ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <ErrorIcon color="error" />
                        )
                      }
                    >
                      <AssignmentIcon color={done ? "disabled" : "error"} />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Missionary Art Form">
                  <IconButton
                    onClick={() => navigate("/forms/missionary-form")}
                  >
                    <Badge
                      badgeContent={
                        done ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <ErrorIcon color="error" />
                        )
                      }
                    >
                      <AssignmentIcon color={done ? "disabled" : "error"} />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Right-aligned buttons */}
              <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                <Button variant="contained" color="primary">
                  Review Order
                </Button>
              </Box>
            </Box>
          </ListItem>
          <Divider />
          {orders.map((order, idx) => (
            <React.Fragment key={`Order-List-Item[${idx}]`}>
              <ListItem key={order.orderId}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Left-aligned text */}
                  <ListItemText
                    primary={`Order #${order.orderId}`}
                    secondary={`${order.description?.replaceAll(
                      ", ",
                      "\n"
                    )}\nStatus: ${order.status}`}
                    secondaryTypographyProps={{
                      component: "span",
                      whiteSpace: "pre-line",
                    }}
                    sx={{ flexGrow: 1 }}
                  />

                  {/* Middle-aligned form badges */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      flexGrow: 1,
                    }}
                  >
                    {Object.entries(order.formStatus)?.length > 0 && (
                      <>
                        <Typography alignContent="center">
                          Forms Required:
                        </Typography>
                        {Object.entries(order.formStatus || {}).map(
                          ([formId, done]) => (
                            <Tooltip key={formId} title={formId}>
                              <IconButton
                                onClick={() =>
                                  navigate(getFormPath(formId as FormTypes))
                                }
                              >
                                <Badge
                                  badgeContent={
                                    done ? (
                                      <CheckCircleIcon color="success" />
                                    ) : (
                                      <ErrorIcon color="error" />
                                    )
                                  }
                                >
                                  <AssignmentIcon
                                    color={done ? "disabled" : "error"}
                                  />
                                </Badge>
                              </IconButton>
                            </Tooltip>
                          )
                        )}
                      </>
                    )}
                  </Box>

                  {/* Right-aligned buttons */}
                  <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                    <Button variant="contained" color="primary">
                      Review Order
                    </Button>
                  </Box>
                </Box>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Paper>

      {/* Past Orders */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Past Orders
        </Typography>
        <List>
          {/* If pastOrders is empty, show a helpful message */}
          {pastOrders.length === 0 ? (
            <Typography variant="body1" color="textSecondary">
              You have no past orders.
            </Typography>
          ) : (
            /* Dynamically create ListItems from pastOrders */
            pastOrders.map((order) => (
              <ListItem key={order.orderId}>
                <ListItemText
                  primary={`Order #${order.orderId} - ${order.description}`}
                  secondary={`Completed on ${order.completedDate}`}
                />
                <Button variant="outlined" color="primary">
                  Reorder
                </Button>
              </ListItem>
            ))
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default OrdersTab;
