import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ProductPageCardWithMotion from "../../components/ProductPageCardWithMotion";
import { Product } from "../../components/ProductPageCard";

function GenerationalArtPage() {
  const products: Product[] = [
    {
      imgSrc: "/images/shop/generationalArt/circular.jpg",
      title: "Circular Layout",
      description: "Generational Art Circular Layout",
      price: "$50+",
      link: "/shop/Generational-Art/Circular",
    },
    {
      imgSrc: "/images/shop/generationalArt/horizontal.jpg",
      title: "Horizontal Layout",
      description: "Generation Art Horizontal Layout",
      link: "/shop/Generational-Art/Horizontal",
      price: "$48+",
    },
    {
      imgSrc: "/images/shop/generationalArt/mounting.jpg",
      title: "Horizontal Mounting",
      description: "Mounting of Horizontal Layout",
      price: "$24+",
      link: "/shop/Generational-Art/Mounting",
    },
    {
      imgSrc: "/images/shop/generationalArt/reprint.jpg",
      title: "Reprints",
      description: "Reprints of your completed Generational Art",
      price: "$25+",
      link: "/shop/Generational-Art/Reprints",
    },
  ];

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom sx={{ mt: 4 }}>
        Generational Art
      </Typography>

      <Grid container justifyContent="center" spacing={4} sx={{ mb: 4 }}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ProductPageCardWithMotion index={index} card={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GenerationalArtPage;
