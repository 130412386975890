import React from "react";
import {
  Box,
  Paper,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  FormControl,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  ProductType,
  ProductOption,
  SelectOption,
  NumberOption,
  BooleanOption,
} from "./productTypes";

type ProductOptionsSectionProps = {
  product: ProductType;
  selectedOptions: { [key: string]: any };
  onOptionChange: (optionId: string, value: any) => void;
};

const ProductOptionsSection: React.FC<ProductOptionsSectionProps> = ({
  product,
  selectedOptions,
  onOptionChange,
}) => {
  return (
    <Box>
      {product.options?.map((option: ProductOption) => {
        // Handle SelectOption type
        if (option.type === "select") {
          const selectOption = option as SelectOption; // Ensure it's SelectOption type
          return (
            <Paper
              key={selectOption.id}
              elevation={5}
              sx={{ padding: 2, marginBottom: 2 }}
            >
              <Typography variant="h6" gutterBottom>
                {selectOption.label}
              </Typography>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={selectedOptions[selectOption.id] || ""}
                  onChange={(e) =>
                    onOptionChange(selectOption.id, e.target.value)
                  }
                >
                  {selectOption.values.map((value) => (
                    <Grid
                      container
                      key={value.id}
                      spacing={2}
                      alignItems="center"
                    >
                      <Grid item xs={8}>
                        <FormControlLabel
                          value={value.id}
                          control={<Radio />}
                          label={
                            <>
                              {value.label}
                              {value.cost > 0 && (
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="span"
                                >
                                  (+${value.cost})
                                </Typography>
                              )}
                            </>
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {value.image && (
                          <img
                            src={value.image}
                            alt={value.label}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              borderRadius: "5px",
                              border:
                                selectedOptions[selectOption.id] === value.id
                                  ? "2px solid orange"
                                  : "1px solid #ccc",
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </RadioGroup>
              </FormControl>
            </Paper>
          );
        }

        // Handle NumberOption type
        if (option.type === "number") {
          const numberOption = option as NumberOption; // Ensure it's NumberOption type
          return (
            <Paper
              key={numberOption.id}
              elevation={5}
              sx={{ padding: 2, marginBottom: 2 }}
            >
              <Typography variant="h6" gutterBottom>
                {numberOption.label}
              </Typography>
              <TextField
                type="number"
                label={numberOption.label}
                value={selectedOptions[numberOption.id] || numberOption.default}
                onChange={(e) =>
                  onOptionChange(numberOption.id, Number(e.target.value))
                }
                inputProps={{ min: numberOption.min, max: numberOption.max }}
                fullWidth
              />
            </Paper>
          );
        }

        // Handle BooleanOption type
        if (option.type === "boolean") {
          const booleanOption = option as BooleanOption; // Ensure it's BooleanOption type
          return (
            <Paper
              key={booleanOption.id}
              elevation={5}
              sx={{
                padding: 2,
                marginBottom: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedOptions[booleanOption.id] || false}
                    onChange={(e) =>
                      onOptionChange(booleanOption.id, e.target.checked)
                    }
                  />
                }
                label={
                  <>
                    {booleanOption.label}
                    {booleanOption.cost > 0 && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        (+${booleanOption.cost})
                      </Typography>
                    )}
                  </>
                }
              />

              {/* Display Image on the right side if available */}
              {booleanOption.image && (
                <Box sx={{ marginLeft: "auto" }}>
                  <img
                    src={booleanOption.image}
                    alt={booleanOption.label}
                    style={{ maxWidth: "100px", borderRadius: "8px" }}
                  />
                </Box>
              )}
            </Paper>
          );
        }

        return null; // Handle unexpected option types
      })}
    </Box>
  );
};

export default ProductOptionsSection;
