import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { AuthContext, db } from "../../../firebase";
import MissionaryInfoContent from "./MissionaryInfoContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { enqueueSnackbar } from "notistack";
import { CompanionDetails } from "./CompanionList";

export interface Missionary {
  id: string;
  name: string;
  missionDetails: MissionDetails;
  companionList: CompanionDetails[];
  notes: string;
  imageFilePath: string;
}

export type MissionDetails = {
  missionName: string;
  start: string;
  end: string;
  president: string;
};

const MissionaryArtTab: React.FC = () => {
  const [missionaries, setMissionaries] = useState<Missionary[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) return;
    const fetchMissionaries = async () => {
      setIsLoading(true);
      try {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const loadedMissionaries = docSnap.data().missionaries || [];
          setMissionaries(loadedMissionaries);
          // Automatically select the first tab if missionaries exist
          if (loadedMissionaries.length > 0) {
            setSelectedTabIndex(0); // Select the first tab
          }
        }
      } catch (error) {
        console.error("Error fetching missionaries: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMissionaries();
  }, [user]);

  const handleMissionarySelect = (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    setSelectedTabIndex(newIndex);
  };

  const handleAddMissionary = () => {
    const newMissionary: Missionary = {
      id: `missionary-${Date.now()}`,
      name: "New Missionary",
      notes: "",
      imageFilePath: "",
      missionDetails: {
        missionName: "",
        start: "",
        end: "",
        president: "",
      },
      companionList: [],
    };
    setMissionaries([...missionaries, newMissionary]);
    setSelectedTabIndex(missionaries.length); // Select the new missionary (last tab)
  };

  const handleSaveMissionaries = async (updatedMissionaries: Missionary[]) => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      try {
        await setDoc(
          userDocRef,
          { missionaries: updatedMissionaries },
          { merge: true }
        );
      } catch (error) {
        console.error("Error saving missionaries: ", error);
        enqueueSnackbar("Error saving missionaries", { variant: "error" });
      }
    }
  };

  const selectedMissionary = missionaries[selectedTabIndex];

  const onMissionaryUpdate = (updatedMissionary: Missionary) => {
    const updatedMissionaries = missionaries.map((missionary) =>
      missionary.id === updatedMissionary.id ? updatedMissionary : missionary
    );
    setMissionaries(updatedMissionaries);
    handleSaveMissionaries(updatedMissionaries);
  };

  return (
    <Box display="flex">
      <Tabs
        orientation="vertical"
        value={selectedTabIndex}
        onChange={handleMissionarySelect}
        sx={{ borderRight: 1, borderColor: "divider", minWidth: 200 }}
      >
        {missionaries.map((missionary, index) => (
          <Tab key={missionary.id} label={missionary.name} value={index} />
        ))}
        <Tab icon={<AddIcon />} label="Add" onClick={handleAddMissionary} />
      </Tabs>

      <Box flex={1} padding={2}>
        {selectedMissionary ? (
          <MissionaryInfoContent
            missionaryId={selectedMissionary.id}
            onMissionaryUpdate={onMissionaryUpdate}
          />
        ) : (
          <Typography variant="h6">
            Select or add a missionary to begin.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MissionaryArtTab;