import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";

const NewsletterBanner = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100vw",
        height: "88px",
        backgroundColor: "#5C0629",
        padding: 0,
      }}
    >
      <Grid item>
        <Typography
          variant="h6"
          style={{ color: "#be8d4b", fontFamily: "Playfair Display" }}
        >
          NEWSLETTER SIGNUP
        </Typography>
        <Typography variant="body1" style={{ color: "white" }}>
          Sign up to receive promo’s via email
        </Typography>
      </Grid>
      <Grid item style={{ padding: 10 }}>
        <TextField
          id="outlined-basic"
          label="Email Address"
          variant="outlined"
          InputLabelProps={{ style: { color: "white" } }}
          inputProps={{ style: { color: "white" } }}
          sx={{
            width: "400px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
            "& .MuiInputLabel-root": {
              color: "white",
            },
            "& .MuiInputBase-input": {
              color: "white",
            },
          }}
        />
        {/* <WhiteBorderTextField 
                id="outlined-basic" 
                label="Email Address" 
                variant="outlined" 
                InputLabelProps={{ style: { color: "white" } }} 
                inputProps={{ style: { color: "white" } }} 
                style={{width: "400px", color: "white"}}/> */}
      </Grid>
      <Grid item style={{ padding: 10 }}>
        <Button
          variant="outlined"
          style={{ color: "white", borderColor: "white" }}
        >
          SUBSCRIBE
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewsletterBanner;
