import React, { useState, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Badge,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../firebase";
import { useSnackbar } from "notistack";
import { useShoppingCart } from "../pages/Checkout/ShoppingCartProvider";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const linkArray = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Purchase",
    link: "/purchase",
  },
  {
    title: "Photo Family Trees",
    link: "/photo-family-trees",
    subLinks: [
      { title: "Overview", link: "/photo-family-trees" },
      { title: "Custom Layouts", link: "/custom-layout-options" },
      {
        title: "Family Tree Example Gallery",
        link: "/family-tree-example-gallery",
      },
      {
        title: "Photo Enhancements",
        link: "/photo-enhancements",
      },
      {
        title: "Background Colors",
        link: "/background-colors-for-family-trees",
      },
      {
        title: "LDS Temple in Background",
        link: "/family-trees-with-lds-temple-backgrounds ",
      },
      {
        title: "Ordering a Photo Family Tree",
        link: "/ordering?product=photo-family-tree",
      },
    ],
  },
  // {
  //   title: "Family Proclamations",
  //   link: "/family-proclamations",
  //   subLinks: [
  //     { title: "Overview", link: "/family-proclamations" },
  //     {
  //       title: "Ordering a Family Proclamation",
  //       link: "/ordering?product=family-proclamation",
  //     },
  //   ],
  // },
  {
    title: "Missionary Photo Art",
    link: "/missionary-photo-art",
    subLinks: [
      { title: "Overview", link: "/missionary-photo-art" },
      {
        title: "Ordering Missionary Photo Art",
        link: "/ordering?product=missionary-photo-art",
      },
    ],
  },
  {
    title: "Generational Art",
    link: "/generational-art",
    subLinks: [
      { title: "Overview", link: "/generational-art" },
      {
        title: "Ordering Generational Art",
        link: "/ordering?product=generational-art",
      },
    ],
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
];

const FamilyTreeAppBar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [avatarMenuAnchor, setAvatarMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const { user, signOut } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { cart } = useShoppingCart();

  const handleMenuClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    title: string
  ) => {
    const linkObject = linkArray.find((item) => item.title === title);
    if (!linkObject?.subLinks) {
      navigate(linkObject?.link || "/");
    }

    if (openMenu === title) {
      setAnchorEl(null);
      setOpenMenu(null);
    } else {
      setAnchorEl(event.currentTarget);
      setOpenMenu(title);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const handleAvatarMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAvatarMenuAnchor(event.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchor(null);
  };

  const handleSignOut = () => {
    signOut();
    enqueueSnackbar("Signed out successfully", { variant: "success" });
    navigate("/");
  };

  return (
    <AppBar position="static" style={{ height: "50px" }}>
      <Toolbar
        variant="dense"
        sx={{
          margin: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {linkArray.map((item) => (
            <div key={item.title} style={{ display: "inline-block" }}>
              <Link
                color="inherit"
                underline="none"
                onClick={(e) => handleMenuClick(e, item.title)}
                sx={{ marginRight: 2, fontSize: "16px", cursor: "pointer" }}
              >
                {item.title.toLocaleUpperCase()}
              </Link>
              {item.subLinks && (
                <Menu
                  anchorEl={anchorEl}
                  open={openMenu === item.title}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {item.subLinks.map((subLink) => (
                    <MenuItem
                      key={subLink.title}
                      component={RouterLink}
                      to={subLink.link}
                      onClick={handleMenuClose}
                    >
                      {subLink.title}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </div>
          ))}
        </div>

        {/* Shopping Cart Icon with Badge */}
        <IconButton component={RouterLink} to="/cart" color="inherit">
          <Badge badgeContent={cart?.length || 0} color="error">
            <ShoppingCartIcon sx={{ color: "white" }} />
          </Badge>
        </IconButton>

        {/* Conditional Rendering based on user authentication */}
        <div>
          {!user ? (
            <Button color="inherit" onClick={() => navigate("/sign-in")}>
              Sign In
            </Button>
          ) : (
            <div>
              <IconButton onClick={handleAvatarMenuOpen}>
                <Avatar alt={user.displayName} src={user.photoURL} />
              </IconButton>
              <Menu
                anchorEl={avatarMenuAnchor}
                open={Boolean(avatarMenuAnchor)}
                onClose={handleAvatarMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => navigate("/account")}>
                  Account
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleSignOut();
                    handleAvatarMenuClose();
                  }}
                >
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default FamilyTreeAppBar;
