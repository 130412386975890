import { Box, Grid, Button, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";

const PromoBanner = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100vw",
        height: "340px",
        backgroundColor: "rgba(92,6,41,0.90",
      }}
    >
      <Grid item alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          style={{ color: "white", textAlign: "center", opacity: 1 }}
        >
          Our products are designed to showcase the family. They make
          meaningful, memorable gifts and are great for family reunions.
        </Typography>
      </Grid>
      <Grid item alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          style={{
            color: "white",
            width: "100vw",
            textAlign: "center",
            opacity: 1,
          }}
        >
          More than just art pieces -- they are CONVERSATION PIECES!
        </Typography>
      </Grid>

      <Grid item alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          style={{ color: "white", textAlign: "center", opacity: 1 }}
        >
          Let the power of pictures strengthen your family!
        </Typography>
      </Grid>

      <Grid item alignItems="center" justifyContent="center">
        <Typography
          variant="h5"
          style={{
            color: "#be8d4b",
            padding: 20,
            textAlign: "center",
            opacity: 1,
          }}
        >
          Order <i>your</i> keepsake art today!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PromoBanner;
