import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Link,
  CssBaseline,
} from "@mui/material";
import MainLayoutFooter from "../pages/Home/Footer";
import FamilyTreeAppBar from "../components/FamilyTreeAppBar";

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <FamilyTreeAppBar />
      <Box component="main" display="flex" flexDirection={"column"}>
        {children}
      </Box>
      <MainLayoutFooter />
    </>
  );
};

export default MainLayout;
