import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const GenerationalForm: React.FC = () => {
  return (
    <Box
      sx={{ maxWidth: "900px", margin: "auto", mt: 4, mb: 4 }}
      component="form"
    >
      <Typography variant="h4" gutterBottom>
        Generational Names & Birthdates
      </Typography>
      <Typography variant="body1" paragraph>
        Fill out the appropriate fields which correspond with how many
        generations will be featured in your art. A birthdate and deathdate are
        optional. You may choose other information to share about them, such as
        "Member of the Martin Handcart Company."
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="label">
            Your Name*
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="yourName" variant="outlined" required />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="label">
            Title for the top of your Art Piece*
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="artTitle" variant="outlined" required />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="label">
            Featured Individual's Name*
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="featuredName"
            variant="outlined"
            required
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="label">
            Their birthdate
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="featuredBirthdate" variant="outlined" />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography variant="h6" component="label">
            Any additional information you want to add
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="featuredAdditionalInfo"
            variant="outlined"
          />
        </Grid>

        {[
          "mother/father",
          "grandmother/father",
          "great grandmother/father",
          "great, great grandmother/father",
          "great, great, great grandmother/father",
          "great, great, great, great grandmother/father",
        ].map((relation, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h6" component="label">
                Featured individual's {relation} as applicable
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name={`relation_${index}_name`}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="h6" component="label">
                Their birthdate
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name={`relation_${index}_birthdate`}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography variant="h6" component="label">
                Any additional information you want to add
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                multiline
                rows={4}
                name={`relation_${index}_additionalInfo`}
                variant="outlined"
              />
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12} sm={6}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GenerationalForm;
