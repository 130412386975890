import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const fields = [
  { name: "yourName", label: "Your Name*" },
  {
    name: "chosenMissionArtOption",
    label: "Chosen Mission Art Option: Large or Small*",
  },
  { name: "missionaryName", label: "Name of Missionary*" },
  { name: "missionName", label: "Name of Mission where served*" },
  { name: "missionPresident", label: "Name of Mission President and his wife" },
  {
    name: "secondMissionPresident",
    label: "Name of 2nd Mission president and his wife, if applicable",
  },
  {
    name: "missionaryServiceDates",
    label: "Dates when the missionary served*",
  },
  {
    name: "missionPresidentServiceDates",
    label: "Dates when the Mission President served",
  },
  {
    name: "secondMissionPresidentServiceDates",
    label: "Dates when the 2nd Mission president served, if applicable",
  },
  { name: "favoriteScripture", label: "A favorite scripture or two" },
  {
    name: "flags",
    label: "Name of country/countries whose flags you want shown",
  },
  {
    name: "locationsOnMap",
    label:
      "Names of cities/states/countries you want identified on your mission map",
  },
  ...Array.from({ length: 18 }, (_, i) => [
    {
      name: `companion_${i + 1}_name`,
      label: `Name of ${i + 1}${
        i + 1 === 1 ? "st" : i + 1 === 2 ? "nd" : i + 1 === 3 ? "rd" : "th"
      } companion${i >= 10 ? ", if applicable" : ""}`,
    },
    {
      name: `companion_${i + 1}_info`,
      label: `Info about ${i + 1}${
        i + 1 === 1 ? "st" : i + 1 === 2 ? "nd" : i + 1 === 3 ? "rd" : "th"
      } companion`,
    },
  ]).flat(),
];

const MissionaryForm: React.FC = () => {
  const [formData, setFormData] = React.useState(() => {
    const initialData: { [key: string]: string } = {};
    fields.forEach((field) => {
      initialData[field.name] = "";
    });
    return initialData;
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
  };

  return (
    <Box
      sx={{ maxWidth: "900px", margin: "auto", mt: 4, mb: 4 }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Typography variant="h4" gutterBottom>
        Missionary Photo Art
      </Typography>
      <Typography variant="body1" paragraph>
        In order to create your customized Missionary Photo Art, we need the
        following information. Fill in the appropriate fields relative to your
        chosen layout.
      </Typography>
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid item xs={12} key={index}>
            <TextField
              fullWidth
              name={field.name}
              label={field.label}
              variant="outlined"
              value={formData[field.name]}
              onChange={handleInputChange}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button variant="contained" type="submit" fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MissionaryForm;
