import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Divider, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";

import { useSnackbar } from "notistack";
import Swal from "sweetalert2";
import { registerNewUser } from "../../utils/firebaseUtils";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export const isValidEmail = (email: string) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
};

export default function SignUpPage() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const registration = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      password: data.get("password"),
    };
    validateFieldsAndSubmit(registration);
  };

  const showErrorMessage = (message: string) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
    });
  };

  const validateFieldsAndSubmit = (registration: any) => {
    if (!isValidEmail(registration.email)) {
      showErrorMessage("Please enter a valid email address");
      return;
    }
    if (registration.password.length < 5) {
      showErrorMessage("Please enter a longer password");
      return;
    }
    if (registration.firstName.length < 1 || registration.lastName.length < 1) {
      showErrorMessage("Please enter your first and last name");
      return;
    }
    submitRegistration(registration);
  };

  const successCallback = (data: any) => {
    Swal.fire({
      title: "Registration Successful",
      text: "Registration submitted succesfully! Check your email for validation.",
      icon: "success",
      confirmButtonText: "OKAY",
    }).then(() => {
      navigate("/signIn");
    });
  };

  const failureCallback = (data: any) => {
    showErrorMessage(data);
  };

  const submitRegistration = (registration: any) => {
    registerNewUser(registration, successCallback, failureCallback);
  };

  const handleGoogleSignUp = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // Successfully signed up with Google
        Swal.fire({
          title: "Sign Up Successful",
          text: "Welcome! You've signed up with Google.",
          icon: "success",
          confirmButtonText: "OKAY",
        }).then(() => {
          navigate("/");
        });
      })
      .catch((error) => {
        showErrorMessage("Error signing up with Google: " + error.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Divider sx={{ my: 2 }}>OR</Divider>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleGoogleSignUp}
            sx={{ mb: 2 }}
          >
            Sign Up with Google
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/signIn" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
