import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Link,
  CssBaseline,
} from "@mui/material";
import MainLayoutFooter from "../pages/Home/Footer";
import FamTreeTextHeroBanner from "../components/FamTreeTextHeroBanner";
import FamilyTreeAppBar from "../components/FamilyTreeAppBar";

interface SubpageLayoutProps {
  children: React.ReactNode;
}

const SubpageLayout: React.FC<SubpageLayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <FamilyTreeAppBar />
      <FamTreeTextHeroBanner />
      <Box
        component="main"
        display="flex"
        flexDirection={"column"}
        style={{ backgroundColor: "white" }}
      >
        {children}
      </Box>
      <MainLayoutFooter />
    </>
  );
};

export default SubpageLayout;
