import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";

const PhotoFamilyProclamationPage: React.FC = () => {
  return (
    <Container maxWidth="md" style={{ backgroundColor: "white" }}>
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Photo Family Proclamation
        </Typography>

        <Typography variant="body1" paragraph>
          Our digitally-designed, customized Photo Family Proclamation features
          the Church-produced document,{" "}
          <em>THE FAMILY: A Proclamation to the World</em>. In keeping with
          copyright laws, we do not reformat nor print the Proclamation on the
          art piece; rather, we use the actual Proclamation which is placed and
          permanently attached to the top of a 16”x20” photo sheet personalized
          with your family pictures around the perimeter.
        </Typography>

        <Typography variant="body1" paragraph>
          You may choose either a 28-photo spot Proclamation or a 16-spot
          Proclamation, both either with or without the family name as a title.
        </Typography>

        <Typography variant="body1" paragraph>
          A frame is <strong>NOT</strong> included.
        </Typography>

        <Typography variant="body1" paragraph>
          Family Proclamation Art is priced at $75 for the 16-spot and $90 for
          the 28-spot. Reprints are $25 each.
        </Typography>

        {/* Images with Captions */}
        <Grid container spacing={2} sx={{ my: 4 }}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/familyProclamation/famproc1.jpg"
                alt="28-spot Proclamation with Family title"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  28-spot Proclamation with Family title
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/familyProclamation/famproc2.jpg"
                alt="28-spot Proclamation without Family title"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  28-spot Proclamation without Family title
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/familyProclamation/famproc3.jpg"
                alt="16-spot Proclamation with Family title"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  16-spot Proclamation with Family title
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardMedia
                component="img"
                image="images/familyProclamation/famproc4.jpg"
                alt="16-spot Proclamation without Family title"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary">
                  16-spot Proclamation without Family title
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Link
              to="/purchase"
              style={{
                textDecoration: "none",
                fontWeight: 800,
                fontSize: "32px",
                color: "rgb(192, 178, 131)",
              }}
            >
              CLICK TO PURCHASE
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PhotoFamilyProclamationPage;
