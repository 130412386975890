import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { TreeViewBaseItem } from "@mui/x-tree-view/models";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { familyTreeData } from "./family";
import {
  Avatar,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AuthContext, db, storage } from "../../firebase";
import { doc, onSnapshot, setDoc } from "firebase/firestore";

type FamilyMember = TreeViewBaseItem<{
  id: string;
  name: string;
  birthdate: string;
  imageMetadata: string;
  children?: FamilyMember[];
}>;

const findFamilyMember = (
  memberId: string,
  familyData: FamilyMember[]
): FamilyMember | null => {
  for (const member of familyData) {
    if (member.id === memberId) {
      return member;
    }
    if (member.children) {
      const found = findFamilyMember(memberId, member.children);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const FamilyTreeTab: React.FC = () => {
  const [familyTree, setFamilyTree] = useState<FamilyMember[]>(familyTreeData);
  const [selectedMember, setSelectedMember] = useState<FamilyMember | null>(
    null
  );
  const [isEdited, setIsEdited] = useState(false);
  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [image, setImage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [userId, setUserId] = useState<string>("");

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!user) return;
    const userDocRef = doc(db, "users", user.uid);
    setUserId(user.uid);

    setLoading(true); // Start loading
    // Set up the onSnapshot listener
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setFamilyTree(docSnapshot.data().familyTreeData || []);
      } else {
        console.log("No such document!");
      }
      setLoading(false); // Stop loading after data is fetched
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (selectedMember) {
      setName(selectedMember.name);
      setBirthdate(selectedMember.birthdate);
      setImage(selectedMember.imageMetadata);
      setIsEdited(false); // Reset edit state when a new member is selected
    }
  }, [selectedMember]);

  const handleSave = async () => {
    if (selectedMember) {
      const updatedTree = updateFamilyMember(familyTree, selectedMember.id, {
        name,
        birthdate,
      });
      setFamilyTree(updatedTree);

      try {
        setLoading(true); // Start loading
        // Update Firestore with the new familyTree
        const userDocRef = doc(db, "users", userId);
        await setDoc(
          userDocRef,
          { familyTreeData: updatedTree },
          { merge: true }
        );
        setIsEdited(false); // Reset edit state after saving
      } catch (error) {
        console.error("Error saving family tree data: ", error);
      } finally {
        setLoading(false); // Stop loading after the operation is complete
      }
    }
  };

  const handleFieldChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => {
    setter(value);
    setIsEdited(true); // Enable the submit button when a field is edited
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    memberId: string
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const storageRef = ref(storage, `users/${userId}/images/${memberId}.jpg`);

      setLoading(true); // Start loading
      try {
        // Upload the image to Firebase Storage
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        // Update the local state with the download URL
        const updatedTree = updateFamilyMember(familyTree, memberId, {
          imageMetadata: downloadURL,
        });
        setFamilyTree(updatedTree);
        setImage(downloadURL);
        setIsEdited(true); // Enable the submit button when the image is changed

        // Update Firestore with the new familyTreeData
        const userDocRef = doc(db, "users", userId);
        await setDoc(
          userDocRef,
          { familyTreeData: updatedTree },
          { merge: true }
        );
      } catch (error) {
        console.error("Error uploading image: ", error);
      } finally {
        setLoading(false); // Stop loading after the operation is complete
      }
    }
  };

  // Recursive function to update the family member in the tree
  const updateFamilyMember = (
    members: FamilyMember[],
    id: string,
    updatedData: Partial<FamilyMember>
  ): FamilyMember[] => {
    return members.map((member) => {
      if (member.id === id) {
        return { ...member, ...updatedData };
      }
      if (member.children) {
        return {
          ...member,
          children: updateFamilyMember(member.children, id, updatedData),
        };
      }
      return member;
    });
  };

  const onItemClick = (event: any, item: any) => {
    const member = findFamilyMember(item, familyTree);
    if (member) {
      setSelectedMember(member);
    }
  };

  // Function to render the item label as a string
  const getItemLabel = (item: FamilyMember) => item.name;

  return (
    <Box>
      <Box sx={{ display: "flex", padding: "24px", alignItems: "flex-start" }}>
        <Box sx={{ flexGrow: 1, marginRight: "24px" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <RichTreeView
              items={familyTree}
              getItemLabel={getItemLabel}
              onItemClick={onItemClick}
            />
          )}
        </Box>
        <Box sx={{ flex: 2, maxWidth: "500px" }}>
          {selectedMember && (
            <Card elevation={5} sx={{ maxWidth: 800 }}>
              <CardContent sx={{ display: "flex" }}>
                <Box sx={{ marginRight: "16px" }}>
                  <Avatar
                    src={loading ? undefined : image || "/default-avatar.png"}
                    sx={{ width: 240, height: 240 }}
                  >
                    {loading && <CircularProgress />}
                  </Avatar>
                  <input
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={(event) =>
                      handleImageChange(event, selectedMember.id)
                    }
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      disabled={loading} // Disable button while loading
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <TextField
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={name}
                    onChange={(e) => handleFieldChange(setName, e.target.value)}
                    disabled={loading} // Disable field while loading
                  />
                  <TextField
                    margin="dense"
                    label="Birthdate"
                    type="date"
                    fullWidth
                    variant="outlined"
                    value={birthdate}
                    onChange={(e) =>
                      handleFieldChange(setBirthdate, e.target.value)
                    }
                    disabled={loading} // Disable field while loading
                  />
                  {/* Add more fields here if needed */}
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  onClick={handleSave}
                  color="primary"
                  variant="contained"
                  disabled={!isEdited || loading} // Disable the button unless a field is edited or loading
                >
                  Save
                </Button>
              </CardActions>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FamilyTreeTab;
