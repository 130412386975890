export const familyTreeData = [
  {
    id: "self",
    name: "Your Name",
    birthdate: "YYYY-MM-DD",
    imageMetadata: "Placeholder for image metadata",
    children: [
      {
        id: "father",
        name: "Father's Name",
        birthdate: "YYYY-MM-DD",
        imageMetadata: "Placeholder for image metadata",
        children: [
          {
            id: "paternal-grandfather",
            name: "Paternal Grandfather's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
            children: [
              {
                id: "paternal-great-grandfather",
                name: "Paternal Great Grandfather's Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
                children: [
                  {
                    id: "paternal-great-great-grandfather",
                    name: "Paternal Great Great Grandfather's Name",
                    birthdate: "YYYY-MM-DD",
                    imageMetadata: "Placeholder for image metadata",
                  },
                  {
                    id: "paternal-great-great-grandmother",
                    name: "Paternal Great Great Grandmother's Name",
                    birthdate: "YYYY-MM-DD",
                    imageMetadata: "Placeholder for image metadata",
                  },
                ],
              },
              {
                id: "paternal-great-grandmother",
                name: "Paternal Great Grandmother's Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
            ],
          },
          {
            id: "paternal-grandmother",
            name: "Paternal Grandmother's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
            children: [
              {
                id: "paternal-great-grandfather-mother-side",
                name: "Paternal Great Grandfather (Mother's Side) Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
              {
                id: "paternal-great-grandmother-mother-side",
                name: "Paternal Great Grandmother (Mother's Side) Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
            ],
          },
        ],
      },
      {
        id: "mother",
        name: "Mother's Name",
        birthdate: "YYYY-MM-DD",
        imageMetadata: "Placeholder for image metadata",
        children: [
          {
            id: "maternal-grandfather",
            name: "Maternal Grandfather's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
            children: [
              {
                id: "maternal-great-grandfather",
                name: "Maternal Great Grandfather's Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
                children: [
                  {
                    id: "maternal-great-great-grandfather",
                    name: "Maternal Great Great Grandfather's Name",
                    birthdate: "YYYY-MM-DD",
                    imageMetadata: "Placeholder for image metadata",
                  },
                  {
                    id: "maternal-great-great-grandmother",
                    name: "Maternal Great Great Grandmother's Name",
                    birthdate: "YYYY-MM-DD",
                    imageMetadata: "Placeholder for image metadata",
                  },
                ],
              },
              {
                id: "maternal-great-grandmother",
                name: "Maternal Great Grandmother's Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
            ],
          },
          {
            id: "maternal-grandmother",
            name: "Maternal Grandmother's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
            children: [
              {
                id: "maternal-great-grandfather-mother-side",
                name: "Maternal Great Grandfather (Mother's Side) Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
              {
                id: "maternal-great-grandmother-mother-side",
                name: "Maternal Great Grandmother (Mother's Side) Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "spouse",
    name: "Spouse Name",
    birthdate: "2000-01-01",
    imageMetadata: "Placeholder for image metadata",
    children: [
      {
        id: "spouse-father",
        name: "Spouse's Father's Name",
        birthdate: "YYYY-MM-DD",
        imageMetadata: "Placeholder for image metadata",
        children: [
          {
            id: "spouse-paternal-grandfather",
            name: "Spouse's Paternal Grandfather's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
            children: [
              {
                id: "spouse-paternal-great-grandfather",
                name: "Spouse's Paternal Great Grandfather's Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
              {
                id: "spouse-paternal-great-grandmother",
                name: "Spouse's Paternal Great Grandmother's Name",
                birthdate: "YYYY-MM-DD",
                imageMetadata: "Placeholder for image metadata",
              },
            ],
          },
          {
            id: "spouse-paternal-grandmother",
            name: "Spouse's Paternal Grandmother's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
          },
        ],
      },
      {
        id: "spouse-mother",
        name: "Spouse's Mother's Name",
        birthdate: "YYYY-MM-DD",
        imageMetadata: "Placeholder for image metadata",
        children: [
          {
            id: "spouse-maternal-grandfather",
            name: "Spouse's Maternal Grandfather's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
          },
          {
            id: "spouse-maternal-grandmother",
            name: "Spouse's Maternal Grandmother's Name",
            birthdate: "YYYY-MM-DD",
            imageMetadata: "Placeholder for image metadata",
          },
        ],
      },
    ],
  },
];
