import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { CartItem, ProductType, ProductOption } from "../shop/productTypes";

type ShoppingCartContextType = {
  cart: CartItem[];
  addToCart: (
    product: ProductType,
    quantity: number,
    selectedOptions: { [key: string]: any }
  ) => void;
  removeFromCart: (productId: string) => void;
  updateQuantity: (productId: string, quantity: number) => void;
  clearCart: () => void;
};

const ShoppingCartContext = createContext<ShoppingCartContextType | undefined>(
  undefined
);

type ShoppingCartProviderProps = {
  children: ReactNode;
};

export const ShoppingCartProvider: React.FC<ShoppingCartProviderProps> = ({
  children,
}) => {
  const userId = auth.currentUser?.uid;
  const [cart, setCart] = useState<CartItem[]>([]);

  useEffect(() => {
    const fetchCart = async () => {
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const cartData = userDoc.data()?.shoppingCart || [];
          setCart(cartData);
        }
      }
    };

    fetchCart();
  }, [userId]);

  const updateCartInFirestore = async (updatedCart: CartItem[]) => {
    if (userId) {
      const userDocRef = doc(db, "users", userId);
      await setDoc(userDocRef, { shoppingCart: updatedCart }, { merge: true });
    }
  };

  const getOptionSummary = (
    options: ProductOption[],
    selectedOptions: { [key: string]: any }
  ) => {
    return options
      .map((option) => {
        const selectedValue = selectedOptions[option.id];
        if (option.type === "select") {
          const selectedOption = option.values.find(
            (value) => value.id === selectedValue
          );
          return selectedOption
            ? `${option.label}: ${selectedOption.label}`
            : `${option.label}: undecided`;
        }
        if (option.type === "number") {
          return `${option.label}: ${selectedValue}`;
        }
        if (option.type === "boolean") {
          return `${option.label}: ${selectedValue ? "Yes" : "No"}`;
        }
        return "";
      })
      .join("\n");
  };

  const addToCart = (
    product: ProductType,
    quantity: number,
    selectedOptions: { [key: string]: any }
  ) => {
    // Generate the description and image based on selected options
    const description = getOptionSummary(
      product.options || [],
      selectedOptions
    );
    const image = product.images[0]; // You can choose to select based on options if needed

    const cartItem: CartItem = {
      productId: product.id,
      name: product.title,
      price: product.price,
      quantity,
      selectedOptions,
      description,
      image,
      requiredForms: product.requiredForms,
    };

    let updatedCart;

    // if (existingItemIndex !== -1) {
    //   updatedCart = cart.map((cartItem, index) =>
    //     index === existingItemIndex
    //       ? { ...cartItem, quantity: cartItem.quantity + quantity }
    //       : cartItem
    //   );
    // } else {
    updatedCart = [...cart, cartItem];
    // }

    setCart(updatedCart);
    updateCartInFirestore(updatedCart);
  };

  const removeFromCart = (productId: string) => {
    const updatedCart = cart.filter((item) => item.productId !== productId);
    setCart(updatedCart);
    updateCartInFirestore(updatedCart);
  };

  const updateQuantity = (productId: string, quantity: number) => {
    const updatedCart = cart.map((item) =>
      item.productId === productId ? { ...item, quantity } : item
    );
    setCart(updatedCart);
    updateCartInFirestore(updatedCart);
  };

  const clearCart = () => {
    setCart([]);
    updateCartInFirestore([]);
  };

  return (
    <ShoppingCartContext.Provider
      value={{ cart, addToCart, removeFromCart, updateQuantity, clearCart }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

export const useShoppingCart = (): ShoppingCartContextType => {
  const context = useContext(ShoppingCartContext);
  if (!context) {
    throw new Error(
      "useShoppingCart must be used within a ShoppingCartProvider"
    );
  }
  return context;
};
