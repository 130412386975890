import { Stack, Grid, Divider, Link, Typography, Box } from "@mui/material";
import React, { Fragment } from "react";
import { Image } from "@mui/icons-material";
import styled from "styled-components";
import StyledLink from "./StyledLink";

const CustomLink = styled.a`
  color: #c0b283;
  text-decoration: none;

  &:hover {
    font-weight: bold;
    text-decoration: none;
  }
`;

const categories = [
  "Photo Family",
  "FAMILY TREES",
  // "PROCLAMATIONS",
  "MISSIONARIES",
  "GENERATIONAL ART",
  "CONTACT US",
];

const contactArray = [
  {
    title: "Home",
    width: 75,
    link: "/",
  },
];

const shopArray = [
  {
    title: "Photo Family Trees",
    link: "/shop/Photo-Family-Trees",
  },
  // {
  //   title: "Family Proclamations",
  //   link: "/family-proclamations",
  // },
  {
    title: "Missionary Photo Art",
    link: "/shop/Missionary-Photo-Art",
  },
  {
    title: "Generational Art",
    link: "/shop/Generational-Art",
  },
  {
    title: "Gift Certificiates",
    link: "/shop/Gift-Certificates",
  },
];

const informationArray = [
  {
    title: "Photographs",
    link: "/photograph-guidelines",
  },
  {
    title: "Scanning",
    link: "/scanning-guidelines",
  },
  {
    title: "Policies",
    link: "/policies",
  },
  {
    title: "Price List",
    link: "/price-list",
  },
  {
    title: "FAQ",
    link: "/faq",
  },
];

type ListItemProps = {
  textColor: string;
  text: string;
  isUnderlined: boolean;
};

const ListItem = ({ textColor, text, isUnderlined }: ListItemProps) => {
  return (
    <Typography
      variant="body1"
      sx={{
        color: textColor,
        margin: 0,
        fontSize: "16px",
        textDecoration: isUnderlined ? "underline" : "none",
        "&:hover": {
          textDecoration: "none",
          fontWeight: "bold",
        },
      }}
    >
      {text}
    </Typography>
  );
};

const MainLayoutFooter = () => {
  return (
    <Stack
      style={{
        padding: "20px",
        backgroundColor: "black",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        style={{
          width: "100vw",
          padding: 20,
        }}
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          xs={2.5}
          style={{}}
        >
          <Grid item style={{ lineHeight: "30px" }}>
            <ListItem
              text={"CONTACT US"}
              textColor="white"
              isUnderlined={true}
            />
          </Grid>
          <Grid item style={{ lineHeight: "30px" }}>
            <ListItem
              text={"Family Tree & Me"}
              textColor="#c0b283"
              isUnderlined={false}
            />
          </Grid>
          <Grid item style={{ lineHeight: "30px" }}>
            <Link href="tel:8014005802">
              <ListItem
                text={"801-400-5802"}
                textColor="#c0b283"
                isUnderlined={false}
              />
            </Link>
          </Grid>
          <Grid item style={{ lineHeight: "45px" }}>
            <Link href="mailto:shirlenedymock@familytreeandme.com">
              <ListItem
                text={"shirlenedymock@familytreeandme.com"}
                textColor="#c0b283"
                isUnderlined={false}
              />
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          xs={2}
        >
          <Grid item style={{ margin: 0 }}>
            <Typography
              variant="body1"
              style={{
                color: "white",
                textDecoration: "underline",
                fontSize: "16px",
              }}
            >
              SHOP BY CATEGORY
            </Typography>
          </Grid>
          {shopArray.map((entry, index) => (
            <Grid key={`shop-${index}`} item style={{ lineHeight: "30px" }}>
              <StyledLink entry={entry} />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          xs={2}
          style={{}}
        >
          <Grid item style={{ margin: 0 }}>
            <Typography
              variant="body1"
              style={{
                color: "white",
                textDecoration: "underline",
                fontSize: "16px",
              }}
            >
              INFORMATION
            </Typography>
          </Grid>
          {informationArray.map((entry, index) => (
            <Grid key={`info-${index}`} item style={{ lineHeight: "30px" }}>
              <StyledLink entry={entry} />
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={0}
          xs={2}
          style={{}}
        >
          <Grid item style={{ margin: 0 }}>
            <Typography
              variant="body1"
              style={{
                color: "white",
                textDecoration: "underline",
                fontSize: "16px",
              }}
            >
              PAYMENT METHODS
            </Typography>
          </Grid>
          <Grid item>
            <img
              src="/images/paypal_logo.jpg"
              alt="visa"
              style={{ width: "262px", height: "131px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box
        style={{
          backgroundColor: "black",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <Stack direction="column">
          <Stack direction="row" spacing={2}>
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontSize: "12px",
                textAlign: "center",
                lineHeight: "20px",
              }}
            >
              <CustomLink href="/">Home</CustomLink>
              &emsp;|&emsp;
              <CustomLink href="/Purchase">Purchase</CustomLink>
              &emsp;|&emsp;
              <CustomLink href="/photo-family-trees">
                Photo Family Trees
              </CustomLink>
              &emsp;|&emsp;
              {/* <CustomLink href="/family-proclamations">
                Family Proclamations
              </CustomLink>
              &emsp;|&emsp; */}
              <CustomLink href="/missionary-photo-art">
                Missionary Photo Art
              </CustomLink>
              &emsp;|&emsp;
              <CustomLink href="/generational-art">Generational Art</CustomLink>
              &emsp;|&emsp;
              <CustomLink href="/contact-us">Contact Us</CustomLink>
              {/* &emsp;|&emsp;
              <CustomLink href="/site-map">Site Map</CustomLink> */}
            </Typography>
          </Stack>
          <br />
          <Typography
            variant="body1"
            style={{
              color: "white",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "20px",
            }}
          >
            Copyright © 2007-2024 - Family Tree & Me
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "white",
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "20px",
            }}
          >
            Website design by RCD
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export default MainLayoutFooter;
