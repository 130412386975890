import React from "react";
import { Grid } from "@mui/material";
import ProductPageCardWithMotion from "../../components/ProductPageCardWithMotion";

const ProductsPage = () => {
  return (
    <Grid
      container
      direction="row"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: "transparent",
        padding: 10,
      }}
    >
      {[
        {
          title: "Photo Family Trees",
          description:
            "Art designed to capture one's pedigree with head-and-shoulder photo shots of each family member.",
          link: "/shop/Photo-Family-Trees",
          imgSrc: "images/grove.jpg",
        },
        // {
        //   title: "Family Proclamations",
        //   description:
        //     "Art featuring the document: The Family A Proclamation to the World with family photos around the perimeter.",
        //   link: "/shop/Family-Proclamation",
        //   imgSrc: "images/bott.jpg",
        // },
        {
          title: "Missionary Photo Art",
          description:
            "Art highlighting the people, experiences, and memories from one's mission.",
          link: "/shop/Missionary-Photo-Art",
          imgSrc: "images/mckinnon.jpg",
        },
        {
          title: "Generational Art",
          description:
            "Art that displays one's ancestral line in a Father-to-Son or Mother-to-Daughter photo format.",
          link: "/shop/Generational-Art",
          imgSrc: "images/generation.jpg",
        },
        {
          title: "Gift Certificates",
          description:
            "Art that displays one's ancestral line in a Father-to-Son or Mother-to-Daughter photo format.",
          link: "/shop/Gift-Certificates",
          imgSrc: "images/gift_certicatecustom_layouts.jpg",
        },
      ].map((card, index) => (
        <ProductPageCardWithMotion
          key={`Product-Card-${index}`}
          index={index}
          card={card}
        />
      ))}
    </Grid>
  );
};

export default ProductsPage;
