// src/pages/InDevelopment/InDevelopmentPage.js

import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { motion } from "framer-motion";
import ConstructionIcon from "@mui/icons-material/Construction";

const InDevelopmentPage = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: "center", mt: 8 }}>
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 50, damping: 10 }}
      >
        <ConstructionIcon
          sx={{ fontSize: 100, color: "primary.main", mb: 2 }}
        />
      </motion.div>
      <Typography variant="h3" gutterBottom>
        Page Under Construction
      </Typography>
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        <Typography variant="body1">
          We are working hard to bring this page to life. Please check back
          soon!
        </Typography>
      </motion.div>
      <motion.div
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        transition={{ type: "spring", stiffness: 100, delay: 0.5 }}
      >
        <Box
          sx={{
            mt: 5,
            mb: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <ConstructionIcon sx={{ fontSize: 50, color: "primary.main" }} />
          <ConstructionIcon sx={{ fontSize: 50, color: "primary.main" }} />
          <ConstructionIcon sx={{ fontSize: 50, color: "primary.main" }} />
        </Box>
      </motion.div>
    </Container>
  );
};

export default InDevelopmentPage;
