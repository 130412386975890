import React from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";

const PhotoEnhancementsPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageBlocks = [
    {
      text: "WE ELIMINATE THE BACKGROUND",
      altText: "Example of background removal",
      images: [
        "/images/photoFamilyTrees/enhancements/2.jpg",
        "/images/photoFamilyTrees/enhancements/3.jpg",
        "/images/photoFamilyTrees/enhancements/4.jpg",
        "/images/photoFamilyTrees/enhancements/5.jpg",
      ],
    },
    {
      text: "WE ENHANCE IMAGE QUALITY",
      altText: "Example of image quality enhancement",
      images: [
        "/images/photoFamilyTrees/enhancements/6.jpg",
        "/images/photoFamilyTrees/enhancements/7.jpg",
        "/images/photoFamilyTrees/enhancements/8.jpg",
        "/images/photoFamilyTrees/enhancements/9.jpg",
      ],
    },
    {
      text: "WE REMOVE OTHER PEOPLE",
      altText: "Example of removing other people",
      images: [
        "/images/photoFamilyTrees/enhancements/10.jpg",
        "/images/photoFamilyTrees/enhancements/11.jpg",
        "/images/photoFamilyTrees/enhancements/12.jpg",
        "/images/photoFamilyTrees/enhancements/13.jpg",
      ],
    },
    {
      text: "WE COMPLETE THE PHOTO",
      altText: "Example of photo completion",
      images: [
        "/images/photoFamilyTrees/enhancements/14.jpg",
        "/images/photoFamilyTrees/enhancements/15.jpg",
        "/images/photoFamilyTrees/enhancements/16.jpg",
        "/images/photoFamilyTrees/enhancements/17.jpg",
      ],
    },
    {
      text: "WE USE A SILHOUETTE - if no photo is available",
      altText: "Example of silhouette usage",
      images: [
        "/images/photoFamilyTrees/enhancements/male-silho.jpg",
        "/images/photoFamilyTrees/enhancements/female-silho.jpg",
      ],
    },
  ];

  return (
    <Box maxWidth="1100px" margin="0 auto" padding="16px">
      <Typography variant="h4" component="h1" gutterBottom textAlign={"center"}>
        Photo Enhancements - Before & After Examples
      </Typography>
      <Typography variant="body1" paragraph>
        IDEALLY, every client will submit sharp, high-resolution, quality photos
        for each of the family members in their family tree. This rarely
        happens! That's why we rely on PHOTO ENHANCEMENTS.
      </Typography>
      <Typography variant="body1" paragraph>
        Our signature style, the <strong>"STUDIO PORTRAIT LOOK"</strong>{" "}
        involves extracting the head-and-shoulder image for each person out of
        their original background and placing them on a background similar to
        all the other photos. We then individually address lighting issues,
        blemishes, scratches, dust, missing details, other people overlapping
        them, poor quality, etc.{" "}
        <strong>We artistically try to make each picture look its best.</strong>
      </Typography>
      <Typography variant="subtitle2" paragraph>
        DISCLAIMER: Although we do our best, we cannot create miracles. If you
        provide really BAD or really SMALL pictures, we are limited in what we
        can do with it.
      </Typography>
      <Typography variant="body1" paragraph>
        When clients look at our Photo Family Trees, they say,{" "}
        <em>"I don't have photos like these!"</em> Our response back is,{" "}
        <em>
          "Nobody does! We specialize in making your photos look this good!"
        </em>
      </Typography>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} sm={6}>
          <img
            src={"/images/photoFamilyTrees/enhancements/1.jpg"}
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      {imageBlocks.map((block, index) => (
        <Box key={index} mb={4}>
          <Typography
            variant="h5"
            textAlign={"center"}
            gutterBottom
            sx={{ margin: "auto", mt: 4, color: "red", fontWeight: "800" }}
          >
            {block.text}
          </Typography>
          <Grid container spacing={2}>
            {block.images.map((image, index) => (
              <Grid item xs={12} sm={6}>
                <img src={image} style={{ width: "100%" }} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default PhotoEnhancementsPage;
