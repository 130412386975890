import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ProductPageCardWithMotion from "../../components/ProductPageCardWithMotion";
import { Product } from "../../components/ProductPageCard";

function PhotoFamilyTreesPage() {
  const products: Product[] = [
    {
      imgSrc: "/images/shop/basic.jpg",
      title: "Basic Layout",
      description: "Photo Family Tree",
      link: "/shop/Photo-Family-Trees/basic-layout",
      price: "$48+",
    },
    {
      imgSrc: "/images/shop/custom.jpg",
      title: "CUSTOM LAYOUT",
      description: " Photo Family Tree",
      price: "$60+",
      link: "/shop/Photo-Family-Trees/custom-layout",
    },
    {
      imgSrc: "/images/shop/gift_basic.jpg",
      title: "GIFT CERTIFICATE",
      description: "Photo Family Tree BASIC LAYOUT",
      price: "$48+",
      link: "/shop/Photo-Family-Trees/gift-certificate-basic",
    },
    {
      imgSrc: "/images/shop/gift_custom.jpg",
      title: "GIFT CERTIFICATE",
      description: "Photo Family Tree CUSTOM LAYOUT",
      price: "$60+",
      link: "/shop/Photo-Family-Trees/gift-certificate-custom",
    },
    {
      imgSrc: "/images/shop/reprint.jpg",
      title: "REPRINTS",
      description: "REPRINTS of your completed artwork",
      price: "$25+",
      link: "/shop/Photo-Family-Trees/reprints",
    },
    // {
    //   imgSrc: "/images/shop/sltemple.jpg",
    //   title: "LDS Temple",
    //   description: "LDS Temple in the Background",
    //   price: "$15.00",
    //   link: "/shop/Photo-Family-Trees/-Addon",
    // },
    // {
    //   imgSrc: "/images/shop/perimeter.jpg",
    //   title: "Perimeter Add-on",
    //   description: "Add a perimeter to your tree",
    //   price: "$40.00",
    //   link: "/shop/Photo-Family-Trees/Perimeter-Addon",
    // },
    {
      imgSrc: "/images/shop/addons.jpg",
      title: "Add-ons",
      description: "Unexpected Add-ons",
      price: "$5+",
      link: "/shop/Photo-Family-Trees/Unexpected-Addons",
    },
  ];

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom sx={{ mt: 4 }}>
        Photo Family Trees
      </Typography>

      <Grid container justifyContent="center" spacing={4} sx={{ mb: 4 }}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ProductPageCardWithMotion index={index} card={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default PhotoFamilyTreesPage;
