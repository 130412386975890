import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Divider,
} from "@mui/material";

const ScanningGuidelines: React.FC = () => {
  return (
    <Box sx={{ maxWidth: 900, mx: "auto", p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Scanning Guidelines
      </Typography>

      <Typography variant="body1" paragraph>
        Since we work exclusively with digital photos, any physical photos must
        first be scanned into your computer and saved as JPEG files. Please
        follow these guidelines to ensure the best results:
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        1. Scan at a Minimum of 300 DPI
      </Typography>
      <Typography variant="body1" paragraph>
        Printed images consist of tiny dots, and DPI (dots-per-inch) measures
        the resolution, sharpness, and clarity of your scanned photos. Scanning
        at a minimum of 300 DPI is necessary for high-quality printing.
        <ul>
          <li>
            <strong>
              If the photo is the same size or larger than the final print size:
            </strong>{" "}
            300 DPI is sufficient.
          </li>
          <li>
            <strong>If the photo needs to be enlarged:</strong> Increase the DPI
            to 600 or adjust the scale tool on your scanner to 150%, 200%, or
            300%. You may need to access advanced scanner settings to do this.
          </li>
        </ul>
      </Typography>
      {/* Include a DPI comparison image */}
      <Card sx={{ margin: "auto", mb: 2, maxWidth: "500px" }}>
        <CardMedia
          component="img"
          image="/images/guidelines/resolution.jpeg" // Replace with actual image path
          alt="300 DPI vs. 600 DPI"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            DPI comparison
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        2. Don’t Judge Photo Quality by Your Computer Monitor
      </Typography>
      <Typography variant="body1" paragraph>
        Images displayed on computer monitors or websites are typically 72 DPI,
        which is not sufficient for printing. Even if a photo looks good on your
        screen, it may print blurry or pixelated if not scanned at 300 DPI.
        <strong>Reminder:</strong> Always scan photos at 300 DPI or higher to
        ensure print quality.
      </Typography>
      {/* Include a comparison of screen vs. print */}
      <Card sx={{ mb: 2 }}>
        <CardMedia
          component="img"
          image="/path-to-monitor-vs-print.jpg" // Replace with actual image path
          alt="72 DPI on screen vs. 300 DPI print"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            72 DPI on screen vs. Printed at 300 DPI
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        3. Clean Your Scanner Before Scanning
      </Typography>
      <Typography variant="body1" paragraph>
        Before scanning, make sure both your photographs and the scanner glass
        are clean. Scanners can easily pick up and magnify fingerprints, dust,
        or lint that may not be visible to the naked eye.
      </Typography>
      {/* Include an image showing a clean scanner glass */}
      <Card sx={{ margin: "auto", mb: 2, maxWidth: "400px" }}>
        <CardMedia
          component="img"
          image="/images/guidelines/cleaning.png" // Replace with actual image path
          alt="Clean scanner glass"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Ensure the scanner glass is clean before use
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        4. Save Your Scanned Photos as JPEGs
      </Typography>
      <Typography variant="body1" paragraph>
        Save each scanned photo as a JPEG file and, if applicable, name it with
        the person's name. For example: "John_Michael_Smith.jpg".
      </Typography>
      {/* Include a screenshot of the file-saving process */}
      <Card sx={{ mb: 2, maxWidth: "300px" }}>
        <CardMedia
          component="img"
          image="/path-to-file-saving.jpg" // Replace with actual image path
          alt="Saving scanned photos as JPEGs"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Saving scanned photos as JPEGs
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        5. Use the "De-Screen" Function for Photocopies or Newspaper Clippings
      </Typography>
      <Typography variant="body1" paragraph>
        If you’re scanning photocopies or newspaper images, use the “de-screen”
        function on your scanner. This will improve the image quality. This
        feature is usually found in the advanced settings.
      </Typography>
      {/* Include an image showing the de-screen function in the scanner software */}
      <Card sx={{ margin: "auto", mb: 2, maxWidth: "500px" }}>
        <CardMedia
          component="img"
          image="/images/guidelines/descreen.png" // Replace with actual image path
          alt="Using the de-screen function"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Using the de-screen function for better image quality
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        6. Consider Local Scanning Services
      </Typography>
      <Typography variant="body1" paragraph>
        If you don’t have a scanner at home, many local businesses and stores
        offer scanning services. Call around and inquire if they can scan at
        higher resolutions or scales.
      </Typography>
      {/* Include a general map image or list of local services */}
      <Card sx={{ mb: 2 }}>
        <CardMedia
          component="img"
          image="/path-to-local-scanning-services.jpg" // Replace with actual image path
          alt="Local scanning services"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Find local scanning services
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ScanningGuidelines;
