import React, { RefObject } from "react";
import { Box, Typography, Button } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  ProductType,
  SelectOption,
  NumberOption,
  BooleanOption,
} from "./productTypes";

type TotalCostSectionProps = {
  dynamicCost: number;
  product: ProductType;
  quantity: number;
  addToCart: (product: ProductType, quantity: number) => void;
  selectedOptions: { [key: string]: any };
  totalCostRef: RefObject<HTMLDivElement>;
};

const TotalCostSection: React.FC<TotalCostSectionProps> = ({
  dynamicCost,
  product,
  quantity,
  addToCart,
  selectedOptions,
  totalCostRef,
}) => {
  // Get the label and value for each option, formatted with labels
  const getOptionSummary = (
    option: SelectOption | NumberOption | BooleanOption
  ) => {
    if (option.type === "select") {
      const selectedValue = option.values.find(
        (v) => v.id === selectedOptions[option.id]
      );
      return selectedValue
        ? `${option.label}: ${selectedValue.label}`
        : `${option.label}: undecided`;
    }
    if (option.type === "number") {
      const selectedValue = selectedOptions[option.id];
      return `${option.label}: ${selectedValue}`;
    }
    if (option.type === "boolean") {
      const selectedValue = selectedOptions[option.id];
      return selectedValue ? `${option.label}: Yes` : `${option.label}: No`;
    }
    return "";
  };

  // Get all selected options in a labeled, comma-separated string
  const selectedOptionsSummary = product.options
    ?.map((option) => getOptionSummary(option))
    .filter((summary) => summary !== "") // Filter out empty strings
    .join(", ");

  return (
    <Box
      ref={totalCostRef}
      sx={{
        // position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#fff",
        padding: 2,
        borderTop: "1px solid #e0e0e0",
        boxShadow: "0 -1px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 999,
      }}
    >
      {/* Total Cost */}
      <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: 1 }}>
        Total: ${dynamicCost.toFixed(2)}
      </Typography>

      {/* Comma-separated list of labeled selected options */}
      <Typography variant="subtitle2" color="textSecondary">
        {selectedOptionsSummary}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => addToCart(product, quantity)}
        startIcon={<ShoppingCartIcon />}
        sx={{ marginTop: 2 }}
      >
        Add To Cart
      </Button>
    </Box>
  );
};

export default TotalCostSection;
