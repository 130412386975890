import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function ExampleGalleryPage() {
  const images = [
    {
      src: "/images/examples/1.jpg",
      caption:
        "3 GENERATIONS: Couple, children, and parents. Combo Traditional/Butterfly Format. Neutral Beige.",
    },
    {
      src: "/images/examples/2.jpg",
      caption:
        "3 GENERATIONS: Couple, children, and parents. Combo Traditional/Butterfly Format. Antique Parchment.",
    },
    {
      src: "/images/examples/3.jpg",
      caption:
        "3 GENERATIONS: Couple, parents, and grandparents. Butterfly Format. Neutral Beige.",
    },
    {
      src: "/images/examples/4.jpg",
      caption:
        "4 GENERATIONS: Couple, children, parents, and grandparents. Butterfly Format. Neutral Beige.",
    },
    {
      src: "/images/examples/5.jpg",
      caption:
        "4 GENERATIONS: Couple, children, parents, and grandparents. Butterfly Format. Burgundy Cloud.",
    },
    {
      src: "/images/examples/6.jpg",
      caption:
        "4 GENERATIONS: Couple, children, parents, and grandparents. Traditional Format. Neutral Beige.",
    },
    {
      src: "/images/examples/7.jpg",
      caption:
        "4 GENERATIONS: Couple, children, parents, and grandparents. Butterfly Format. Neutral Beige.",
    },
    {
      src: "/images/examples/8.jpg",
      caption:
        "4 GENERATIONS: Couple, children, parents, and grandparents. Traditional Format. Burgundy Cloud.",
    },
    {
      src: "/images/examples/9.jpg",
      caption:
        "4 GENERATIONS: Couple, parents, grandparents, and great grandparents. Butterfly Format. Neutral Beige. LDS temple in the background.",
    },
    {
      src: "/images/examples/10.jpg",
      caption:
        "5 GENERATIONS: Couple, children, parents, grandparents, and great grandparents. Butterfly Format. Tawny Parchment.",
    },
    {
      src: "/images/examples/11.jpg",
      caption:
        "4 GENERATIONS: Couple, children, parents, and grandparents. Traditional Format. Grove.",
    },
    {
      src: "/images/examples/12.jpg",
      caption:
        "5 GENERATIONS: Couple, children, parents, grandparents, and great grandparents. Traditional Format. Antique Parchment.",
    },
    {
      src: "/images/examples/13.jpg",
      caption:
        "5 GENERATIONS: Couple, children, parents, grandparents, and great grandparents. Traditional Format. Neutral Beige. LDS temple in the background.",
    },
    {
      src: "/images/examples/14.jpg",
      caption:
        "5 GENERATIONS: Couple, children, parents, grandparents, and great grandparents. Traditional Format. Grove.",
    },
    {
      src: "/images/examples/15.jpg",
      caption:
        "5 GENERATIONS: Couple, parents, grandparents, and great grandparents. Butterfly Format. Neutral Beige. LDS temple in the background.",
    },
  ];

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: "center",
          color: "rgb(234, 174, 93)",
          fontFamily: "Playfair display",
          mt: 4,
          mb: 4,
        }}
      >
        BASIC Family Tree Layout Examples
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
        The following examples display the same family using different layout
        styles & varying background colors.
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              component="img"
              src={image.src}
              alt={image.caption}
              sx={{
                width: "100%",
                display: "block",
                margin: "auto",
              }}
            />
            <Typography
              variant="caption"
              display="block"
              align="center"
              sx={{ mt: 1 }}
            >
              {image.caption}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ExampleGalleryPage;
