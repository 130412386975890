import { Box, Grid, Typography } from "@mui/material";

const FamTreeTextHeroBanner = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100vw",
        height: "106px",
        backgroundColor: "black",
        borderTop: "3px solid #252525", // Top border
        borderBottom: "3px solid #252525", // Bottom border
      }}
    >
      <Grid item>
        <Typography
          style={{
            fontSize: "32px",
            fontWeight: "500",
            color: "rgb(234, 174, 93)",
            fontFamily: "Playfair display",
          }}
        >
          Family Tree and Me
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FamTreeTextHeroBanner;
