import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";

const DashboardTab: React.FC = () => {
  return (
    <Box>
      {/* Quick Actions */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Actions Needed
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Upload photos for your recent order" />
            <Button variant="contained" color="primary">
              Upload
            </Button>
          </ListItem>
          <ListItem>
            <ListItemText primary="Review and approve your custom product design" />
            <Button variant="contained" color="primary">
              Review
            </Button>
          </ListItem>
        </List>
      </Paper>

      {/* Recent Activity */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Recent Activity
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Updated family tree with new members"
              secondary="2 days ago"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Placed an order for Custom Photo Art"
              secondary="1 week ago"
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default DashboardTab;
