import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

type HomePageCardProps = {
  imgSrc: string;
  description: string;
  link: string;
  title: string;
};

export const HomePageCard: React.FC<HomePageCardProps> = ({
  imgSrc,
  description,
  link,
  title,
}) => {
  return (
    <Card sx={{ maxWidth: 300, height: 475 }}>
      <CardActionArea>
        <CardMedia component="img" height="250px" image={imgSrc} alt={title} />
        <CardContent
          style={{ backgroundColor: "#242424", height: "225px", padding: 40 }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            style={{ color: "#eaae5d" }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="white">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
