import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ProductPageCardWithMotion from "../../components/ProductPageCardWithMotion";
import { Product } from "../../components/ProductPageCard";

function GiftCertificatePage() {
  const products: Product[] = [
    {
      imgSrc: "/images/shop/photoFamilyTree/basicgift1.jpg",
      title: "Basic Layout Tree",
      description: "Photo Family Tree - Basic Layout",
      price: "$48+",
      link: "/shop/Gift-Certificates/Basic-Family-Photo-Tree",
    },
    {
      imgSrc: "/images/shop/photoFamilyTree/customgift1.jpg",
      title: "Custom Layout Tree",
      description: "Photo Family Tree - Custom Layout",
      link: "/shop/Gift-Certificates/Custom-Family-Photo-Tree",
      price: "$60+",
    },
    {
      imgSrc: "/images/shop/missionary/giftcertificate.jpg",
      title: "GIFT CERTIFICATE",
      description: "Gift Certificate",
      price: "$120+",
      link: "/shop/Gift-Certificates/Missionary-Photo-Art",
    },
  ];

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom sx={{ mt: 4 }}>
        Gift Certificates
      </Typography>

      <Grid container justifyContent="center" spacing={4} sx={{ mb: 4 }}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ProductPageCardWithMotion index={index} card={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GiftCertificatePage;
