import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import HomePage from "./pages/Home/HomePage";
import PhotoArtPage from "./pages/PhotoArt/PhotoArtOverviewPage";
import FamilyProclamationsPage from "./pages/FamilyProclamations/FamilyProclamationsPage";
import ContactPage from "./pages/Contact/ContactPage";
import SubpageLayout from "./layouts/SubpageLayout";
import OrderPage from "./pages/Order/OrderPage";
import ProductsPage from "./pages/Products/ProductsPage";
import PhotoFamilyTreesOverviewPage from "./pages/PhotoFamilyTrees/PhotoFamilyTreesOverviewPage";
import InDevelopmentPage from "./pages/InDevelopment/InDevelopmentPage";
import CustomLayoutOptionsPage from "./pages/PhotoFamilyTrees/CustomLayoutOptionsPage";
import ExampleGalleryPage from "./pages/PhotoFamilyTrees/ExampleGalleryPage";
import PhotoFamilyTreesPage from "./pages/shop/PhotoFamilyTreesPage";
import ProductPage from "./pages/shop/ProductPage";
import EcommercePage from "./pages/shop/EcommercePage";
import MissionaryPhotoArtPage from "./pages/shop/MissionaryPhotoArtPage";
import GenerationalArtPage from "./pages/shop/GenerationalArtPage";
import GiftCertificatePage from "./pages/shop/GiftCertificatesPage";
import PhotographGuidelinesPage from "./pages/Guidelines/PhotographGuidelinesPage";
import ScanningGuidelines from "./pages/Guidelines/ScanningGuidelinesPage";
import CustomPhotoFamilyTreeForm from "./pages/Forms/CustomPhotoFamilyTreeForm";
import GenerationalForm from "./pages/Forms/GenerationalForm";
import FamilyNamesForm from "./pages/Forms/FamilyNamesForm";
import MissionaryForm from "./pages/Forms/MissionaryForm";
import PhotoEnhancementsPage from "./pages/PhotoFamilyTrees/PhotoEnhancementsPage";
import BackgroudColorsPage from "./pages/PhotoFamilyTrees/BackgroundColorsPage";
import LdsTempleBackgroundsPage from "./pages/PhotoFamilyTrees/LdsTempleBackgroundPage";
import GenerationalArtOverviewPage from "./pages/GenerationalArt/GenerationalArtOverviewPage";
import PoliciesPage from "./pages/PoliciesPage";
import PriceListPage from "./pages/PriceListPage";
import SignIn from "./pages/Account/SignIn";
import SignUpPage from "./pages/Account/SignUp";
import AccountPage from "./pages/Account/AccountPage";
import CheckoutPage from "./pages/Checkout/CheckoutPage";
import PaymentConfirmationPage from "./pages/Checkout/PaymentConfirmationPage";
import PaymentSuccess from "./pages/Checkout/PaymentSuccess";
import ShoppingCartPage from "./pages/Checkout/ShoppingCartPage";
import { FormTypes } from "./pages/shop/productTypes";
import { useAuth } from "./firebase";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const mainLayoutRoutes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "/ordering",
    component: <OrderPage />,
  },
  {
    path: "/family-proclamations",
    component: <FamilyProclamationsPage />,
  },
  {
    path: "/sign-in",
    component: <SignIn />,
  },
  {
    path: "/sign-up",
    component: <SignUpPage />,
  },
];

const subPageLayoutRoutes = [
  {
    path: "/contact-us",
    component: <ContactPage />,
  },
  {
    path: "/missionary-photo-art",
    component: <PhotoArtPage />,
  },
  {
    path: "/ordering",
    component: <OrderPage />,
  },
  {
    path: "/purchase",
    component: <ProductsPage />,
  },
  {
    path: "/photo-family-trees",
    component: <PhotoFamilyTreesOverviewPage />,
  },
  {
    path: "/404",
    component: <InDevelopmentPage />,
  },
  {
    path: "/custom-layout-options",
    component: <CustomLayoutOptionsPage />,
  },
  {
    path: "/family-tree-example-gallery",
    component: <ExampleGalleryPage />,
  },
  {
    path: "/shop/Photo-Family-Trees",
    component: <PhotoFamilyTreesPage />,
  },
  {
    path: "/shop/Missionary-Photo-Art",
    component: <MissionaryPhotoArtPage />,
  },
  {
    path: "/shop/Generational-Art",
    component: <GenerationalArtPage />,
  },
  {
    path: "/shop/Gift-Certificates",
    component: <GiftCertificatePage />,
  },
  {
    path: "/shop/:categoryId/:productId",
    component: <EcommercePage />,
  },
  {
    path: "/photograph-guidelines",
    component: <PhotographGuidelinesPage />,
  },
  {
    path: "/scanning-guidelines",
    component: <ScanningGuidelines />,
  },
  {
    path: "/photo-enhancements",
    component: <PhotoEnhancementsPage />,
  },
  {
    path: "/background-colors-for-family-trees",
    component: <BackgroudColorsPage />,
  },
  {
    path: "/family-trees-with-lds-temple-backgrounds",
    component: <LdsTempleBackgroundsPage />,
  },
  {
    path: "/generational-art",
    component: <GenerationalArtOverviewPage />,
  },
  {
    path: "/policies",
    component: <PoliciesPage />,
  },
  {
    path: "/price-list",
    component: <PriceListPage />,
  },

  {
    path: "/account",
    component: <AccountPage />,
  },
  {
    path: "/checkout",
    component: <CheckoutPage />,
  },
  {
    path: "/payment-confirmation",
    component: <PaymentConfirmationPage />,
  },
  {
    path: "/payment-success",
    component: <PaymentSuccess />,
  },
  {
    path: "/cart",
    component: <ShoppingCartPage />,
  },
];

const formRoutes = [
  {
    path: "/forms/customizing-your-family-tree",
    component: <CustomPhotoFamilyTreeForm />,
  },
  {
    path: "/forms/generational-names",
    component: <GenerationalForm />,
  },
  {
    path: "/forms/family-names-and-birthdates",
    component: <FamilyNamesForm />,
  },
  {
    path: "/forms/missionary-form",
    component: <MissionaryForm />,
  },
];

export const getFormPath = (formType: FormTypes): string => {
  switch (formType) {
    case "Missionary":
      return "/forms/missionary-form";
    case "Generational":
      return "/forms/generational-names";
    case "FamilyNames":
      return "/forms/family-names-and-birthdates";
  }
};

// const routesToDo = [
//   "/shop/Misc/PURCHASES-made-through-RETAIL-STORES",
// ];

const mainRoutesObjects = mainLayoutRoutes.map((route) => ({
  path: route.path,
  component: route.component,
  layout: MainLayout,
}));

const formRouteObjects = formRoutes.map((route) => ({
  path: route.path,
  component: route.component,
  layout: SubpageLayout,
}));

const subRoutesObjects = subPageLayoutRoutes.map((route) => ({
  path: route.path,
  component: route.component,
  layout: SubpageLayout,
}));

// const inDevelopmentObjects = routesToDo.map((route) => ({
//   path: route,
//   component: <InDevelopmentPage />,
//   layout: SubpageLayout,
// }));

const allRoutes = [
  ...mainRoutesObjects,
  ...subRoutesObjects,
  ...formRouteObjects,
  // ...inDevelopmentObjects,
];

const AppRouter = () => {
  const authContext = useAuth();
  const [isWhitelisted, setIsWhitelisted] = useState<boolean | null>(null);
  const db = getFirestore();

  useEffect(() => {
    const checkWhitelist = async () => {
      if (authContext?.user?.email) {
        const docRef = doc(db, "whitelist", authContext.user.email);
        const docSnap = await getDoc(docRef);
        setIsWhitelisted(docSnap.exists());
      } else {
        setIsWhitelisted(false);
      }
    };
    checkWhitelist();
  }, [authContext, db]);

  if (!authContext) {
    return <div>Loading...</div>;
  }

  const { user } = authContext;

  if (!user) {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<SignIn />} />
        </Routes>
      </Router>
    );
  }

  if (isWhitelisted === null) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  if (!isWhitelisted) {
    return <div>Access Denied</div>; // Or redirect to an unauthorized page
  }

  return (
    <Router>
      <Routes>
        {allRoutes.map((route, index) => {
          const Layout = route.layout || React.Fragment;
          return (
            <Route
              key={index}
              path={route.path}
              element={<Layout>{route.component}</Layout>}
            />
          );
        })}
      </Routes>
    </Router>
  );
};

export default AppRouter;
