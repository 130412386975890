import React, { useState } from "react";
import { Box, Typography, Grid, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const temples = [
  {
    src: "/images/photoFamilyTrees/temple/1.jpg",
    alt: " Bountiful Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/bountiful1.jpg",
      "/images/photoFamilyTrees/temple/bountiful2.jpg",
      "/images/photoFamilyTrees/temple/bountiful3.jpg",
      "/images/photoFamilyTrees/temple/bountiful4.jpg",
      "/images/photoFamilyTrees/temple/bountiful5.jpg",
      "/images/photoFamilyTrees/temple/bountiful6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/2.jpg",
    alt: "Chicago Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/chicago1.jpg",
      "/images/photoFamilyTrees/temple/chicago2.jpg",
      "/images/photoFamilyTrees/temple/chicago3.jpg",
      "/images/photoFamilyTrees/temple/chicago4.jpg",
      "/images/photoFamilyTrees/temple/chicago5.jpg",
      "/images/photoFamilyTrees/temple/chicago6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/3.jpg",
    alt: "Houston Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/houston1.jpg",
      "/images/photoFamilyTrees/temple/houston2.jpg",
      "/images/photoFamilyTrees/temple/houston3.jpg",
      "/images/photoFamilyTrees/temple/houston4.jpg",
      "/images/photoFamilyTrees/temple/houston5.jpg",
      "/images/photoFamilyTrees/temple/houston6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/4.jpg",
    alt: "Idaho Falls Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/idaho1.jpg",
      "/images/photoFamilyTrees/temple/idaho2.jpg",
      "/images/photoFamilyTrees/temple/idaho3.jpg",
      "/images/photoFamilyTrees/temple/idaho4.jpg",
      "/images/photoFamilyTrees/temple/idaho5.jpg",
      "/images/photoFamilyTrees/temple/idaho6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/5.jpg",
    alt: "Jordan River Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/jordan1.jpg",
      "/images/photoFamilyTrees/temple/jordan2.jpg",
      "/images/photoFamilyTrees/temple/jordan3.jpg",
      "/images/photoFamilyTrees/temple/jordan4.jpg",
      "/images/photoFamilyTrees/temple/jordan5.jpg",
      "/images/photoFamilyTrees/temple/jordan6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/6.jpg",
    alt: "Logan Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/logan1.jpg",
      "/images/photoFamilyTrees/temple/logan2.jpg",
      "/images/photoFamilyTrees/temple/logan3.jpg",
      "/images/photoFamilyTrees/temple/logan4.jpg",
      "/images/photoFamilyTrees/temple/logan5.jpg",
      "/images/photoFamilyTrees/temple/logan6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/7.jpg",
    alt: "Manti Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/manti1.jpg",
      "/images/photoFamilyTrees/temple/manti2.jpg",
      "/images/photoFamilyTrees/temple/manti3.jpg",
      "/images/photoFamilyTrees/temple/manti4.jpg",
      "/images/photoFamilyTrees/temple/manti5.jpg",
      "/images/photoFamilyTrees/temple/manti6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/8.jpg",
    alt: "St George Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/stgeorge1.jpg",
      "/images/photoFamilyTrees/temple/stgeorge2.jpg",
      "/images/photoFamilyTrees/temple/stgeorge3.jpg",
      "/images/photoFamilyTrees/temple/stgeorge4.jpg",
      "/images/photoFamilyTrees/temple/stgeorge5.jpg",
      "/images/photoFamilyTrees/temple/stgeorge6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/9.jpg",
    alt: "Washington DC Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/dc1.jpg",
      "/images/photoFamilyTrees/temple/dc2.jpg",
      "/images/photoFamilyTrees/temple/dc3.jpg",
      "/images/photoFamilyTrees/temple/dc4.jpg",
      "/images/photoFamilyTrees/temple/dc5.jpg",
      "/images/photoFamilyTrees/temple/dc6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/10.jpg",
    alt: "Salt Lake Temple",
    relatedImages: [
      "/images/photoFamilyTrees/temple/sl1.jpg",
      "/images/photoFamilyTrees/temple/sl2.jpg",
      "/images/photoFamilyTrees/temple/sl3.jpg",
      "/images/photoFamilyTrees/temple/sl4.jpg",
      "/images/photoFamilyTrees/temple/sl5.jpg",
      "/images/photoFamilyTrees/temple/sl6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/11.jpg",
    alt: "SL Temple Autumn",
    relatedImages: [
      "/images/photoFamilyTrees/temple/autumn1.jpg",
      "/images/photoFamilyTrees/temple/autumn2.jpg",
      "/images/photoFamilyTrees/temple/autumn3.jpg",
      "/images/photoFamilyTrees/temple/autumn4.jpg",
      "/images/photoFamilyTrees/temple/autumn5.jpg",
      "/images/photoFamilyTrees/temple/autumn6.jpg",
    ],
  },
  {
    src: "/images/photoFamilyTrees/temple/12.jpg",
    alt: "SL Temple Perspective",
    relatedImages: [
      "/images/photoFamilyTrees/temple/perspective1.jpg",
      "/images/photoFamilyTrees/temple/perspective2.jpg",
      "/images/photoFamilyTrees/temple/perspective3.jpg",
      "/images/photoFamilyTrees/temple/perspective4.jpg",
      "/images/photoFamilyTrees/temple/perspective5.jpg",
      "/images/photoFamilyTrees/temple/perspective6.jpg",
    ],
  },
];

const LdsTempleBackgroundsPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState<string[]>([]);

  const handleOpen = (images: string[]) => {
    setCurrentImages(images);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImages([]);
  };

  return (
    <Box maxWidth="900px" margin="0 auto" padding="16px">
      <Typography variant="h4" component="h1" textAlign={"center"} gutterBottom>
        LDS Temple Backgrounds
      </Typography>
      <Typography variant="body1" paragraph>
        For a special touch when ordering a Photo Family Tree, one may choose
        the art option of a line-drawn LDS temple to be placed in the
        background.
      </Typography>
      <Typography variant="body1" paragraph>
        This added feature reminds us that families are forever because of the
        temples and the work performed there.
      </Typography>
      <Typography variant="body1" paragraph>
        We are privileged to offer the copyrighted artwork of{" "}
        <strong>Chad Hawkins</strong>, a renowned LDS artist whose specialty
        includes the detailed drawings of temples throughout the world.
      </Typography>
      <Typography variant="body1" paragraph>
        There is a $15 artist's royalty fee for the addition of a temple
        background.
      </Typography>
      <Typography variant="body1" paragraph>
        Listed below is a sampling of temples that we offer as backgrounds.
        Click on the image to see Family Tree examples using that specific
        temple.
      </Typography>
      <Grid container spacing={2}>
        {temples.map((temple, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <img
              src={temple.src}
              alt={temple.alt}
              style={{ width: "100%", cursor: "pointer" }}
              onClick={() => handleOpen(temple.relatedImages)}
            />
          </Grid>
        ))}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90vw", // Set a maximum width for the box
            maxHeight: "90vh", // Ensure the modal doesn't exceed 90% of viewport height
            width: "auto", // Let the width be determined by the content
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflowY: "auto", // Allow scrolling if content overflows
            display: "inline-block", // Shrinks the box to fit the content
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Carousel
            showThumbs={true}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={true}
            useKeyboardArrows={true}
            dynamicHeight={false}
            thumbWidth={80} // Adjust thumbnail width if needed
          >
            {currentImages.map((image, idx) => (
              <div key={idx}>
                <img
                  src={image}
                  alt={`Slide ${idx + 1}`}
                  style={{
                    maxHeight: "70vh", // Reduce height to account for thumbnails
                    width: "100%",
                    objectFit: "contain", // Use 'contain' to fit the entire image within the bounds
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Box>
      </Modal>
    </Box>
  );
};

export default LdsTempleBackgroundsPage;
