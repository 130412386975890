import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Button,
} from "@mui/material";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { productData } from "./productData";
import { ProductType } from "./productTypes";
import { useShoppingCart } from "../Checkout/ShoppingCartProvider";
import ProductImageSection from "./ProductImageSection";
import ProductOptionsSection from "./ProductOptionsSection";
import TotalCostSection from "./TotalCostSection"; // Fixed at bottom
import OrderSummaryTable from "./OrderSummaryTable"; // Table under product image
import { useSnackbar } from "notistack";

type ProductParams = {
  categoryId: string;
  productId: string;
};

const EcommercePage: React.FC = () => {
  const { categoryId, productId } = useParams<ProductParams>();
  const [quantity, setQuantity] = useState<number>(1); // Default quantity
  const [dynamicCost, setDynamicCost] = useState<number>(0);
  const [product, setProduct] = useState<ProductType | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: any;
  }>({});
  const totalCostRef = useRef<HTMLDivElement | null>(null);

  const { addToCart } = useShoppingCart();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      categoryId &&
      productId &&
      productData[categoryId] &&
      productData[categoryId][productId]
    ) {
      const selectedProduct = productData[categoryId][productId];
      setProduct(selectedProduct);
      setDynamicCost(selectedProduct.price);

      const initialOptions: { [key: string]: any } = {};
      selectedProduct.options?.forEach((option) => {
        initialOptions[option.id] = option.default;
      });
      setSelectedOptions(initialOptions);
    }
  }, [categoryId, productId]);

  if (!product) {
    return <Typography>Product not found</Typography>;
  }

  const handleOptionChange = (optionId: string, value: any) => {
    const updatedOptions = { ...selectedOptions, [optionId]: value };
    setSelectedOptions(updatedOptions);

    let newCost = product.price;
    product.options?.forEach((option) => {
      const selectedOption = updatedOptions[option.id];
      if (option.type === "select") {
        const selectedValue = option.values.find(
          (v) => v.id === selectedOption
        );
        if (selectedValue) {
          newCost += selectedValue.cost;
        }
      } else if (option.type === "number") {
        const extraValue = selectedOption - option.min;
        if (extraValue > 0) {
          newCost += extraValue * option.cost;
        }
      } else if (option.type === "boolean" && selectedOption) {
        newCost += option.cost;
      }
    });
    setDynamicCost(newCost);
  };

  const handleAddToCart = () => {
    if (!product) return;
    addToCart(product, 1, selectedOptions);
    enqueueSnackbar("1 Product added to cart", {
      variant: "success",
    });
  };

  console.log(`${categoryId} - ${productId}`);

  return (
    <Box sx={{ maxWidth: 1200, margin: "auto", padding: 2 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to={`/shop/${categoryId}`} color="inherit">
          {product.category}
        </Link>
        <Typography color="textPrimary">{product.productName}</Typography>
      </Breadcrumbs>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <ProductImageSection product={product} />
          <OrderSummaryTable
            product={product}
            quantity={quantity}
            selectedOptions={selectedOptions}
          />

          {/* Display description below image on mobile */}
          <Box sx={{ display: { xs: "block", md: "none" }, marginTop: 2 }}>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-line", // For handling \n\n
              }}
            >
              {product.description}
            </Typography>
          </Box>

          <TotalCostSection
            dynamicCost={dynamicCost}
            product={product}
            quantity={quantity}
            selectedOptions={selectedOptions}
            addToCart={handleAddToCart} // Use the handler function to add the product to the cart
            totalCostRef={totalCostRef}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Display description on top right on desktop */}
          <Box
            sx={{ display: { xs: "none", md: "block" }, marginTop: 2, mb: 2 }}
          >
            <Typography
              variant="body1"
              sx={{
                whiteSpace: "pre-line", // For handling \n\n
              }}
            >
              {product.description}
            </Typography>
          </Box>
          <ProductOptionsSection
            product={product}
            selectedOptions={selectedOptions}
            onOptionChange={handleOptionChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EcommercePage;
