import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { AuthContext, db } from "../../firebase"; // Ensure correct import paths
import { useSnackbar } from "notistack";

const AccountSettingsTab: React.FC = () => {
  const { user } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPasswordSection, setShowPasswordSection] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false); // To handle loading state
  const [hasChanges, setHasChanges] = useState<boolean>(false); // Track changes

  useEffect(() => {
    if (!user) return;

    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setName(userData.name || "");
          setEmail(userData.email || "");
          setPhoneNumber(userData.phoneNumber || "");
          setAddress(userData.address || "");
        }

        // Check if the user signed in with Google
        const googleProvider = user.providerData.find(
          (provider: any) => provider.providerId === "google.com"
        );
        if (googleProvider) {
          setShowPasswordSection(false);
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };

    fetchUserData();
  }, [user]);

  const handleSaveChanges = async () => {
    if (!user) return;
    setSaving(true);
    try {
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(
        userDocRef,
        { name, email, phoneNumber, address },
        { merge: true }
      );
      setHasChanges(false); // Reset changes tracker after save
      console.log("Personal information updated successfully");
      enqueueSnackbar("Personal information updated successfully", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error updating personal information: ", error);
      enqueueSnackbar("Error updating personal information", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  };

  // Function to handle password update
  const handleUpdatePassword = async () => {
    if (!user) return;
    if (newPassword !== confirmPassword) {
      console.error("Passwords do not match");
      enqueueSnackbar("Passwords do not match", { variant: "error" });
      return;
    }

    setSaving(true);
    try {
      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(
        user.email!,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);

      // Update the password
      await updatePassword(user, newPassword);
      console.log("Password updated successfully");
      enqueueSnackbar("Password updated successfully", { variant: "success" });
    } catch (error) {
      console.error("Error updating password: ", error);
      enqueueSnackbar("Error updating password", { variant: "error" });
    } finally {
      setSaving(false);
    }
  };

  const handleFieldChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => {
    setter(value);
    setHasChanges(true); // Mark as changed if a field is edited
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Account Settings
      </Typography>

      {/* Personal Information */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Personal Information
        </Typography>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => handleFieldChange(setName, e.target.value)}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => handleFieldChange(setEmail, e.target.value)}
        />
        <TextField
          fullWidth
          label="Shipping Address"
          variant="outlined"
          margin="normal"
          value={address}
          onChange={(e) => handleFieldChange(setAddress, e.target.value)}
        />
        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          margin="normal"
          value={phoneNumber}
          onChange={(e) => handleFieldChange(setPhoneNumber, e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "16px" }}
          onClick={handleSaveChanges}
          disabled={!hasChanges || saving} // Enable only if changes have been made and not saving
        >
          Save Changes
        </Button>
      </Paper>

      {/* Password & Security */}
      {showPasswordSection && (
        <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
          <Typography variant="h6" gutterBottom>
            Password & Security
          </Typography>
          <TextField
            fullWidth
            label="Current Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            fullWidth
            label="New Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            type="password"
            variant="outlined"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "16px" }}
            onClick={handleUpdatePassword}
            disabled={saving}
          >
            Update Password
          </Button>
        </Paper>
      )}

      {/* Notification Preferences */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Notification Preferences
        </Typography>
        <FormControlLabel
          control={<Switch defaultChecked />}
          label="Receive email notifications"
        />
        <FormControlLabel control={<Switch />} label="Receive SMS alerts" />
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop: "16px" }}
          onClick={() => console.log("Save preferences")} // Implement as needed
          disabled={saving}
        >
          Save Preferences
        </Button>
      </Paper>
    </Box>
  );
};

export default AccountSettingsTab;
