import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

const CustomLayoutOptionsPage = () => {
  return (
    <Box sx={{ mt: 4, px: 2, textAlign: "center", maxWidth: 1100, mx: "auto" }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{ color: "rgb(234, 174, 93)", fontFamily: "Playfair display" }}
      >
        Custom Layouts
      </Typography>
      <Typography variant="body1" paragraph>
        With a <strong>CUSTOM DESIGN</strong>, we can create a family tree to
        fit the uniqueness of your family structure. For example:
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Card elevation={0}>
            <CardMedia
              component="img"
              height="350"
              image="/images/photoFamilyTrees/naidu.jpg" // Replace with actual image path
              alt="Grandparent Art"
              sx={{ objectFit: "contain" }}
            />
            <CardContent>
              <Typography variant="body1">
                <strong>CUSTOM LAYOUTS</strong> are priced at $10 per each
                person in the art piece. Reprints are $25 each, unless wider
                than 36". Customs can be designed up to 96" wide.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{ color: "rgb(234, 174, 93)", fontFamily: "Playfair display" }}
      >
        Grandparent Art
      </Typography>
      <Typography variant="body1" paragraph>
        Art that displays your posterity. Choose a caption that is meaningful to
        you!
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card elevation={0}>
            <CardMedia
              component="img"
              height="350"
              image="/images/photoFamilyTrees/grandparent.jpg" // Replace with actual image path
              alt="Grandparent Art"
              sx={{ objectFit: "contain" }}
            />
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        textAlign="center"
        sx={{
          mt: 4,
          color: "rgb(234, 174, 93)",
          fontFamily: "Playfair display",
        }}
      >
        Perimeter Family Tree Art
      </Typography>
      <Typography variant="body1" paragraph>
        A Traditional Family Tree is pictured in the center of this art piece
        with color pictures around the perimeter.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card elevation={0}>
            <CardMedia
              component="img"
              height="350"
              image="/images/photoFamilyTrees/naidu.jpg" // Replace with actual image path
              alt="Grandparent Art"
              sx={{ objectFit: "contain" }}
            />
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          color: "rgb(234, 174, 93)",
          fontFamily: "Playfair display",
          mt: 5,
        }}
      >
        Art displaying Ancestry & Posterity
      </Typography>
      <Typography variant="body1" paragraph sx={{ mt: 4 }}>
        The featured couple is displayed along with their parents, grandparents,
        children with spouses, grandchildren, and sometimes great grandchildren.
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/photoFamilyTrees/ancestry1.jpg"
            alt="Ancestry & Posterity Art"
            sx={{
              width: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/photoFamilyTrees/ancestry2.jpg"
            alt="Ancestry & Posterity Art"
            sx={{
              width: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/photoFamilyTrees/ancestry3.jpg"
            alt="Ancestry & Posterity Art"
            sx={{
              width: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src="/images/photoFamilyTrees/ancestry4.jpg"
            alt="Ancestry & Posterity Art"
            sx={{
              width: "100%",
              display: "block",
              margin: "auto",
            }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          mt: 5,
          color: "rgb(234, 174, 93)",
          fontFamily: "Playfair display",
        }}
      >
        Art Displaying Generations in Family Groupings
      </Typography>
      <Typography variant="body1" paragraph>
        This art features a family, the father's family with siblings, the
        mother's family with siblings, and the grandparents' families with their
        siblings.
      </Typography>
      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardMedia
              component="img"
              image="/images/photoFamilyTrees/generations.jpg" // Replace with actual image path
              alt="Our LONGEST Family Tree"
              sx={{ objectFit: "contain" }}
            />
          </Card>
        </Grid>
      </Grid>{" "}
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          mt: 5,
          color: "rgb(234, 174, 93)",
          fontFamily: "Playfair display",
        }}
      >
        Our LONGEST Family Tree
      </Typography>
      <Typography variant="body1" paragraph>
        Our LONGEST Family Tree: This 24" x 87" art piece features a couple,
        their families with siblings, their fathers' family with siblings, their
        mothers' family with siblings, and their grandparents' families with
        their siblings. This Family Tree won the Sweepstakes Award at the County
        Fair!
      </Typography>
      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardMedia
              component="img"
              image="/images/photoFamilyTrees/longest.jpg" // Replace with actual image path
              alt="Our LONGEST Family Tree"
              sx={{ objectFit: "contain" }}
            />
          </Card>
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          mt: 5,
          color: "rgb(234, 174, 93)",
          fontFamily: "Playfair display",
        }}
      >
        Special Request Art
      </Typography>
      <Typography variant="body1" paragraph sx={{ mt: 2 }}>
        This 24" x 36" custom art piece gives presentation to the client's 4
        years of research identifying his ancestors who fought in the
        Revolutionary and Civil Wars. The color boxes have text that give names,
        dates, which battles, with whom they served. Connecting lines trace the
        lineage.
      </Typography>
      <Grid container justifyContent="center" spacing={4} sx={{}}>
        <Grid item xs={12} md={6}>
          <Card elevation={0}>
            <CardMedia
              component="img"
              height="400"
              image="/images/photoFamilyTrees/specialRequest.jpg" // Replace with actual image path
              alt="Special Request Art"
              sx={{ objectFit: "contain" }}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomLayoutOptionsPage;
