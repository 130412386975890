import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AppRouter from "./AppRouter";
import theme from "./styles/theme";
import { AuthProvider, useAuth } from "./firebase";
import { SnackbarProvider } from "notistack";
import { ShoppingCartProvider } from "./pages/Checkout/ShoppingCartProvider";
import SignIn from "./pages/Account/SignIn";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <ShoppingCartProvider>
          <SnackbarProvider maxSnack={3}>
            <AppRouter />
          </SnackbarProvider>
        </ShoppingCartProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
