import { Box, Grid } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";

const FamTreeLogoHeroBanner = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100vw",
        height: "340px",
        backgroundColor: "#5C0629",
        borderTop: "3px solid #252525", // Top border
        borderBottom: "3px solid #252525", // Bottom border
        opacity: 0.75,
      }}
    >
      <Grid item>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white", opacity: 1 }}
        >
          <img src={`images/logo.jpg`} alt="" />
        </Link>
      </Grid>
    </Grid>
  );
};

export default FamTreeLogoHeroBanner;
