import React from "react";
import { Grid } from "@mui/material";
import ProductPageCard, { Product } from "./ProductPageCard";
import { motion } from "framer-motion";

const ProductPageCardWithMotion = ({
  index,
  card,
}: {
  index: number;
  card: Product;
}) => {
  return (
    <Grid item key={index} style={{ color: "white" }}>
      <motion.div
        whileHover={{
          scale: 1.05,
          transition: { duration: 0.3 },
        }}
        style={{
          overflow: "hidden",
          borderRadius: "8px",
        }}
      >
        <ProductPageCard
          title={card.title}
          description={card.description}
          link={card.link}
          imgSrc={card.imgSrc}
          price={card.price}
        />
      </motion.div>
    </Grid>
  );
};

export default ProductPageCardWithMotion;
