import React, { useState } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  Box,
  Typography,
} from "@mui/material";

const backgroundOptions = [
  {
    label: "Neutral Beige",
    image: "/images/photoFamilyTrees/backgrounds/beige.jpg",
  },
  {
    label: "Burgundy Cloud",
    image: "/images/photoFamilyTrees/backgrounds/burgundy.jpg",
  },
  { label: "Grove", image: "/images/photoFamilyTrees/backgrounds/grove.jpg" },
  {
    label: "Antique Parchment",
    image: "/images/photoFamilyTrees/backgrounds/antiquep.jpg",
  },
  {
    label: "Tawny Parchment",
    image: "/images/photoFamilyTrees/backgrounds/tawny.jpg",
  },
  { label: "undecided" },
];

const formatOptions = ["Traditional", "Butterfly", "undecided"];

const orientationOptions = ["Horizontal", "Vertical", "undecided"];

const dimensionOptions = [
  "16 x 20",
  "20 x 24",
  "20 x 30",
  "24 x 36",
  "Other",
  "undecided",
];

const CustomPhotoFamilyTreeForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    description: "",
    numberOfPeople: "",
    backgroundColor: "Neutral Beige",
    format: "Traditional",
    orientation: "Horizontal",
    dimensions: "16 x 20",
    otherDimensions: "",
    reprints: false,
    notARobot: false,
  });

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    setFormData({
      ...formData,
      [name as string]: value,
    });
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!formData.notARobot) {
      alert("Please confirm you're not a robot.");
      return;
    }
    console.log("Form data submitted:", formData);
    // Submit logic here
  };

  const selectedBackground = backgroundOptions.find(
    (option) => option.label === formData.backgroundColor
  );

  return (
    <Box sx={{ maxWidth: "900px", margin: "auto", mt: 4, mb: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Custom Photo Family Tree Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          required
          name="name"
          label="Your Name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          required
          name="email"
          label="Email address"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          required
          name="description"
          label="Who are you wanting pictured in your Family Tree? children? parents? grandparents? siblings? explain"
          multiline
          rows={4}
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          required
          name="numberOfPeople"
          type="number"
          label="How many people will be displayed?"
          value={formData.numberOfPeople}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <Box display="flex" flexDirection="row" marginBottom={2}>
          <Box flex={1}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">What color background?</FormLabel>
              <RadioGroup
                name="backgroundColor"
                value={formData.backgroundColor}
                onChange={handleChange}
              >
                {backgroundOptions.map((option) => (
                  <FormControlLabel
                    key={option.label}
                    value={option.label}
                    control={<Radio />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            flex={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {selectedBackground && selectedBackground.image && (
              <img
                src={selectedBackground.image}
                alt={formData.backgroundColor}
                style={{ maxWidth: "100%", maxHeight: "250px", margin: "auto" }}
              />
            )}
          </Box>
        </Box>

        <Box marginBottom={2}>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">What format do you prefer?</FormLabel>
            <RadioGroup
              name="format"
              value={formData.format}
              onChange={handleChange}
            >
              {formatOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box marginBottom={2}>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">
              What orientation do you prefer?
            </FormLabel>
            <RadioGroup
              name="orientation"
              value={formData.orientation}
              onChange={handleChange}
            >
              {orientationOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>

        <Box marginBottom={0}>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">
              What dimensions are you wanting for your Family Tree?
            </FormLabel>
            <RadioGroup
              name="dimensions"
              value={formData.dimensions}
              onChange={handleChange}
            >
              {dimensionOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        {formData.dimensions === "Other" && (
          <TextField
            name="otherDimensions"
            label="If you chose 'other', what size do you want?"
            value={formData.otherDimensions}
            onChange={handleChange}
            fullWidth
            margin="dense"
            sx={{ marginBottom: 4, mt: 0 }}
          />
        )}

        <Box marginBottom={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="reprints"
                checked={formData.reprints}
                onChange={handleCheckboxChange}
              />
            }
            label="Will you be ordering reprints for other family members?"
          />
        </Box>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default CustomPhotoFamilyTreeForm;
