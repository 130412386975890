import React from "react";
import { Box, Grid } from "@mui/material";
import { ProductType } from "./productTypes";

type ProductImageSectionProps = {
  product: ProductType;
};

const ProductImageSection: React.FC<ProductImageSectionProps> = ({
  product,
}) => {
  const [selectedImage, setSelectedImage] = React.useState(product.images[0]);

  return (
    <Box>
      <img
        src={selectedImage}
        alt={product.title}
        style={{ width: "100%", borderRadius: "10px" }}
      />
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {product.images.map((image, index) => (
          <Grid item key={index} xs={3}>
            <img
              src={image}
              alt={`Thumbnail ${index + 1}`}
              style={{
                width: "100%",
                borderRadius: "10px",
                border: image === selectedImage ? "2px solid orange" : "none",
                cursor: "pointer",
              }}
              onClick={() => setSelectedImage(image)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductImageSection;
