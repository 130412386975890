import React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Field = {
  name: string;
  label: string;
  dateName?: string;
};

const relations = [
  "Featured husband",
  "Featured wife (maiden name)",
  "Couple's 1st child",
  "Couple's 2nd child",
  "Couple's 3rd child",
  "Couple's 4th child",
  "Couple's 5th child",
  "Couple's 6th child",
  "Husband's father",
  "Husband's mother (maiden name)",
  "Wife's father",
  "Wife's mother (maiden name)",
  "Husband's father's father",
  "Husband's father's mother (maiden name)",
  "Husband's mother's father",
  "Husband's mother's mother (maiden name)",
  "Wife's father's father",
  "Wife's father's mother (maiden name)",
  "Wife's mother's father",
  "Wife's mother's mother (maiden name)",
  "Husband's father's paternal grandfather",
  "Husband's father's paternal grandmother (maiden name)",
  "Husband's father's maternal grandfather",
  "Husband's father's maternal grandmother (maiden name)",
  "Husband's mother's paternal grandfather",
  "Husband's mother's paternal grandmother (maiden name)",
  "Husband's mother's maternal grandfather",
  "Husband's mother's maternal grandmother (maiden name)",
  "Wife's father's paternal grandfather",
  "Wife's father's paternal grandmother (maiden name)",
  "Wife's father's maternal grandfather",
  "Wife's father's maternal grandmother (maiden name)",
  "Wife's mother's paternal grandfather",
  "Wife's mother's paternal grandmother (maiden name)",
  "Wife's mother's maternal grandfather",
  "Wife's mother's maternal grandmother (maiden name)",
];

const fields: Field[] = [
  { name: "yourName", label: "Your Name*" },
  { name: "familyTreeLayout", label: "Family Tree Layout you are ordering*" },
  { name: "instructions", label: "Instructions from you to us*" },
  ...Array.from({ length: relations.length }, (_, i) => ({
    name: `relation_${i}_name`,
    label: `Relation ${i + 1} Name`,
    dateName: `relation_${i}_birthdate`,
  })),
];

const FamilyNamesForm: React.FC = () => {
  const [selectedDates, setSelectedDates] = React.useState<{
    [key: string]: any;
  }>({});
  const [formData, setFormData] = React.useState(() => {
    const initialData: { [key: string]: any } = {};
    fields.forEach((field) => {
      initialData[field.name] = "";
      if (field.dateName) {
        initialData[field.dateName] = null; // Initialize date fields as null
      }
    });
    return initialData;
  });

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (key: string, newValue: any) => {
    setSelectedDates((prev) => ({ ...prev, [key]: newValue }));
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Combine formData with selectedDates into one object
    const dataToSubmit = {
      ...formData,
      ...selectedDates,
    };

    // Here you can do whatever you want with dataToSubmit, like sending it to an API
    console.log("Form Data Submitted:", dataToSubmit);

    // Optionally, you can reset the form after submission
    // setFormData({});
    // setSelectedDates({});
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{ maxWidth: "900px", margin: "auto", mt: 4, mb: 4 }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom>
          Family Tree Names & Birthdates
        </Typography>
        <Typography variant="body1" paragraph>
          Fill out the appropriate fields which correspond with your chosen
          Family Tree layout. Use the misc. fields if we have not provided
          enough fields to include all the members of your family (for example:
          if you have more than 6 children).
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="label">
              Your Name*
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              name="yourName"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="label">
              Family Tree Layout you are ordering*
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              name="familyTreeLayout"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" component="label">
              Instructions from you to us: title, quote, decorative corners,
              custom requests, comments, etc.*
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="instructions"
              variant="outlined"
              required
              onChange={handleInputChange}
            />
          </Grid>

          <Divider sx={{ width: "100%", mt: 4, mb: 4 }} />

          {[
            "Featured husband",
            "Featured wife (maiden name)",
            "Couple's 1st child",
            "Couple's 2nd child",
            "Couple's 3rd child",
            "Couple's 4th child",
            "Couple's 5th child",
            "Couple's 6th child",
            "Husband's father",
            "Husband's mother (maiden name)",
            "Wife's father",
            "Wife's mother (maiden name)",
            "Husband's father's father",
            "Husband's father's mother (maiden name)",
            "Husband's mother's father",
            "Husband's mother's mother (maiden name)",
            "Wife's father's father",
            "Wife's father's mother (maiden name)",
            "Wife's mother's father",
            "Wife's mother's mother (maiden name)",
            "Husband's father's paternal grandfather",
            "Husband's father's paternal grandmother (maiden name)",
            "Husband's father's maternal grandfather",
            "Husband's father's maternal grandmother (maiden name)",
            "Husband's mother's paternal grandfather",
            "Husband's mother's paternal grandmother (maiden name)",
            "Husband's mother's maternal grandfather",
            "Husband's mother's maternal grandmother (maiden name)",
            "Wife's father's paternal grandfather",
            "Wife's father's paternal grandmother (maiden name)",
            "Wife's father's maternal grandfather",
            "Wife's father's maternal grandmother (maiden name)",
            "Wife's mother's paternal grandfather",
            "Wife's mother's paternal grandmother (maiden name)",
            "Wife's mother's maternal grandfather",
            "Wife's mother's maternal grandmother (maiden name)",
          ].map((relation, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="label">
                  {relation} & birthdate
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name={`relation_${index}_name`}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  label="Birthdate"
                  value={selectedDates[`relation_${index}_birthdate`]}
                  onChange={(newValue) =>
                    handleDateChange(`relation_${index}_birthdate`, newValue)
                  }
                />
              </Grid>
            </React.Fragment>
          ))}

          {[0, 1, 2, 3, 4, 5, 6].map((index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1" component="label">
                  misc/you label them/name & birthdate
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name={`misc_${index}_name`}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DatePicker
                  label="Birthdate"
                  value={selectedDates[`misc_${index}_birthdate`]}
                  onChange={(newValue) =>
                    handleDateChange(`misc_${index}_birthdate`, newValue)
                  }
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12}>
            <Button variant="contained" type="submit" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default FamilyNamesForm;
