import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Avatar, CircularProgress, IconButton } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";

interface MissionaryCardProps {
  name: string;
  startDate: string;
  endDate: string;
  notes: string;
  image: string;
  loading: boolean;
  showCompanionFields: boolean;
  onDetailsChange: (field: string, value: any) => void;
  onImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  isEdited: boolean;
}

const MissionaryCard: React.FC<MissionaryCardProps> = ({
  name,
  startDate,
  endDate,
  notes,
  image,
  showCompanionFields,
  loading,
  onDetailsChange,
  onImageChange,
  onSave,
  isEdited,
}) => {
  const handleFieldChange = (name: string, value: string) => {
    onDetailsChange(name, value);
  };

  return (
    <Card elevation={3} sx={{ maxWidth: 800 }}>
      <CardContent>
        <Box sx={{ display: "flex", marginBottom: 2 }}>
          <Box sx={{ marginRight: "16px" }}>
            <Avatar
              src={loading ? undefined : image || "/default-avatar.png"}
              sx={{ width: 240, height: 240 }}
            >
              {loading && <CircularProgress />}
            </Avatar>
            <input
              accept="image/*"
              id={`icon-button-file-${name}`}
              type="file"
              style={{ display: "none" }}
              onChange={onImageChange}
            />
            <label htmlFor={`icon-button-file-${name}`}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                disabled={loading} // Disable button while loading
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Box>
          {showCompanionFields && (
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                margin="dense"
                label="Name"
                type="text"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => handleFieldChange("name", e.target.value)}
                disabled={loading}
              />
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                fullWidth
                variant="outlined"
                value={startDate}
                onChange={(e) => handleFieldChange("startDate", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={loading}
              />
              <TextField
                margin="dense"
                label="End Date"
                type="date"
                fullWidth
                variant="outlined"
                value={endDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleFieldChange("endDate", e.target.value)}
                disabled={loading}
              />
            </Box>
          )}
        </Box>
        <TextField
          margin="dense"
          label="Notes"
          type="text"
          fullWidth
          variant="outlined"
          value={notes}
          onChange={(e) => handleFieldChange("notes", e.target.value)}
          disabled={loading}
        />
      </CardContent>
      {showCompanionFields && (
        <CardActions>
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            disabled={!isEdited || loading}
          >
            Save
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default MissionaryCard;
