import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import FamTreeLogoHeroBanner from "../../components/FamTreeLogoHeroBanner";
import CarouselSliderBanner from "../../components/CarouselSliderBanner";
import { HomePageCard } from "./HomePageCard";
import FamTreeWelcomeBanner from "../../components/FamTreeWelcomeBanner";
import FamTreeButtonBanner from "../../components/FamTreeButtonBanner";
import NewsletterBanner from "../../components/NewsletterBanner";
import PromoBanner from "./PromoBanner";
import MainLayoutFooter from "./Footer";

const FtamHomePage = () => {
  return (
    <div
      className="main-page-wrapper"
      style={{
        backgroundImage:
          "url('https://media.rainpos.com/663/hex5f021f_burgundy_design_swirl_boldtiny09867633333333.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        height: "100%",
      }}
    >
      <FamTreeLogoHeroBanner />
      <CarouselSliderBanner />
      <Grid
        container
        direction="row"
        spacing={4}
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "transparent",
          padding: 10,
          height: "600px",
        }}
      >
        <Grid item style={{ color: "white" }}>
          <HomePageCard
            title="Photo Family Trees"
            description="Art designed to capture one's pedigree with head-and-shoulder photo shots of each family member."
            link="/family-tree-and-me"
            imgSrc="images/grove.jpg"
          />
        </Grid>
        <Grid item style={{ color: "white" }}>
          <HomePageCard
            title="Family Proclamations"
            description="Art featuring the document: The Family A Proclamation to the World with family photos around the perimeter."
            link="/family-tree-and-me"
            imgSrc="images/bott.jpg"
          />
        </Grid>
        <Grid item style={{ color: "white" }}>
          <HomePageCard
            title="Missionary Photo Art"
            description="Art highlighting the people, experiences, and memories from one's mission."
            link="/family-tree-and-me"
            imgSrc="images/mckinnon.jpg"
          />
        </Grid>
        <Grid item style={{ color: "white" }}>
          <HomePageCard
            title="Generational Art"
            description="Art that displays one's ancestral line in a Father-to-Son or Mother-to-Daughter photo format."
            link="/family-tree-and-me"
            imgSrc="images/generation.jpg"
          />
        </Grid>
      </Grid>
      <FamTreeWelcomeBanner />
      <FamTreeButtonBanner />
      <NewsletterBanner />
      <PromoBanner />
    </div>
  );
};

export default FtamHomePage;
