import React from "react";
import {
  Box,
  Typography,
  Link,
  Divider,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

const PhotographGuidelinesPage: React.FC = () => {
  return (
    <Box sx={{ maxWidth: 900, mx: "auto", p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Photograph Submission Guidelines
      </Typography>

      <Typography variant="body1" paragraph>
        To start, ensure that all physical photos are scanned into your computer
        and saved as high-quality JPEG files.{" "}
        <Link href="/scanning-guidelines" underline="hover">
          Click here for detailed scanning guidelines.
        </Link>
      </Typography>
      {/* Add a picture or diagram of scanning a photo */}
      <Card sx={{ margin: "auto", mb: 2, maxWidth: "300px" }}>
        <CardMedia
          component="img"
          image="/images/guidelines/scanning.png"
          alt="Example of scanning a physical photo"
        />
      </Card>

      <Typography variant="body2" color="textSecondary" paragraph>
        Legal Notice: Please remember that copyright laws protect photographs.
        By submitting your photos, you confirm that you have the legal right to
        do so.
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Choosing the Best Photos
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Focus on Quality:</strong> Select clear, sharply focused images
        for the best results.
      </Typography>
      {/* Add an example of high-quality vs low-quality photo */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Card sx={{ width: "48%" }}>
          <CardMedia
            component="img"
            image="/path-to-high-quality-photo.jpg" // Replace with actual image path
            alt="High-quality photo"
          />
          <CardContent>
            <Typography variant="caption" align="center" display="block">
              High-quality photo
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: "48%" }}>
          <CardMedia
            component="img"
            image="/path-to-low-quality-photo.jpg" // Replace with actual image path
            alt="Low-quality photo"
          />
          <CardContent>
            <Typography variant="caption" align="center" display="block">
              Low-quality photo
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Typography variant="body1" paragraph>
        <strong>Photo Size & Clarity:</strong> Opt for photos that are
        well-sized and of better quality. We recommend choosing images where the
        individual is in their prime years.
      </Typography>
      {/* Add an ideal photo example */}
      <Card sx={{ mb: 2 }}>
        <CardMedia
          component="img"
          image="/path-to-ideal-photo.jpg" // Replace with actual image path
          alt="Ideal photo: Clear and well-sized"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Ideal photo: Clear and well-sized
          </Typography>
        </CardContent>
      </Card>

      <Typography variant="body1" paragraph>
        <strong>Silhouette Option:</strong> If a suitable photo isn't available,
        we can use a male or female silhouette in its place.
      </Typography>
      {/* Add silhouette example */}
      <Card sx={{ mb: 2 }}>
        <CardMedia
          component="img"
          image="/path-to-silhouette.jpg" // Replace with actual image path
          alt="Example of a placeholder silhouette"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Example of a placeholder silhouette
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Avoiding Common Pitfalls
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Photocopies & Newspaper Images:</strong> Avoid using photocopies
        or newspaper clippings, as they typically don’t meet the printing
        quality standards. If unavoidable, use the “de-screen” feature when
        scanning.
      </Typography>
      {/* Add example of a poorly scanned newspaper image */}
      <Card sx={{ mb: 2, maxWidth: "300px" }}>
        <CardMedia
          component="img"
          image="/images/guidelines/newspaper.png" // Replace with actual image path
          alt="Example of low-quality newspaper scan"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Example of low-quality newspaper scan
          </Typography>
        </CardContent>
      </Card>

      <Typography variant="body1" paragraph>
        <strong>Scaling Small Faces:</strong> If you have a great photo but the
        face is too small, adjust the scanner’s scale tool to 150%, 200%, or
        even 300%. You can also increase the setting to 600 dpi for better
        detail.
      </Typography>
      {/* Add a diagram of scaling in the scanner software */}
      <Card sx={{ mb: 2 }}>
        <CardMedia
          component="img"
          image="/path-to-scaling-diagram.jpg" // Replace with actual image path
          alt="Using the scale tool to enlarge a small face"
        />
        <CardContent>
          <Typography variant="caption" align="center" display="block">
            Using the scale tool to enlarge a small face
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Color Considerations
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>Ignore Color Differences:</strong> Don’t worry about the colors
        in your photos. We will convert all images to the same tone (Sepia or
        Black-and-White) based on your chosen background.
      </Typography>
      {/* Add before-and-after color conversion example */}
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Card sx={{ width: "48%" }}>
          <CardMedia
            component="img"
            image="/path-to-original-color.jpg" // Replace with actual image path
            alt="Original color"
          />
          <CardContent>
            <Typography variant="caption" align="center" display="block">
              Original color
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: "48%" }}>
          <CardMedia
            component="img"
            image="/path-to-sepia-tone.jpg" // Replace with actual image path
            alt="Sepia tone"
          />
          <CardContent>
            <Typography variant="caption" align="center" display="block">
              Sepia tone
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default PhotographGuidelinesPage;
