import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

// Tab Components
import DashboardTab from "./DashboardTab";
import FamilyTreeTab from "./FamilyTreeTab";
import OrdersTab from "./OrdersTab";
import AccountSettingsTab from "./AccountSettingsTab";
import HelpSupportTab from "./HelpSupportTab";
import GenerationalArtTab from "./GenerationalArtTab";
import MissionaryArtTab from "./Mission/MissionaryArtTab";

const AccountPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoad(false);
    }, 500); // Adjust the duration to match your slide-down animation
    return () => clearTimeout(timer);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", padding: "36px" }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="account tabs"
        sx={{ marginBottom: "24px" }}
      >
        <Tab label="Dashboard" />
        <Tab label="Family Tree Info" />
        <Tab label="Generational Art" />
        <Tab label="Missionary Info" />
        <Tab label="Orders" />
        <Tab label="Account Settings" />
        <Tab label="Help & Support" />
      </Tabs>

      {selectedTab === 0 && <DashboardTab />}
      {selectedTab === 1 && <FamilyTreeTab />}
      {selectedTab === 2 && <GenerationalArtTab />}
      {selectedTab === 3 && <MissionaryArtTab />}
      {selectedTab === 4 && <OrdersTab />}
      {selectedTab === 5 && <AccountSettingsTab />}
      {selectedTab === 6 && <HelpSupportTab />}
    </Box>
  );
};

export default AccountPage;
