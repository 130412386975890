import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useShoppingCart } from "./ShoppingCartProvider"; // Importing the ShoppingCart context
import CheckoutButton from "../../components/CheckoutButton";

const ShoppingCartPage: React.FC = () => {
  const { cart, removeFromCart } = useShoppingCart(); // Accessing the cart and removeFromCart from the context

  // Calculate the total cost
  const totalCost = cart.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );

  // Placeholder for shipping, discounts, etc.
  const shippingCost = 0.0; // Example flat shipping cost
  const discount = 0.0; // Example discount

  const finalTotal = totalCost + shippingCost - discount;

  return (
    <Container maxWidth="lg" sx={{ my: 2 }}>
      <Grid container spacing={4}>
        {/* Left side: Product cards */}
        <Grid item xs={12} md={7}>
          {cart.map((product) => (
            <Card key={product.productId} sx={{ mb: 4 }}>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={product.image}
                    alt={product.name}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      {product.description}
                    </Typography>

                    {/* Safely display customization options */}
                    {product.selectedOptions && (
                      <Typography variant="body2" color="text.secondary">
                        Customizations:{" "}
                        {Object.entries(product.selectedOptions)
                          .map(([key, value]) => `${key}: ${value}`)
                          .join(", ")}
                      </Typography>
                    )}

                    <Typography variant="body1" color="text.primary" mt={2}>
                      Price: ${(product.price * product.quantity).toFixed(2)}
                    </Typography>

                    {/* Remove from cart button */}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => removeFromCart(product.productId)}
                      sx={{ mt: 2 }}
                    >
                      Remove from Cart
                    </Button>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>

        {/* Right side: Table Summary, Order Summary, and Checkout */}
        <Grid item xs={12} md={5}>
          {/* Table Summary */}
          <TableContainer component={Paper} sx={{ mb: 4 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Options</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell align="right">Total Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.map((product) => (
                  <TableRow key={product.productId}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      {/* Safely handle undefined selectedOptions */}
                      {product.selectedOptions
                        ? Object.entries(product.selectedOptions)
                            .map(([key, value]) => `${key}: ${value}`)
                            .join(", ")
                        : "No customizations"}
                    </TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell align="right">
                      ${(product.price * product.quantity).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Order Summary and Checkout */}
          <Box p={2} border={1} borderRadius={1} borderColor="grey.300">
            <Typography variant="h5" gutterBottom>
              Order Summary
            </Typography>
            <Typography variant="body1" gutterBottom>
              Total Cost: ${totalCost.toFixed(2)}
            </Typography>
            <Typography variant="body1" gutterBottom>
              Shipping: ${shippingCost.toFixed(2)}
            </Typography>
            {discount > 0 && (
              <Typography variant="body1" gutterBottom>
                Discount: -${discount.toFixed(2)}
              </Typography>
            )}
            <Typography variant="h6" gutterBottom>
              Total: ${finalTotal.toFixed(2)}
            </Typography>
            <Box mt={4}>
              <CheckoutButton
                amount={finalTotal}
                currency="USD"
                description="Your Order Summary"
                customData={{ items: cart }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ShoppingCartPage;
