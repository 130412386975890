import { Typography, Box } from "@mui/material";
import CompanionList, { CompanionDetails } from "./CompanionList";
import MissionaryCard from "./MissionaryCard";
import { useContext, useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AuthContext, db, storage } from "../../../firebase";
import { Missionary } from "./MissionaryArtTab";
import { useSnackbar } from "notistack";

const urlCache = new Map<string, string>();

type CompanionsInfoPaneProps = {
  companionList: CompanionDetails[];
  onCompanionListUpdate: (newList: CompanionDetails[]) => void;
};

const CompanionsInfoPane = ({
  companionList,
  onCompanionListUpdate,
}: CompanionsInfoPaneProps) => {
  const [companionImageUrl, setCompanionImageUrl] = useState<string>("");
  const [updatedCompanionList, setUpdatedCompanionList] =
    useState<CompanionDetails[]>(companionList);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedCompanion, setSelectedCompanion] =
    useState<CompanionDetails | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (companionList) {
      setUpdatedCompanionList(companionList);
    }
  }, [companionList]);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (!selectedCompanion) return;
      const filePath = selectedCompanion.imageFilePath;

      if (filePath) {
        if (urlCache.has(filePath)) {
          setCompanionImageUrl(urlCache.get(filePath)!);
        } else {
          setLoading(true);
          console.log("FETCHING IMAGE");
          try {
            const url = await getDownloadURL(ref(storage, filePath));
            urlCache.set(filePath, url);
            setCompanionImageUrl(url);
          } catch (error) {
            console.error("Error fetching image URL: ", error);
          } finally {
            setLoading(false);
          }
        }
      }
    };

    fetchImageUrl();
  }, [selectedCompanion?.imageFilePath]);

  const handleCompanionClick = (item: CompanionDetails) => {
    console.log(item);
    setSelectedCompanion(item);
    if (!item.imageFilePath) {
      setCompanionImageUrl("");
    }
  };

  const handleCompanionImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log("companion image change");
    console.log(event.target.files);
    console.log(event.target.files && event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const filePath = `users/${user?.uid}/images/${selectedCompanion?.id}.jpg`;
      const storageRef = ref(storage, filePath);

      setLoading(true); // Start loading
      console.log("attempting companion image upload");
      try {
        await uploadBytes(storageRef, file);

        // Store the file path instead of the download URL
        const updatedList = updateCompanionList(
          updatedCompanionList,
          selectedCompanion!.id,
          {
            imageFilePath: filePath,
          }
        );
        console.log("updated list", updatedList);
        setUpdatedCompanionList(updatedList);
        setSelectedCompanion(
          (prevDetails) =>
            ({
              ...prevDetails,
              imageFilePath: filePath,
            } as CompanionDetails)
        );
        setIsEdited(true);

        onCompanionListUpdate(updatedList);
      } catch (error) {
        console.error("Error uploading image: ", error);
      } finally {
        setLoading(false); // Stop loading after the operation is complete
      }
    }
  };

  const handleCompanionSave = async () => {
    console.log("companion save");
    if (selectedCompanion && user) {
      try {
        onCompanionListUpdate(updatedCompanionList);
        console.log("saving new companion list", updatedCompanionList);

        setIsEdited(false);
        enqueueSnackbar("Companion updated successfully", {
          variant: "success",
        });
      } catch (error) {
        console.error("Error saving companion list data: ", error);
        enqueueSnackbar("Error saving companion data", { variant: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const updateCompanionList = (
    items: CompanionDetails[],
    id: string,
    updatedData: Partial<CompanionDetails>
  ): CompanionDetails[] => {
    return items.map((item) =>
      item.id === id ? { ...item, ...updatedData } : item
    );
  };

  const handleAddItem = () => {
    const newItem: CompanionDetails = {
      id: `new-${Date.now()}`,
      name: "",
      startDate: "",
      notes: "",
      endDate: "",
      imageFilePath: "",
    };
    setUpdatedCompanionList([...updatedCompanionList, newItem]);
    setSelectedCompanion(newItem);
  };

  const handleCompanionDetailsChange = (
    field: string,
    value: string | Date
  ) => {
    console.log("companion details change", field, value);
    setSelectedCompanion(
      (prevDetails) =>
        ({
          ...prevDetails,
          [field]: value,
        } as CompanionDetails)
    );
    const newList = updateCompanionList(
      updatedCompanionList,
      selectedCompanion!.id,
      {
        [field]: value,
      }
    );
    setUpdatedCompanionList(newList);
    setIsEdited(true);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Companions
      </Typography>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <CompanionList
          companionList={companionList}
          loading={false}
          onItemClick={handleCompanionClick}
          onAddItem={handleAddItem}
        />
        <Box sx={{ flex: 2, maxWidth: "500px" }}>
          {selectedCompanion && (
            <MissionaryCard
              name={selectedCompanion.name}
              startDate={selectedCompanion.startDate}
              endDate={selectedCompanion.endDate}
              showCompanionFields={true}
              image={companionImageUrl}
              notes={selectedCompanion.notes || ""}
              loading={loading}
              onDetailsChange={handleCompanionDetailsChange}
              onImageChange={handleCompanionImageChange}
              onSave={handleCompanionSave}
              isEdited={isEdited}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default CompanionsInfoPane;
