import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../firebase";
import { Box, CircularProgress, Typography } from "@mui/material";

const PaymentConfirmation: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { user } = useAuth();
  const userID = user ? user.uid : null;

  useEffect(() => {
    // Extract token (orderID) and PayerID from URL
    if (!userID) {
      // setError("User ID not found");
      // setLoading(false);
      return;
    }
    const queryParams = new URLSearchParams(location.search);
    const orderID = queryParams.get("token"); // PayPal orderID
    const payerID = queryParams.get("PayerID"); // PayPal PayerID

    if (!orderID || !payerID) {
      setError("Missing orderID or PayerID");
      setLoading(false);
      return;
    }

    // Call backend to capture the PayPal payment
    const capturePayment = async () => {
      try {
        const response = await fetch(
          "http://127.0.0.1:5001/family-tree-and-me/us-central1/api_capture_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orderID,
              payerID,
              userID,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to capture PayPal payment");
        }

        const data = await response.json();
        console.log("Payment captured successfully:", data);

        // Navigate to a success page after the payment is captured
        navigate("/payment-success", { state: { orderID: data.orderID } });
      } catch (error: any) {
        console.error("Payment capture error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    capturePayment();
  }, [location, navigate, userID]);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
      >
        {/* Payment Processing Spinner */}
        <CircularProgress
          size={80}
          thickness={4}
          color="primary"
          sx={{ mb: 4 }}
        />

        {/* Heading */}
        <Typography variant="h4" component="h1" gutterBottom>
          Processing Your Payment
        </Typography>

        {/* Subtext */}
        <Typography variant="body1" color="textSecondary">
          Please wait while we complete your payment. This may take a moment.
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Payment confirmed! Redirecting to the success page...</div>;
};

export default PaymentConfirmation;
