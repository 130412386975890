import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import {
  ProductType,
  SelectOption,
  NumberOption,
  BooleanOption,
} from "./productTypes";

type OrderSummaryTableProps = {
  product: ProductType;
  quantity: number;
  selectedOptions: { [key: string]: any };
};

const OrderSummaryTable: React.FC<OrderSummaryTableProps> = ({
  product,
  quantity,
  selectedOptions,
}) => {
  const getOptionSummary = (
    option: SelectOption | NumberOption | BooleanOption
  ) => {
    if (option.type === "select") {
      const selectedValue = option.values.find(
        (v) => v.id === selectedOptions[option.id]
      );
      return selectedValue ? `${selectedValue.label}` : "";
    }
    if (option.type === "number") {
      const selectedValue = selectedOptions[option.id];
      return `${selectedValue}`;
    }
    if (option.type === "boolean") {
      const selectedValue = selectedOptions[option.id];
      return selectedValue ? "Yes" : "No";
    }
    return "";
  };

  const getOptionCost = (
    option: SelectOption | NumberOption | BooleanOption
  ) => {
    if (option.type === "select") {
      const selectedValue = option.values.find(
        (v) => v.id === selectedOptions[option.id]
      );
      return selectedValue ? `+$${selectedValue.cost}` : "$0";
    }
    if (option.type === "number") {
      const selectedValue = selectedOptions[option.id];
      const extraCost = (selectedValue - option.min) * option.cost;
      return `+$${extraCost}`;
    }
    if (option.type === "boolean") {
      const selectedValue = selectedOptions[option.id];
      return selectedValue ? `+$${option.cost}` : "$0";
    }
    return "$0";
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Typography variant="h6">Order Summary</Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="Order Summary Table">
          <TableHead>
            <TableRow>
              <TableCell>Option</TableCell>
              <TableCell align="right">Selected</TableCell>
              <TableCell align="right">Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Base Price</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">${product.price.toFixed(2)}</TableCell>
            </TableRow>
            {product.options?.map((option) => (
              <TableRow key={option.id}>
                <TableCell>{option.label}</TableCell>
                <TableCell align="right">{getOptionSummary(option)}</TableCell>
                <TableCell align="right">{getOptionCost(option)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell align="right">{quantity}</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ marginY: 2 }} />
    </Box>
  );
};

export default OrderSummaryTable;
