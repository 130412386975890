import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    background: {
      default: "#8A3B5C", // Page background color
      paper: "#f5f5f5", // Paper background color for FAQs
    },
    primary: {
      main: "#5C0629", // Toolbar background color (primary color)
      contrastText: "#FFFFFF", // Text color for primary buttons
    },
    text: {
      primary: "#333333", // Darker text for better readability
      secondary: "#555555", // Lighter gray for secondary text
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: "rgb(234, 174, 93)", // Color for h1 elements
        },
        h4: {
          color: "rgb(234, 174, 93)", // Color for h4 elements
          fontFamily: "Playfair Display, serif",
          textAlign: "center",
        },
        h6: {
          color: "#5C0629", // Color for FAQ headings
          fontWeight: "bold",
        },
        body1: {
          color: "#555555", // Color for body text in FAQs
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5", // Slightly off-white paper color
          padding: "2px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Soft shadow to add depth
          // borderRadius: "8px", // Rounded corners for a softer look
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px", // Rounded button
          textTransform: "none", // No uppercase text
          backgroundColor: "#5C0629", // Button color
          color: "#FFFFFF", // Button text color
          "&:hover": {
            backgroundColor: "#732847", // Darker version of primary on hover
          },
        },
      },
    },
  },
});

export default theme;