import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";

const HelpSupportTab: React.FC = () => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Help & Support
      </Typography>

      {/* FAQs */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="How do I update my family tree?"
              secondary="You can update your family tree from the Family Tree tab. Click 'Edit Family Tree' to make changes."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="What should I do if I need to change my order?"
              secondary="If your order hasn't been processed, you can make changes by contacting our support team."
            />
          </ListItem>
        </List>
        <Button variant="contained" color="primary">
          View All FAQs
        </Button>
      </Paper>

      {/* Contact Support */}
      <Paper sx={{ padding: "16px", marginBottom: "24px" }}>
        <Typography variant="h6" gutterBottom>
          Contact Support
        </Typography>
        <Typography variant="body1">
          Need help? Get in touch with our support team for assistance with your
          orders, account, or any other inquiries.
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: "16px" }}>
          Contact Us
        </Button>
      </Paper>

      {/* Guided Tour */}
      <Paper sx={{ padding: "16px" }}>
        <Typography variant="h6" gutterBottom>
          Guided Tour
        </Typography>
        <Typography variant="body1">
          New here? Take a quick tour to learn how to navigate your account and
          make the most of our features.
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: "16px" }}>
          Start Tour
        </Button>
      </Paper>
    </Box>
  );
};

export default HelpSupportTab;
