import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type Product = {
  imgSrc: string;
  description: string;
  link: string;
  title: string;
  price?: string;
};

const ProductPageCard: React.FC<Product> = ({
  imgSrc,
  description,
  link,
  title,
  price,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };
  return (
    <Card sx={{ maxWidth: 300, position: "relative" }} onClick={handleClick}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={imgSrc}
          alt={title}
          style={{ backgroundColor: "white", objectFit: "contain" }}
        />
        <CardContent
          style={{
            backgroundColor: "black",
            padding: 20,
            margin: "auto",
            textAlign: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              color: "#ffffff",
              fontWeight: 700,
              justifyContent: "center",
              display: "flex",
            }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="white">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>

      {price && (
        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: -36,
            width: 150,
            height: 30,
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            transform: "rotate(-45deg)",
            zIndex: 1,
            boxShadow: "5px 10px 10px rgba(0, 0, 0, 0.6)",
          }}
        >
          <Typography
            variant="caption"
            sx={{ fontWeight: "bold", fontSize: "16px" }}
          >
            {price}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default ProductPageCard;
