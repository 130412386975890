import React from "react";
import { Box, Typography } from "@mui/material";

const PoliciesPage: React.FC = () => {
  return (
    <Box maxWidth="900px" margin="0 auto" padding="16px">
      <Typography variant="h4" component="h1" gutterBottom>
        Policies
      </Typography>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Tax
        </Typography>
        <Typography variant="body1" paragraph>
          Sales tax will be added to ordering clients from the state of Utah.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Shipping or Will-Call
        </Typography>
        <Typography variant="body1" paragraph>
          Our art pieces are sent flat, wrapped in a protective sleeve and
          placed between thick cardboard to protect it from damage. The shipping
          and handling fee is $15.99 when mailed within the state of Utah and
          $19.99 when mailed outside of Utah. Larger special orders may require
          a mailing tube. Orders sent outside the United States will be priced
          on an individual basis.
        </Typography>
        <Typography variant="body1" paragraph>
          Arrangements may be made to pick up any completed artwork at our Orem,
          Utah address. Please contact us to arrange a pick-up time.
        </Typography>
        <Typography variant="body1" paragraph>
          Be aware that our system will automatically add shipping charges to
          your order. If you plan to pick up your artwork in person, contact us
          and we will manually remove this fee before your credit card is
          charged.
        </Typography>
        <Typography variant="body1" paragraph>
          Should damage occur, contact us IMMEDIATELY and do not dispose of the
          packaging. The mail service may need to see in what condition the
          shipment arrived in order to process the insurance/damage claim.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Refunds and Exchanges
        </Typography>
        <Typography variant="body1" paragraph>
          Due to the nature of our custom products, there are no refunds or
          exchanges.
        </Typography>
        <Typography variant="body1" paragraph>
          You will have the opportunity to view and change your designed artwork
          prior to printing. We will send a draft via email for you to proofread
          and approve. Upon approval of your art piece, it will be sent to the
          printer. Errors discovered AFTER printing are the responsibility of
          the client.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Payment
        </Typography>
        <Typography variant="body1" paragraph>
          When ordering through our website, we use the services provided by
          PAYPAL. You do NOT need to have a PAYPAL account in order use your
          credit card.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Privacy
        </Typography>
        <Typography variant="body1" paragraph>
          Your personal information is guarded by strict internal policy.
          Personal information is never shared with any other party.
        </Typography>
      </Box>
    </Box>
  );
};

export default PoliciesPage;
