import { Box, Grid, Typography } from "@mui/material";

const BackgroudColorsPage = () => {
  return (
    <Box maxWidth="1100px" margin="0 auto" padding="16px">
      <Typography variant="h4" align="center" gutterBottom>
        Choose between the 5 following background colors
      </Typography>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        color="textSecondary"
      >
        Neutral Beige
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/beige.jpg"
            alt="beige"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/beigeDemo.jpg"
            alt="beige demo"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        color="textSecondary"
      >
        Burgundy Cloud
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/burgundy.jpg"
            alt="burgundy"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/burgundyDemo.jpg"
            alt="burgundy demo"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        color="textSecondary"
      >
        Grove
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/grove.jpg"
            alt="grove"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/groveDemo.jpg"
            alt="grove demo"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        color="textSecondary"
      >
        Antique Parchment
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/antiquep.jpg"
            alt="parchment"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/antiquepDemo.jpg"
            alt="parchment demo"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        color="textSecondary"
      >
        Tawny Parchment
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/tawny.jpg"
            alt="tawny"
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img
            src="/images/photoFamilyTrees/backgrounds/tawnyDemo.jpg"
            alt="tawny demo"
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BackgroudColorsPage;
