import { Container } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// uses a format string to create a list of images from ss_1 to ss_12
//const slides = [...Array(12).keys()].map((i) => `images/ftam/ss_${i + 1}.jpg`);

const slides = [
  "images/home/ss_1.jpg",
  "images/home/ss_2.jpg",
  "images/home/ss_3.jpg",
  "images/home/ss_4.jpg",
  "images/home/ss_5.jpg",
  "images/home/ss_6.jpg",
  "images/home/ss_7.jpg",
  "images/home/ss_8.jpg",
  "images/home/ss_9.jpg",
  "images/home/ss_10.jpg",
  "images/home/ss_11.jpg",
  "images/home/ss_12.jpg",
];

const CarouselSliderBanner = () => {
  return (
    <Container
      style={{
        padding: "20px",
        marginBottom: "10px",
        backgroundColor: "transparent",
      }}
    >
      <Carousel autoPlay infiniteLoop interval={5000} showThumbs={false}>
        {slides.map((slide, index) => (
          <div key={`carousel-slide-${index}`}>
            <img src={slide} />
          </div>
        ))}
      </Carousel>
    </Container>
  );
};

export default CarouselSliderBanner;
