import styled from "styled-components";
import { Link, Typography } from "@mui/material";
import React from "react";

const CustomLink = styled(Link)`
  color: gold;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:hover .typography-hover {
    font-weight: bold;
  }
`;

interface Entry {
  link: string;
  title: string;
}

interface StyledLinkProps {
  entry: Entry;
}

const StyledLink: React.FC<StyledLinkProps> = ({ entry }) => {
  return (
    <CustomLink href={entry.link}>
      <Typography
        variant="body1"
        className="typography-hover"
        style={{ color: "#c0b283" }}
      >
        {entry.title}
      </Typography>
    </CustomLink>
  );
};

export default StyledLink;
