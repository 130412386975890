import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ProductPageCardWithMotion from "../../components/ProductPageCardWithMotion";
import { Product } from "../../components/ProductPageCard";

function MissionaryPhotoArtPage() {
  const products: Product[] = [
    // {
    //   imgSrc: "/images/shop/missionary/small.jpg",
    //   title: "Small Layout",
    //   description: "Small Layout - 16 x 20",
    //   price: "$120",
    //   link: "/shop/Missionary-Photo-Art/Small",
    // },
    {
      imgSrc: "/images/shop/missionary/large.jpg",
      title: "Missionary Photo Art",
      description: "Commemorative Mission Art",
      link: "/shop/Missionary-Photo-Art/Art",
      price: "$120+",
    },
    {
      imgSrc: "/images/shop/missionary/giftcertificate.jpg",
      title: "GIFT CERTIFICATE",
      description: "Gift Certificate",
      price: "$120+",
      link: "/shop/Missionary-Photo-Art/Gift-Certificate",
    },
  ];

  return (
    <Container>
      <Typography variant="h3" align="center" gutterBottom sx={{ mt: 4 }}>
        Missionary Photo Art
      </Typography>

      <Grid container justifyContent="center" spacing={4} sx={{ mb: 4 }}>
        {products.map((product, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ProductPageCardWithMotion index={index} card={product} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default MissionaryPhotoArtPage;
