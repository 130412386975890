import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PhotoArtPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        margin: "auto",
        marginTop: "30px",
        maxWidth: "900px",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: "rgb(234, 174, 93)",
          textAlign: "center",
        }}
      >
        Missionary Photo Art
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        Our digitally-designed Missionary Photo Art highlights the experiences
        of the returned missionary. It captures in art form the people, places,
        and memories that touched their lives. It is more than an art piece --
        it is a conversation piece!
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item container xs={12} md={8} justifyContent={"center"}>
          {/* Placeholder for the image */}
          <Box
            component="img"
            src="/images/missionary-photo-art.jpg"
            alt="Missionary Photo Art"
            sx={{
              width: "100%",
              maxWidth: "700px",
              height: "auto",
              borderRadius: 2,
              boxShadow: 3,
              margin: "auto",
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mt: 4, textAlign: "center" }}>
        CHOOSE BETWEEN THE SMALL (16" X 20") OR THE LARGE (20" X 30") PHOTO ART.
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: "center" }}>
        Missionary art is priced at $120 for the small and $185 for the large.
      </Typography>

      <Box
        sx={{
          mt: 3,
          margin: "auto",
          justifyContent: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2 }}
          onClick={() => navigate("/family-tree-example-gallery")}
        >
          Click to see our Example Gallery
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/shop/Missionary-Photo-Art")}
        >
          Click to Purchase
        </Button>
      </Box>
    </Box>
  );
};

export default PhotoArtPage;