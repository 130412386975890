import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Image } from "@mui/icons-material";

const FamTreeWelcomeBanner = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "100vw",
        height: "340px",
        backgroundColor: "rgba(92,6,41,0.90",
      }}
    >
      <Grid item>
        <Typography
          variant="h3"
          style={{
            color: "#be8d4b",
            textAlign: "center",
            fontFamily: `'Playfair Display', serif`,
          }}
        >
          WELCOME TO FAMILY TREE & ME!
        </Typography>
        <Typography
          variant="h5"
          style={{ color: "white", textAlign: "center", opacity: 1 }}
        >
          We design customized Family Photo Art!
        </Typography>
        <Typography
          variant="h5"
          style={{ color: "white", textAlign: "center", opacity: 1 }}
        >
          YOU provide the pictures!
        </Typography>
        <Typography
          variant="h5"
          style={{ color: "white", textAlign: "center", opacity: 1 }}
        >
          WE create the art!
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "#be8d4b",
            padding: 20,
            textAlign: "center",
            opacity: 1,
          }}
        >
          Gather your family photographs and let's get started!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FamTreeWelcomeBanner;
