import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { AuthContext, db, storage } from "../../../firebase";
import CompanionList, { CompanionDetails } from "./CompanionList";
import MissionaryCard from "./MissionaryCard";
import { Button, Divider } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Missionary, MissionDetails } from "./MissionaryArtTab";
import CompanionsInfoPane from "./CompanionsInfoPane";

export type MissionaryInfoContentType = {
  missionaryId: string;
  onMissionaryUpdate: (missionary: Missionary) => void;
};

const urlCache = new Map<string, string>();

const MissionaryInfoContent: React.FC<MissionaryInfoContentType> = ({
  missionaryId,
  onMissionaryUpdate,
}) => {
  const [companionList, setCompanionList] = useState<CompanionDetails[]>([]);
  const [isMissionaryEdited, setIsMissionaryEdited] = useState(false);
  const [missionaryDetails, setMissionaryDetails] = useState({
    id: "",
    name: "",
    notes: "",
    imageFilePath: "",
  });
  const [missionDetails, setMissionDetails] = useState<MissionDetails>({
    missionName: "",
    start: "",
    end: "",
    president: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const [missionaryImageUrl, setMissionaryImageUrl] = useState<string>("");

  useEffect(() => {
    if (!user || !missionaryId) return;
    const userDocRef = doc(db, "users", user.uid);

    setLoading(true); // Start loading
    const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log("data loaded", data, missionaryId);
        const missionaryData = data.missionaries.find(
          (missionary: any) => missionary.id === missionaryId
        );
        console.log("missionary", missionaryData);
        setCompanionList(missionaryData?.companionList || []);
        setMissionaryDetails({
          id: missionaryData?.id || "",
          name: missionaryData?.name || "",
          notes: missionaryData?.notes || "",
          imageFilePath: missionaryData?.imageFilePath || "",
        });
        setMissionDetails(
          missionaryData?.missionDetails || {
            missionName: "",
            start: "",
            end: "",
            president: "",
          }
        );
      } else {
        console.log("No such document!");
      }
      setLoading(false); // Stop loading after data is fetched
    });

    return () => unsubscribe();
  }, [user, missionaryId]);

  useEffect(() => {
    const fetchMissionDetails = async () => {
      if (!user) return;

      setLoading(true);
      try {
        const userDocRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDocRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const missionaryData = data[missionaryId];
          if (missionaryData?.missionDetails) {
            console.log("Mission details: ", missionaryData.missionDetails);
            setMissionDetails(missionaryData.missionDetails);
          }
        } else {
          console.log("No mission details found!");
        }
      } catch (error) {
        console.error("Error fetching mission details: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMissionDetails();
  }, [user, missionaryId]);

  useEffect(() => {
    const fetchImageUrl = async () => {
      const filePath = missionaryDetails.imageFilePath;

      if (filePath) {
        // Check if the URL is already cached
        if (urlCache.has(filePath)) {
          setMissionaryImageUrl(urlCache.get(filePath)!); // Use cached URL
        } else {
          setLoading(true);
          try {
            const url = await getDownloadURL(ref(storage, filePath));
            urlCache.set(filePath, url); // Cache the fetched URL
            setMissionaryImageUrl(url);
          } catch (error) {
            console.error("Error fetching image URL: ", error);
          } finally {
            setLoading(false);
          }
        }
      }
    };

    fetchImageUrl();
  }, [missionaryDetails.imageFilePath]);

  const handleMissionDetailsChange = (field: string, value: string) => {
    setMissionDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    setIsMissionaryEdited(true);
  };

  const handleMissionaryDetailsChange = (
    field: string,
    value: string | Date
  ) => {
    setMissionaryDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    setIsMissionaryEdited(true);
  };

  const handleMissionarySave = async () => {
    if (user && missionaryId) {
      onMissionaryUpdate({
        id: missionaryId,
        companionList: companionList,
        missionDetails: missionDetails,
        name: missionaryDetails.name,
        imageFilePath: missionaryDetails.imageFilePath,
        notes: missionaryDetails.notes,
      });
      setIsMissionaryEdited(false);
      enqueueSnackbar("Mission details updated successfully", {
        variant: "success",
      });
    }
  };

  const handleMissionaryImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // name + timestamp to make the file path unique
      const filePath = `users/${user?.uid}/images/${
        missionaryDetails?.name || ""
      }-${Date.now()}.jpg`;
      const storageRef = ref(storage, filePath);

      setLoading(true); // Start loading
      try {
        await uploadBytes(storageRef, file);

        // Store the file path instead of the download URL
        setMissionaryDetails((prevDetails) => ({
          ...prevDetails,
          imageFilePath: filePath, // Store the file path
        }));
        setIsMissionaryEdited(true);

        onMissionaryUpdate({
          id: missionaryId,
          companionList: companionList,
          missionDetails: missionDetails,
          name: missionaryDetails.name,
          imageFilePath: filePath, // Pass the file path
          notes: missionaryDetails.notes,
        });
      } catch (error) {
        console.error("Error uploading image: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onCompanionListUpdate = (updatedList: CompanionDetails[]) => {
    setCompanionList(updatedList);
    onMissionaryUpdate({
      id: missionaryId,
      companionList: updatedList,
      missionDetails: missionDetails,
      name: missionaryDetails.name,
      imageFilePath: missionaryDetails.imageFilePath,
      notes: missionaryDetails.notes,
    });
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Missionary Information
      </Typography>

      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <MissionaryCard
          name={missionaryDetails.name}
          startDate={missionDetails.start}
          endDate={missionDetails.end}
          image={missionaryImageUrl}
          showCompanionFields={false}
          notes={missionaryDetails.notes || ""}
          loading={loading}
          onDetailsChange={handleMissionaryDetailsChange}
          onImageChange={handleMissionaryImageChange}
          onSave={handleMissionarySave}
          isEdited={isMissionaryEdited}
        />
        <Box sx={{ marginBottom: "24px", marginLeft: "15px" }}>
          <TextField
            margin="dense"
            label="Missionary Name"
            type="text"
            onChange={(e) =>
              handleMissionaryDetailsChange("name", e.target.value)
            }
            value={missionaryDetails.name}
            fullWidth
            variant="outlined"
            disabled={loading}
          />
          <TextField
            margin="dense"
            label="Mission Name"
            type="text"
            onChange={(e) =>
              handleMissionDetailsChange("missionName", e.target.value)
            }
            value={missionDetails.missionName}
            fullWidth
            variant="outlined"
            disabled={loading}
          />
          <TextField
            margin="dense"
            label="Mission President's Name"
            type="text"
            value={missionDetails.president}
            onChange={(e) =>
              handleMissionDetailsChange("president", e.target.value)
            }
            fullWidth
            variant="outlined"
            disabled={loading}
          />
          <TextField
            margin="dense"
            label="Mission Start Date"
            type="date"
            value={
              missionDetails.start
                ? new Date(missionDetails.start).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) =>
              handleMissionDetailsChange("start", e.target.value)
            }
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            disabled={loading}
          />
          <TextField
            margin="dense"
            label="Mission End Date"
            type="date"
            value={
              missionDetails.end
                ? new Date(missionDetails.end).toISOString().split("T")[0]
                : ""
            }
            onChange={(e) => handleMissionDetailsChange("end", e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            disabled={loading}
          />
          <br />

          <Button
            onClick={handleMissionarySave}
            color="primary"
            variant="contained"
            disabled={!isMissionaryEdited || loading}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <Divider sx={{ margin: 2 }} />

      <CompanionsInfoPane
        companionList={companionList}
        onCompanionListUpdate={onCompanionListUpdate}
      />
    </Box>
  );
};

export default MissionaryInfoContent;
