import React, { useRef, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import ReCAPTCHA from "react-google-recaptcha";

const ContactPage = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!recaptchaToken) {
      enqueueSnackbar("Please complete the reCAPTCHA verification.", {
        variant: "warning",
      });
      return;
    }

    try {
      await addDoc(collection(db, "contactSubmissions"), {
        ...formValues,
      });
      enqueueSnackbar(
        "Thank you for reaching out!  We will respond as soon as we can.",
        {
          variant: "success",
        }
      );
      setFormValues({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      setRecaptchaToken(null);
    } catch (error) {
      console.error("Error submitting form:", error);
      enqueueSnackbar(
        "There was an error submitting the form. Please try again.",
        { variant: "error" }
      );
    }
  };

  const onRecaptchaChange = (token: any) => {
    if (token) {
      setRecaptchaToken(token);
    } else {
      enqueueSnackbar("reCAPTCHA verification failed. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <Container maxWidth="md" style={{ backgroundColor: "white" }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          FAMILY TREE AND ME would love to hear from you.
        </Typography>
        <Typography variant="body1" paragraph>
          Your questions, comments, suggestions, and feedback are all welcome.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Contact person:</strong> Shirlene
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Phone number:</strong> 801.400.5802
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Email address:</strong> shirlene@familytreeandme.com
        </Typography>
        <Typography variant="h5" gutterBottom>
          Contact Us
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="name"
                label="Enter Your Name"
                variant="outlined"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="email"
                label="Your E-mail address"
                variant="outlined"
                type="email"
                value={formValues.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="phone"
                label="Your Phone Number"
                variant="outlined"
                type="tel"
                value={formValues.phone}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="subject"
                label="Message Subject"
                variant="outlined"
                value={formValues.subject}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                id="message"
                label="Enter Your Message"
                variant="outlined"
                multiline
                rows={4}
                value={formValues.message}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                onChange={onRecaptchaChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactPage;
