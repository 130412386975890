import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentSuccessPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
      textAlign="center"
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your order. Your payment has been processed successfully.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        You will receive an email receipt shortly with the details of your
        purchase.
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Please click the button below to go to your account page, where you will
        need to fill out some forms related to your order.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/account")}
        sx={{ mt: 3 }}
      >
        Go to Account Page
      </Button>
    </Box>
  );
};

export default PaymentSuccessPage;
